import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Label, Row, Table } from "reactstrap";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import Paginator from "Components/Common/PaginatorFix";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { reportGenerateRequest } from "store/report/action";
import { GeneratedReport } from "store/report/types";
import processFilterDateType from "helpers/filter_datetime";
import moment from "moment";

interface ReportProps {
    config: any;
    t: (key: string) => string;
}

const TableReport: React.FC<ReportProps> = ({t, config}) => {
    const { columns } = config;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [reportConfig, setReportConfig]: any = useState();
    const [report, setReport] = useState<GeneratedReport | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [pagination, setPagination]: any = useState(10);
    
    useEffect(() => {
        if(config) {
            setReportConfig(config);
        }
    }, [config])
    
    useEffect(() => {
        if(reportConfig) {
            generateReport(reportConfig);
        }
    }, [reportConfig])

    const generateReport = (reportConfig: any) => {
        setLoading(true);
        setError(false);

        const updatedConfig = {
            ...reportConfig,
            filters: processFilterDateType(reportConfig.filters),
        };
        const handleSuccess = (response: GeneratedReport) => {
            setLoading(false);
            setError(false);
            setReport(response);
            setPagination({
                page: response.pagination?.page,
                size: response.pagination?.size,
                pages: response.pagination?.pages,
                elements: response.pagination?.total,
                first: response.pagination?.first,
                last: response.pagination?.last,
                offset: response.pagination?.offset,
                hasNext: response.pagination?.hasNext,
                hasPrevious: response.pagination?.hasPrevious,
            })
        }
        const handleError = () => {
            setLoading(false);
            setError(true);
        }
        dispatch(reportGenerateRequest(updatedConfig, handleSuccess, handleError))
    }

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                    setReportConfig({ ...reportConfig, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                    setReportConfig({ ...reportConfig, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 1;
                setPagination({ ...pagination, page: 1 });
                setReportConfig({ ...reportConfig, page: 1 });
                break;
            case 'last':
                newPageNo = lastPage;
                setPagination({ ...pagination, page: newPageNo });
                setReportConfig({ ...reportConfig, page: newPageNo });
                break;
            default:
                newPageNo = pageVal;
                setPagination({ ...pagination, page: newPageNo });
                setReportConfig({ ...reportConfig, page: newPageNo });
                break;
        }
    };

    const onChangePageSize = (event: any) => {
        setPagination({ ...pagination, size: event.target.value })
        setReportConfig({ ...reportConfig, page: event.target.value });
    }

    if(error) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100 p-5 gap-3">
                <Label className="fw-semibold text-danger fs-5">This report couldn't load. Please refresh or try again later.</Label>
                <Button
                    size="sm"
                    onClick={() => generateReport(reportConfig)}
                    color="primary"
                    className="btn-label">
                        <i className="ri-refresh-line label-icon align-middle fs-16 me-2"></i>
                        Refresh
                </Button>
            </div>
        )
    }
    
    return (
        <div className="h-100 w-100 p-3">
            {loading ? <DtsTablePlaceholder
                columns={columns}
                tableClass="align-middle table-nowrap"
                thClass="border-bottom-1 table-soft-primary"
            /> : <div className="d-flex flex-column gap-2" style={{ height: "calc(100% - 140px)" }}>
                <div className="table-responsive overflow-auto">
                    <Table className="align-middle table-nowrap">
                        <thead className='table-light text-muted position-sticky' style={{top: -2}}>
                            <tr>
                                {columns.map((column: any) => <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">{column.label ? column.label : column.key}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {report?.data?.map((row: any) => {
                                return <tr>
                                    {columns.map((column: any) => {
                                        const value = column?.data_source?.primary ? row[column?.key] : row[column?.data_source?.value][column?.key]
                                        if(column?.type === "datetime") {
                                            return <td className='align-middle fw-semibold'>{moment(value).format('lll')}</td>
                                        }
                                        return <td className='align-middle fw-semibold'>{value}</td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </div>
                <Row className="align-items-center g-3 text-center text-sm-start">
                    <div className="col-sm">
                        <div className="text-muted">
                            <span className="fw-semibold ms-1">
                                {pagination ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-auto">
                        <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                            currentPage={pagination?.page}
                            pageChanged={(e: any) => pageChanged(e)}
                            pageSize={pagination?.size}
                            isPageSizeChange={false}
                            onChangeInSelect={(e: any) => onChangePageSize(e)}
                            pagination={pagination}
                        />
                    </div>
                </Row>
            </div>}
        </div>
    );
};

export default withTranslation()(TableReport);
