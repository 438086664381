export interface NotesMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Notes {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export enum NotesActionTypes {
  GET_NOTEMETADATA_REQUEST = "@@note/GET_NOTEMETADATA_REQUEST",
  GET_NOTEMETADATA_SUCCESS = "@@note/GET_NOTEMETADATA_SUCCESS",
  GET_NOTEMETADATA_ERROR = "@@note/GET_NOTEMETADATA_ERROR",
  GET_NOTE_REQUEST = "@@note/GET_NOTE_REQUEST",
  GET_NOTE_SUCCESS = "@@note/GET_NOTE_SUCCESS",
  GET_NOTE_ERROR = "@@note/GET_NOTE_ERROR",
  GET_NOTES_REQUEST = "@@note/GET_NOTES_REQUEST",
  GET_NOTES_SUCCESS = "@@note/GET_NOTES_SUCCESS",
  GET_NOTES_ERROR = "@@note/GET_NOTES_ERROR",
  PUT_NOTE_REQUEST = "@@note/PUT_NOTE_REQUEST",
  PUT_NOTE_SUCCESS = "@@note/PUT_NOTE_SUCCESS",
  PUT_NOTE_ERROR = "@@note/PUT_NOTE_ERROR",
  DELETE_NOTE_REQUEST = "@@note/DELETE_NOTE_REQUEST",
  DELETE_NOTE_SUCCESS = "@@note/DELETE_NOTE_SUCCESS",
  DELETE_NOTE_ERROR = "@@note/DELETE_NOTE_ERROR",
  POST_NOTE_REQUEST = "@@note/POST_NOTE_REQUEST",
  POST_NOTE_SUCCESS = "@@note/POST_NOTE_SUCCESS",
  POST_NOTE_ERROR = "@@note/POST_NOTE_ERROR",
}

export interface NotesState {
  readonly list: Notes[] | null;
  readonly details: Notes | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: NotesMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;
}
