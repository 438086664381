import { Button, ButtonGroup, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledButtonDropdown } from "reactstrap";
import classnames from "classnames";
import { Key, useEffect, useState } from "react";
import Select from "react-select";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import listPlugin from '@fullcalendar/list';
import { getGoogleCalendarData } from "store/calendarSync/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { accountAddRequest, userGetRequest } from "store/user/action";
import { useParams } from "react-router-dom";
import { ApplicationState } from "store";
import { countriesListGetRequest } from "store/currency/action";

interface Timezone {
    zoneName: string;
    gmtOffset: number;
    gmtOffsetName: string;
    abbreviation: string;
    tzName: string;
}
interface Country {
    id: string;
    valuesJson: {
        timezones: string; // Stored as JSON string, needs parsing
    };
}

interface TimeSelection {
    [key: string]: Array<{
        from: string;
        to: string;
    }>;
}

interface Interval {
    from: string;
    to: string;
}

interface Rule {
    type: "wday" | "date";
    wday?: string; // Optional for "date" rules
    date?: string; // Optional for "wday" rules
    intervals: Interval[];
    isEnabled: boolean;
}

const weekDays = [
    { value: "sunday", label: "SUN" },
    { value: "monday", label: "MON" },
    { value: "tuesday", label: "TUE" },
    { value: "wednesday", label: "WED" },
    { value: "thursday", label: "THU" },
    { value: "friday", label: "FRI" },
    { value: "saturday", label: "SAT" },
];

const customStyles = {
    control: (provided: any) => ({
        ...provided,
        borderRadius: "0.375rem", // Bootstrap's default border-radius
        borderColor: "#0d6efd", // Bootstrap's primary color
        padding: "0px",
        boxShadow: "none",
        "&:hover": {
            borderColor: "#0b5ed7",
        },
    }),
    option: (provided: any, state: { isSelected: boolean }) => ({
        ...provided,
        display: "flex",
        justifyContent: "space-between", // Align label elements at both ends
        backgroundColor: state.isSelected ? "#2a5f8d" : "white", // Primary blue if selected
        color: state.isSelected ? "white" : "#000", // White text for selected, black otherwise
        padding: "10px",
        fontWeight: state.isSelected ? "bold" : "normal",
        "&:hover": {
            backgroundColor: "#2a5f8d", // Light blue on hover
            color: "white",
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        borderRadius: "0.375rem",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Bootstrap-like shadow
    }),
};

const Availability = () => {
    let { id }: any = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user = useSelector((state: ApplicationState) => state.auth.userProfile);
    const timeZoneList = useSelector((state: ApplicationState) => state.currency.list);
    const [activeTab, setactiveTab] = useState<any>("1");
    const [activeButton, setActiveButton] = useState('list');
    const [dissortByStart, setdissortByStart] = useState({ value: "09:00", label: "09:00 AM" });
    const [dissortByEnd, setdissortByEnd] = useState({ value: "", label: "" });
    const [selectedDays, setSelectedDays] = useState<Record<string, boolean>>({});
    const [timezone, setTimezone] = useState("Asia/Calcutta");
    const [selectedTimes, setSelectedTimes] = useState<TimeSelection>({});
    const [slotDetails, setSlotDetails] = useState<any>();
    const [fetchedUser, setFetchedUser] = useState<any>();
    const [countryData, setCountryData] = useState<Record<string, Timezone[]>>({});
    const [groupOptions, setGroupOptions] = useState<{ label: string; options: { value: string; label: string }[] }[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<{ value: string; label: string } | null>(null);
    const filter = {
        "filters": [
            {
                "search": null
            }
        ],
        "sorts": [
            {
                "field": "createdAt",
                "order": "desc"
            }
        ]
    }

    useEffect(() => {
        dispatch(countriesListGetRequest(filter))
    }, [])

    useEffect(() => {
        if (timeZoneList?.length) {
            const categorizedTimezones: Record<string, Timezone[]> = {};
            timeZoneList.forEach((country: any) => {
                try {
                    const timezones: Timezone[] = JSON.parse(country.valuesJson.timezones);
                    timezones.forEach((timezone) => {
                        const continent = timezone.zoneName.split("/")[0];
    
                        if (!categorizedTimezones[continent]) {
                            categorizedTimezones[continent] = [];
                        }
                        const isTimezoneExists = categorizedTimezones[continent].some(
                            (existingTimezone) => existingTimezone.zoneName === timezone.zoneName
                        );
                        if (!isTimezoneExists) {
                            categorizedTimezones[continent].push(timezone);
                        }
                    });
                } catch (error) {
                    console.error(`Error parsing timezones for country ${country.id}:`, error);
                }
            });
    
            setCountryData(categorizedTimezones);
        }
    }, [timeZoneList]);
    

    useEffect(() => {
        const formattedOptions = Object.entries(countryData).map(([continent, timezones]) => ({
            label: continent, // Group name
            options: timezones.map((tz) => ({
                value: tz.zoneName,
                label: `${tz.tzName} (${tz.abbreviation})`, // Display with abbreviation
            })),
        }));
        setGroupOptions(formattedOptions);
    }, [countryData]);

    const handleSelectGroups = (selected: { value: string; label: string }) => {
        setSelectedGroup(selected);
        // console.log("Selected Timezone:", selected);
    };

    useEffect(() => {
        if (id) {
            dispatch(userGetRequest(id));
        }
    }, [id]);

    useEffect(() => {
        if (user) {
            setFetchedUser(user);
        }
    }, [user]);

    useEffect(() => {
        if (fetchedUser) {

            try {
                const valuesJson = fetchedUser?.valuesJson;
                if (valuesJson) {
                    const parsedJson = JSON.parse(valuesJson);
                    const meetingSettings = parsedJson?.meeting_settings;

                    if (meetingSettings) {

                        // Update selectedDays and selectedTimes based on meetingSettings
                        const days: Record<string, boolean> = {};
                        const times: TimeSelection = {};

                        meetingSettings.rules.forEach((rule: Rule) => {
                            if (rule.type === "wday" && rule.wday) {
                                // Populate selectedDays and selectedTimes based on wday rules
                                days[rule.wday] = rule.isEnabled;

                                // Initialize times for the day if enabled
                                if (rule.isEnabled) {
                                    times[rule.wday] = rule.intervals.length > 0
                                        ? rule.intervals
                                        : [{ from: dissortByStart.value, to: dissortByEnd.value }];
                                }
                            }
                        });

                        // Update state with the populated days and times
                        setSelectedDays(days);
                        setSelectedTimes(times);

                    } else {
                        console.log("No meeting_settings found in parsed valuesJson.");
                    }
                } else {
                    console.log("valuesJson is undefined or null in fetchedUser.");
                }
            } catch (error) {
                console.log("Error parsing valuesJson: ", error);
            }
        }
    }, [fetchedUser]);

    useEffect(() => {
        updateGroupOptions(); // Initial load
        const interval = setInterval(updateGroupOptions, 60000); // Update every 60 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, [countryData]);

    const getCurrentTime = (zoneName: string): string => {
        return new Intl.DateTimeFormat("en-US", {
            timeZone: zoneName,
            hour: "2-digit",
            minute: "2-digit",
            hour12: true, // Use 12-hour format
        }).format(new Date());
    };

    const updateGroupOptions = () => {
        const formattedOptions = Object.entries(countryData).map(([continent, timezones]) => ({
            label: continent, // Group name
            options: timezones.map((tz) => ({
                value: tz.zoneName,
                label: `${tz.tzName} (${tz.abbreviation})  ${getCurrentTime(tz.zoneName)}`, // Show current time
            })),
        }));

        setGroupOptions(formattedOptions);
    };

    const toggle = (tab: any) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    useEffect(() => {
        if (slotDetails) {
            const days: Record<string, boolean> = {};
            const times: TimeSelection = {};

            slotDetails.rules.forEach((rule: { type: string; wday: string | number; isEnabled: boolean; intervals: any[]; }) => {
                if (rule.type === "wday" && rule.wday) {
                    // Prepopulate based on the isEnabled property
                    days[rule.wday] = rule.isEnabled;

                    // Initialize times for the day if enabled
                    if (rule.isEnabled) {
                        times[rule.wday] = rule.intervals.length > 0
                            ? rule.intervals.map((interval) => ({
                                from: interval.from || dissortByStart.value,
                                to: interval.to || dissortByEnd.value
                            }))
                            : [{ from: dissortByStart.value, to: dissortByEnd.value }];
                    }
                }
            });

            setSelectedDays(days);
            setSelectedTimes(times);
        }
    }, [slotDetails, dissortByStart, dissortByEnd]);


    const formatTimeTo12Hour = (time: string) => {
        const [hours, minutes] = time.split(":").map(Number);
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
        return `${formattedHours}:${minutes?.toString()?.padStart(2, "0")} ${period}`;
    };

    const handleCheckboxChange = (day: string, checked: boolean) => {
        setSelectedDays((prev) => ({
            ...prev,
            [day]: checked,
        }));

        if (checked) {
            setSelectedTimes((prev) => ({
                ...prev,
                [day]: prev[day] || [{ from: dissortByStart.value, to: dissortByEnd.value }],
            }));
        } else {
            // Remove the times for unchecked days
            setSelectedTimes((prev) => {
                const updatedTimes = { ...prev };
                delete updatedTimes[day];
                return updatedTimes;
            });
        }
    };


    const isIntervalValid = (from: string, to: string) => {
        const [fromHours, fromMinutes] = from.split(":").map(Number);
        const [toHours, toMinutes] = to.split(":").map(Number);

        const fromTotal = fromHours * 60 + fromMinutes;
        const toTotal = toHours * 60 + toMinutes;

        return fromTotal < toTotal; // Ensure "from" is earlier than "to"
    };

    const isStartTimeValid = (
        startTime: string,
        endTime: string,
        intervals: { from: string; to: string }[],
        index: number
    ): boolean => {
        const [startHours, startMinutes] = startTime.split(":").map(Number);
        const startTotal = startHours * 60 + startMinutes;

        return intervals.every(({ from, to }, idx) => {
            if (idx === index) return true; // Skip current interval
            const [fromHours, fromMinutes] = from.split(":").map(Number);
            const [toHours, toMinutes] = to.split(":").map(Number);

            const fromTotal = fromHours * 60 + fromMinutes;
            const toTotal = toHours * 60 + toMinutes;
            return startTotal >= toTotal || startTotal < fromTotal;
        });
    };

    const isEndTimeValid = (
        startTime: string,
        endTime: string,
        intervals: { from: string; to: string }[],
        index: number
    ): boolean => {
        const [endHours, endMinutes] = endTime.split(":").map(Number);
        const endTotal = endHours * 60 + endMinutes;

        return intervals.every(({ from, to }, idx) => {
            if (idx === index) return true; // Skip current interval
            const [fromHours, fromMinutes] = from.split(":").map(Number);
            const [toHours, toMinutes] = to.split(":").map(Number);

            const fromTotal = fromHours * 60 + fromMinutes;
            const toTotal = toHours * 60 + toMinutes;
            return endTotal <= fromTotal || endTotal > toTotal;
        });
    };


    const getFilteredStartOptions = (day: string, index: number) => {
        const intervals = selectedTimes[day] || [];
        return dissortbyname.filter((option: any) =>
            isStartTimeValid(option.value, intervals[index]?.to || "", intervals, index)
        );
    };

    const getFilteredEndOptions = (day: string, index: number) => {
        const intervals = selectedTimes[day] || [];
        return dissortbyname.filter((option: any) =>
            isEndTimeValid(intervals[index]?.from || "", option.value, intervals, index)
        );
    };

    const getDayIntervals = (day: string) => {
        return selectedTimes[day] || [{ from: dissortByStart.value, to: dissortByEnd.value }];
    };

    const handleTimeChange = (
        day: string,
        timeType: "start" | "end",
        value: any,
        index: number
    ) => {
        setSelectedTimes((prev) => {
            const dayTimes = prev[day] || [];
            const updatedDayTimes = [...dayTimes];
            if (!updatedDayTimes[index]) {
                updatedDayTimes[index] = { from: "", to: "" };
            }
            updatedDayTimes[index][timeType === "start" ? "from" : "to"] = value.value;

            const { from, to } = updatedDayTimes[index];
            if (!isIntervalValid(from, to)) {
                alert("'From' time must be earlier than 'To' time.");
                return prev;
            }
            const isValid =
                timeType === "start"
                    ? isStartTimeValid(from, to, updatedDayTimes, index)
                    : isEndTimeValid(from, to, updatedDayTimes, index);
            if (!isValid) {
                alert("Time interval overlaps with an existing interval.");
                return prev;
            }
            return { ...prev, [day]: updatedDayTimes };
        });
    };



    const handleAddInterval = (day: string) => {
        setSelectedTimes((prev) => {
            const dayTimes = prev[day] || [];
            const updatedDayTimes = [...dayTimes];

            updatedDayTimes.push({ from: dissortByStart.value, to: dissortByEnd.value });

            return { ...prev, [day]: updatedDayTimes };
        });
    };

    const handleDeleteInterval = (day: string, index: number) => {
        setSelectedTimes((prev) => ({
            ...prev,
            [day]: prev[day]?.filter((_, i) => i !== index) || [],
        }));
    };

    const constructRules = () => {
        const allDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        const rules = allDays.map((day) => ({
            type: "wday",
            wday: day,
            intervals: selectedDays[day]
                ? selectedTimes[day]?.map((interval) => ({
                    from: interval.from || dissortByStart.value,
                    to: interval.to || dissortByEnd.value,
                })) || []
                : [],
            isEnabled: !!selectedDays[day],
        }));

        return {
            rules,
            timezone,
            rules_version: 1,
        };
    };

    const generateTimeOptions = () => {
        const times: { value: string; label: string; }[] = [];
        console.log("times", times)
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const formattedTime = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
                const label = `${hour % 12 || 12}:${minute.toString().padStart(2, "0")} ${hour < 12 ? "AM" : "PM"}`;
                times.push({ value: formattedTime, label });
            }
        }
        return times;
    };

    const dissortbyname = generateTimeOptions();

    useEffect(() => {
        if (!dissortbyname.some((option) => option.value === dissortByStart.value)) {
            setdissortByStart(dissortbyname[0]);
        }
        if (!dissortbyname.some((option) => option.value === dissortByEnd.value)) {
            setdissortByEnd(dissortbyname[dissortbyname.length - 1]);
        }
    }, [dissortByEnd.value, dissortByStart.value, dissortbyname]);

    const handleAddAccount = () => {
        const selectedInfo = { "meeting_settings": constructRules() };
        console.log("selected date", selectedInfo)
        const data = {
            "attributes": [
                {
                    "key": "valuesJson",
                    "value": JSON.stringify(selectedInfo)
                }
            ]
        }
        const handleSuccess = (): void => {
            dispatch(getGoogleCalendarData())
        };
        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        };
        dispatch(accountAddRequest(id, data, handleSuccess, handleError))
    }

    return (
        <>
            <div>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardBody>
                                <Nav tabs className="nav-tabs mb-3">
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1", })} onClick={() => { toggle("1"); }} >
                                            Schedules
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2", })} onClick={() => { toggle("2"); }} >
                                            Holidays
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="text-muted">
                                    <TabPane tabId="1" id="home">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex justify-content-start align-items-center">
                                                {/* <div>
                                                    <Label className="fs-16">Active on: </Label>
                                                </div>
                                                <div className="col-auto">
                                                    <ButtonGroup>
                                                        <UncontrolledButtonDropdown id="btnGroupDrop1">
                                                            <DropdownToggle color="light">
                                                                Dropdown <span><i className="ri-arrow-down-s-line align-middle"></i></span>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem> Dropdown link </DropdownItem>
                                                                <DropdownItem> Dropdown link </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </ButtonGroup>
                                                </div> */}
                                                <div>
                                                    <Label className="fs-16">Time zone: </Label>
                                                </div>
                                                <div style={{ minWidth: "300px" }} className="col-auto">
                                                    <div className="mb-0">
                                                        <Select
                                                            value={selectedGroup}
                                                            onChange={handleSelectGroups}
                                                            options={groupOptions}
                                                            styles={customStyles}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-groups" className="form-label text-muted">
                                                            Select Timezone
                                                        </Label>
                                                        <Select
                                                            value={selectedGroup}
                                                            onChange={handleSelectGroups}
                                                            options={groupOptions}
                                                        />
                                                    </div>
                                                </Col> */}
                                                {/* <div className="rounded-3 bg-light d-flex gap-2 p-2">
                                                    <Button onClick={() => setActiveButton('list')} size="sm" color={activeButton === 'list' ? 'primary' : 'light'} className="rounded-pill">
                                                        List view
                                                    </Button>
                                                    <Button onClick={() => setActiveButton('calendar')} size="sm" color={activeButton === 'calendar' ? 'primary' : 'light'} className="rounded-pill">
                                                        Calendar view
                                                    </Button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <hr></hr>
                                        {activeButton === 'list' &&
                                            <Row>
                                                <Col xxl={6} xl={6} lg={6} md={6} sm={6} className="">
                                                    <div>
                                                        <Label className="text-muted fs-16">Weekly Hours</Label>
                                                        <div>
                                                            {weekDays.map((day) => (
                                                                <div
                                                                    key={day.value}
                                                                    className="d-flex flex-column gap-2 mb-1 ms-3"
                                                                >
                                                                    {/* Checkbox and label with the "plus" icon */}
                                                                    <div className="d-flex justify-content-between align-items-start">
                                                                        <div style={{ minWidth: "80px" }} className="d-flex align-items-center gap-2 mt-2">
                                                                            <Input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`formCheck-${day.value}`}
                                                                                checked={selectedDays[day.value] || false}
                                                                                onChange={(e) => handleCheckboxChange(day.value, e.target.checked)}
                                                                            />
                                                                            <Label
                                                                                className="text-dark fw-bold form-check-label"
                                                                                htmlFor={`formCheck-${day.value}`}
                                                                            >
                                                                                {day.label}
                                                                            </Label>
                                                                        </div>
                                                                        {/* Time slots for the selected day */}
                                                                        <div className="d-flex flex-column gap-1 mt-1">
                                                                            {getDayIntervals(day.value).map((interval: { from: string; to: string }, index: number) => (
                                                                                <div key={index} className="d-flex align-items-center gap-3">
                                                                                    {/* Start Time */}
                                                                                    <div style={{ minWidth: "100px" }}>
                                                                                        <Select
                                                                                            isDisabled={!selectedDays[day.value]} // Disable if the day is not selected
                                                                                            value={{
                                                                                                value: interval.from,
                                                                                                label: formatTimeTo12Hour(interval.from), // Format the time to 12-hour format
                                                                                            }}
                                                                                            onChange={(option: any) => {
                                                                                                const endTime = selectedTimes[day.value]?.[index]?.to;
                                                                                                if (
                                                                                                    isStartTimeValid(option.value, endTime, selectedTimes[day.value], index)
                                                                                                ) {
                                                                                                    handleTimeChange(day.value, "start", option, index);
                                                                                                } else {
                                                                                                    alert("Start time must be less than end time.");
                                                                                                }
                                                                                            }}
                                                                                            options={getFilteredStartOptions(day.value, index)} // Filtered options for start time
                                                                                            className="align-self-end js-example-disabled mb-0"
                                                                                            styles={{
                                                                                                control: (base: any) => ({
                                                                                                    ...base,
                                                                                                    appearance: "none", // Removes the default arrow
                                                                                                    boxShadow: "none", // Removes focus shadow
                                                                                                    borderColor: "#ced4da", // Matches bootstrap input styling
                                                                                                }),
                                                                                                dropdownIndicator: () => ({
                                                                                                    display: "none", // Hides the arrow icon
                                                                                                }),
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <span>-</span>
                                                                                    {/* End Time */}
                                                                                    <div style={{ minWidth: "100px" }}>
                                                                                        <Select
                                                                                            isDisabled={!selectedDays[day.value]} // Disable if the day is not selected
                                                                                            value={{
                                                                                                value: interval.to,
                                                                                                label: formatTimeTo12Hour(interval.to), // Format the time to 12-hour format
                                                                                            }}
                                                                                            onChange={(option: any) => {
                                                                                                const startTime = selectedTimes[day.value]?.[index]?.from;
                                                                                                if (
                                                                                                    isEndTimeValid(startTime, option.value, selectedTimes[day.value], index)
                                                                                                ) {
                                                                                                    handleTimeChange(day.value, "end", option, index);
                                                                                                } else {
                                                                                                    alert("End time must be greater than start time.");
                                                                                                }
                                                                                            }}
                                                                                            options={getFilteredEndOptions(day.value, index)} // Filtered options for end time
                                                                                            className="align-self-end js-example-disabled mb-0"
                                                                                            styles={{
                                                                                                control: (base: any) => ({
                                                                                                    ...base,
                                                                                                    appearance: "none", // Removes the default arrow
                                                                                                    boxShadow: "none", // Removes focus shadow
                                                                                                    borderColor: "#ced4da", // Matches bootstrap input styling
                                                                                                }),
                                                                                                dropdownIndicator: () => ({
                                                                                                    display: "none", // Hides the arrow icon
                                                                                                }),
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    {/* Delete icon */}
                                                                                    <div style={{ marginTop: "4px" }}>
                                                                                        <i
                                                                                            className="text-dark fw-bold fs-18 ri-close-line align-middle"
                                                                                            onClick={() => handleDeleteInterval(day.value, index)} // Handle delete
                                                                                            style={{ cursor: "pointer" }}
                                                                                        ></i>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>

                                                                        <div style={{ marginTop: "10px" }}>
                                                                            <i
                                                                                className="text-dark fw-bold fs-18 ri-add-line align-middle"
                                                                                onClick={() => handleAddInterval(day.value)} // Handle adding a new interval
                                                                                style={{ cursor: "pointer" }}
                                                                            ></i>
                                                                        </div>

                                                                    </div>


                                                                </div>
                                                            ))}
                                                            <Button color="primary" onClick={handleAddAccount}>Save settings</Button>
                                                        </div>


                                                    </div>
                                                </Col>
                                                {/* <Col xxl={6} xl={6} lg={6} md={6} sm={6} className="">
                                                    <div>
                                                        <Label className="text-muted fs-16">
                                                            Date-specific hours
                                                        </Label>
                                                        <p>
                                                            Override your availability for specific dates when your hours differ from your regular weekly hours.
                                                        </p>
                                                        <div>
                                                            <Button color="light" className="rounded-pill"><i className="ri-add-fill"></i> Add date-specific hours </Button>
                                                        </div>
                                                    </div>
                                                </Col> */}
                                            </Row>
                                        }
                                        {activeButton === 'calendar' &&
                                            <div>
                                                <Card className="card-h-100">
                                                    <CardBody>
                                                        <FullCalendar
                                                            plugins={[
                                                                BootstrapTheme,
                                                                dayGridPlugin,
                                                                interactionPlugin,
                                                                listPlugin
                                                            ]}
                                                            // select={handleDateSelect}
                                                            initialView="dayGridMonth"
                                                            slotDuration={"00:15:00"}
                                                            themeSystem="bootstrap"
                                                            // headerToolbar={{
                                                            //     left: "prev,next today",
                                                            //     center: "title",
                                                            //     right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                                                            // }}
                                                            // events={events}
                                                            editable={true}
                                                            droppable={true}
                                                            selectable={true}
                                                        // dateClick={handleDateClick}
                                                        // eventClick={handleEventClick}
                                                        />
                                                    </CardBody>
                                                </Card></div>}
                                    </TabPane>
                                    <TabPane tabId="2" id="product">
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default Availability;