import React, { useState } from "react";
import { Button, Col, Label, Table } from "reactstrap";
import SimpleBar from "simplebar-react";

// Backend data with availability rules
const backendData = {
  rules: [
    { type: "wday", wday: "monday", intervals: [{ from: "09:00", to: "17:00" }], isEnabled: true },
    {
      type: "wday",
      wday: "tuesday",
      intervals: [
        { from: "09:00", to: "17:00" },
        { from: "17:00", to: "19:00" },
      ],
      isEnabled: true,
    },
    { type: "wday", wday: "wednesday", intervals: [], isEnabled: false },
    { type: "wday", wday: "thursday", intervals: [{ from: "09:00", to: "17:00" }], isEnabled: true },
    { type: "wday", wday: "friday", intervals: [{ from: "09:00", to: "12:00" }], isEnabled: true },
    { type: "wday", wday: "saturday", intervals: [], isEnabled: false },
    { type: "wday", wday: "sunday", intervals: [], isEnabled: false },
  ],
  timezone: "Asia/Calcutta",
  rules_version: 1,
};

const TimeSlotCalendar = ({ isScheduleConfirm, setIsScheduleConfirm }: any) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [timeSlots, setTimeSlots] = useState<string[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [clickedSlotIndex, setClickedSlotIndex] = useState<number | null>(null);

  const weekdayMap: Record<string, number> = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  // Helper to get the number of days in the month
  const getDaysInMonth = (month: Date) => {
    const date = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    return date.getDate();
  };

  const handleDateClick = (date: Date) => {
    setClickedSlotIndex(null)
    setSelectedDate(date);
    const selectedDay = date.getDay();
    const selectedRule = backendData.rules.find((rule) => weekdayMap[rule.wday] === selectedDay);

    if (selectedRule && selectedRule.intervals.length > 0) {
      const slots = selectedRule.intervals.flatMap((interval) => {
        const [startHour, startMinute] = interval.from.split(":").map(Number);
        const [endHour, endMinute] = interval.to.split(":").map(Number);

        const startTime = new Date(date);
        startTime.setHours(startHour, startMinute);

        const endTime = new Date(date);
        endTime.setHours(endHour, endMinute);

        const generatedSlots: string[] = [];
        while (startTime < endTime) {
          generatedSlots.push(
            startTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true }) // Format in AM/PM
          );
          startTime.setMinutes(startTime.getMinutes() + 30); // Increment by 30 minutes
        }
        return generatedSlots;
      });
      setTimeSlots(slots);
    } else {
      setTimeSlots([]);
    }
  };


  const changeMonth = (direction: "prev" | "next") => {
    const newMonth = new Date(currentMonth);
    newMonth.setMonth(currentMonth.getMonth() + (direction === "next" ? 1 : -1));
    setCurrentMonth(newMonth);
  };

  const handleSlotClick = (index: number) => {
    setClickedSlotIndex(index);
  };

  const handleNextClick = () => {
    setIsScheduleConfirm(true)
    // Add your logic for "Next" action here
  };

  // Get the current month and year
  const monthYear = `${currentMonth.toLocaleString("default", { month: "long" })} ${currentMonth.getFullYear()}`;

  const renderCalendarTable = () => {
    const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const daysInMonth = getDaysInMonth(currentMonth);
    const firstDayIndex = firstDayOfMonth.getDay();

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const rows = [];
    let cells = [];

    // Fill the first row with empty cells before the start of the month
    for (let i = 0; i < firstDayIndex; i++) {
      cells.push(<td key={`empty-${i}`} className="empty-day border-0 bg-light"></td>);
    }

    // Fill the days of the month
    for (let i = 1; i <= daysInMonth; i++) {
      const dayDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i);
      const dayOfWeek = dayDate.getDay();
      const ruleForDay = backendData.rules.find((rule) => weekdayMap[rule.wday] === dayOfWeek);
      const isEnabled = ruleForDay?.isEnabled ?? false;
      const isPastDate = dayDate < today && dayDate.getDate() !== today.getDate();

      cells.push(
        <td
          key={`day-${i}`}
          className={`border-0 day ${selectedDate?.toDateString() === dayDate.toDateString() ? "selected-day text-white bg-primary cursor-pointer" : ""}
                      ${isEnabled ? "cursor-pointer" : "disabled-day bg-light"}
                      ${isPastDate ? "disabled-day bg-light" : ""}
                      ${dayDate.toDateString() === today.toDateString() ? "text-primary fw-bold" : ""}`}
          onClick={() => !isPastDate && isEnabled && handleDateClick(dayDate)}
        >
          {i}
        </td>
      );

      // Start a new row every 7 cells (week)
      if ((cells.length % 7 === 0) || i === daysInMonth) {
        rows.push(<tr key={`row-${rows.length}`}>{cells}</tr>);
        cells = [];
      }
    }

    return rows;
  };


  return (
    <div className="d-flex">
      <Col xxl={9} className="p-3">
        <Label className="fs-5 fw-bold">Select a Date & Time</Label>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <Button onClick={() => changeMonth("prev")} color="primary">Prev</Button>
          <h5 className="m-0">{monthYear}</h5>
          <Button onClick={() => changeMonth("next")} color="primary">Next</Button>
        </div>
        <Table className="text-center">
          <thead className="table-light">
            <tr>{daysOfWeek.map((day, index) => <th className="text-light bg-primary" key={index}>{day}</th>)}</tr>
          </thead>
          <tbody className="fs-16">{renderCalendarTable()}</tbody>
        </Table>
      </Col>
      <Col xxl={3} className="p-1">
        {selectedDate ? (
          <>
            <p className="text-muted fw-medium fs-16">{selectedDate.toDateString()}</p>
            <SimpleBar style={{ width: "180px", maxHeight: "330px", overflowX: "hidden", whiteSpace: "nowrap" }}>
              <div className="m-2">
                {timeSlots.length > 0 ? (
                  timeSlots.map((slot, index) => (
                    <div className="text-center d-flex justify-content-between gap-1 p-1" key={index}>
                      {clickedSlotIndex === index ? (
                        <>
                          <Button disabled color="primary" className="p-1 text-center w-50">
                          {slot.trim()}  
                          </Button>
                          <Button outline color="success" className="w-50" onClick={handleNextClick}>
                            Next
                          </Button>
                        </>
                      ) : (
                        <Button
                          outline
                          color="primary"
                          className="fs-15 fw-bold w-100"
                          onClick={() => handleSlotClick(index)}
                        >
                          {slot}
                        </Button>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="text-muted fw-medium fs-16">No available time slots for this day.</p>
                )}
              </div>

            </SimpleBar>

          </>
        ) : <><p className="text-muted fw-medium fs-16">Select date to view available slots</p></>}
      </Col>
    </div>
  );
};

export default TimeSlotCalendar;
