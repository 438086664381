import { useParams } from "react-router-dom";
import { Col, Label } from "reactstrap";
import BookSlot from "./BookSlot";

const MeetingBooking = () => {
    const { tenId, eventTypeId } = useParams();
    return (
        <>
            <div className="position-fixed top-0 bottom-0 w-100 h-100 bg-light shadow-lg">
                {/* Header */}
                <div
                    className="position-relative bg-primary text-center"
                    style={{ minHeight: "60px", padding: "6px", zIndex: 10 }}
                >
                    <Label className="fs-20 text-light mt-2">Book Meeting Slot</Label>
                </div>

                {/* Content Section */}
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "calc(100% - 60px)" }} // Ensures content is centered excluding the header height
                >
                    <Col xxl={10} xl={10} lg={10}>
                    {/* Add your content here */}
                    <div style={{ height: "500px" }} className="bg-white rounded-4 shadow-lg p-4 mb-5">
                        <BookSlot tenId={tenId} eventTypeId={eventTypeId}/>
                    </div>
                    </Col>
                    
                </div>
            </div>


        </>
    )
}
export default MeetingBooking;