import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";
import Profile from "../Profile";
import MeetingsMenu from "../MeetingsMenu";

const ProfileMenus = () => {
    const [topBorderTab, settopBorderTab] = useState<any>("1");

    const topBordertoggle = (tab: any) => {
        if (topBorderTab !== tab) {
            settopBorderTab(tab);
        }
    };
    return (
        <>
            <Col xxl={12} className="mt-0">
                <Card>
                    <CardBody> 
                        <Nav tabs className="nav nav-tabs nav-border-top nav-border-top-primary mb-0">
                        <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: topBorderTab === "1", })} onClick={() => { topBordertoggle("1"); }} >
                                <i className="ri-account-circle-line align-middle me-1"></i> Profile
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: topBorderTab === "2", })} onClick={() => { topBordertoggle("2"); }} >
                                <i className="ri-calendar-check-line me-1 align-middle"></i> Meetings
                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: topBorderTab === "3", })} onClick={() => { topBordertoggle("3"); }} >
                                                <i className="ri-question-answer-line align-middle me-1"></i>Messages
                                            </NavLink>
                                        </NavItem> */}
                    </Nav>

                        <TabContent activeTab={topBorderTab} className="text-muted">
                            <TabPane className="mt-2" tabId="1" id="nav-border-justified-home">
                                <Profile></Profile>
                            </TabPane>

                            <TabPane tabId="2" id="nav-border-justified-profile">
                                <MeetingsMenu />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}
export default ProfileMenus;