import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Input, Button, Form } from 'reactstrap';
import ParticlesAuth from "./ParticlesAuth";
import { withTranslation, WithTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { useDispatch } from "react-redux";
import { joinUserRequest, verifyJoinLink } from "store/auth/action";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { jwtDecode } from 'jwt-decode'
import logo from "../../assets/images/dtsimg/svg/zilter_logo_white.svg";
import JoinError from './JoinError';
import { ToastContainer, toast } from 'react-toastify';
import config from "../../config";
import { useGoogleLogin } from '@react-oauth/google';
import { postGoogleCalendarSync } from 'store/calendarSync/action';
import FormBuilder from 'Components/Common/FormBuilder/FormBuilder';
import country from '../../Components/Common/FormBuilder/countrycode.json'

const { link: { TERMS_OF_USE } } = config;

interface FormData {
    email: string;
    fullname: string;
    password: string;
    user_id: string;
    phone_number: any;
    countrycode: any;
}

const JoinUser: React.FC<WithTranslation> = (props: any) => {
    document.title = "Join User | Zilter";
    const { id } = useParams<{ id: string }>();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [token, setToken] = useState<string>("");
    const [isValid, setISValid] = useState<boolean>(true);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [rest, setRest] = useState<any>({
        validate: { required: true }
    });
    const navigate = useNavigate();
    const [inputs, setInputs] = useState<FormData>({
        email: '',
        fullname: '',
        password: '',
        user_id: '',
        phone_number: '',
        countrycode: ''
    });

    const islen = () => {
        let str = inputs.password;
        if (str && str.length >= 8) return true;
        else return false;
    };

    const signUpGoogle = useGoogleLogin({
        onSuccess: codeResponse => {
            handleGoogleSignup(codeResponse.code, codeResponse.scope);
        },
        onError: (error) => console.log('Login Failed:', error),
        flow: 'auth-code',
    });
    
    const handleGoogleSignup = (code: any, scope: any) => {
        const data =
        {
            code: code,
            scope: "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly",
            provider: 'google',
            isSignup: 'true'
        }
        const handleSuccess = (body: any): void => {
            //   toggleArrowTab(4);
        };
        const handleError = (body: any): void => {
        };
        dispatch(postGoogleCalendarSync(data, handleSuccess, handleError));
    };

    const onSubmit = (): void => {
        const data = {
            "attributes": [
                {
                    "key": "name",
                    "value": inputs.fullname
                },
                {
                    "key": "password",
                    "value": inputs.password
                },
                {
                    "key": "phone_number",
                    "value": `${inputs.countrycode}${inputs.phone_number}`
                }
            ]
        }
        const handleSuccess = (body: any): void => {
            const message = () => toast(`You have Signup Successfully.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            navigate('/signin')
        }
        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        }
        dispatch(joinUserRequest(inputs.user_id, data, token, handleSuccess, handleError));
    }

    useEffect(() => {
        const handleSuccess = (body: any): void => {
            setISValid(true)
            setToken(body.token)
            const user: any = jwtDecode(body.token);
            if (user && user.sub) {
                setInputs({ ...inputs, email: user.sub, fullname: user.name, user_id: user.user_id })
            }
        }
        const handleError = (body: any): void => {
            setISValid(false)
        }
        dispatch(verifyJoinLink(id, handleSuccess, handleError));
    }, []);

    useEffect(() => {
        setDisabled(islen() && inputs.password !== "" && /[a-z]/.test(inputs.password) &&
            inputs.password !== "" && /[A-Z]/.test(inputs.password) &&
            inputs.password !== "" && /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/.test(inputs.password) && inputs.fullname != "" && inputs.phone_number !="" && /^[0-9]+$/.test(inputs.phone_number) && inputs.countrycode !="")
    }, [inputs.password, inputs.fullname, inputs.phone_number, inputs.countrycode])

    const onChange = (value: any) => {
        if (/[!@#$%^&*(),.?":{}|<>]/.test(value)) return
        else setInputs({ ...inputs, fullname: value })
    }

    const onhandleChange = (value: any) => {
        let dial_code: any = ""
        let restnumber=""
        if(value) {
            country.map((item: any) => {
                if (value.includes(item.dial_code)) {
                    return dial_code = item.dial_code
                }                    
            })         
            restnumber = value.slice(dial_code.length)      
        }       
        setInputs({...inputs, phone_number: restnumber, countrycode:dial_code})
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <ParticlesAuth>
                {
                    isValid ?
                        <div className="auth-page-content">
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center mt-sm-5 mb-4 text-white-50">
                                            <div>
                                                <Link to="/#" className="d-inline-block auth-logo">
                                                    <img src={logo} alt="" height="50" />
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={8} lg={6} xl={5}>
                                        <Card className="mt-4">
                                            <CardBody className="p-4">
                                                <div className="text-center mt-2">
                                                    <h5 className="text-primary">{props.t("joinuser.create_new_account")}</h5>
                                                </div>
                                                <div className="p-2 mt-4">
                                                    <Form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        onSubmit();
                                                        return false;
                                                    }} className="needs-validation" action="#">
                                                        <div className="mb-3">
                                                            <label htmlFor="email" className="form-label fw-bold fs-14 ">{props.t("joinuser.email")}<span className="text-danger">*</span></label>
                                                            <Input type="email" className="form-control" id="email" placeholder={props.t("joinuser.enter_email_address")}
                                                                name="email"
                                                                value={inputs.email}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="fullname" className="form-label fw-bold fs-14">{props.t("joinuser.full_name")}<span className="text-danger">*</span></label>
                                                            <Input type="text" className="form-control" id="fullname" placeholder={props.t("joinuser.enter_full_name")}
                                                                name="fullname"
                                                                value={inputs.fullname}
                                                                onChange={(e) => onChange(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <FormBuilder
                                                                label={props.t("signup.phone_number")}
                                                                type={"phone"}
                                                                defaultValue={inputs.phone_number}
                                                                value={inputs.phone_number}
                                                                name={"phone_number"}
                                                                placeholder={props.t("signup.enter_phone_number")}
                                                                className="w-100 h-100"
                                                                onChange={(value: any) => onhandleChange(value)}
                                                                rest={rest}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="password-input">{props.t("joinuser.password")}<span className="text-danger">*</span></label>
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    type={passwordShow ? "text" : "password"}
                                                                    className="form-control pe-5 password-input"
                                                                    placeholder={props.t("joinuser.enter_password")}
                                                                    id="password-input"
                                                                    name="password"
                                                                    value={inputs.password ? inputs.password : ""}
                                                                    onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                                                                />
                                                                {passwordShow ? <button   className="btn btn-sm btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><FeatherIcon icon="eye" className="m-1 icon-xs icon-dual-primary" /></button> :
                                                            <button className="btn  btn-sm btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><FeatherIcon icon="eye-off" className="m-1 icon-xs icon-dual-primary" /></button>}

                                                            </div>
                                                            <div className="p-1 mt-1 mb-1">
                                                                <div className="fs-14 fw-bold">{props.t("joinuser.password_must_contain")}:</div>
                                                                <div className="fs-14 p-1">
                                                                    {islen() ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                                                                    {" "}{props.t("joinuser.at_least_8_characters")}.
                                                                </div>
                                                                <div className="fs-14 p-1">
                                                                    {inputs.password !== "" && /[a-z]/.test(inputs.password) ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                                                                    {" "}{props.t("joinuser.one_lower_case")}.</div>
                                                                <div className="fs-14 p-1">
                                                                    {inputs.password !== "" && /[A-Z]/.test(inputs.password) ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                                                                    {" "}{props.t("joinuser.one_upper_case")}.
                                                                </div>
                                                                <div className="fs-14 p-1">
                                                                    {inputs.password !== "" && /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/.test(inputs.password) ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                                                                    {" "}{props.t("joinuser.one_number_symbols_character")}.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-4">
                                                            <p className="mb-0 fs-12 text-muted fst-italic">{props.t("joinuser.by_registering_you_agree_to_the_zilter")}&nbsp;
                                                                <Link to={`${TERMS_OF_USE}`} className="text-primary text-decoration-underline fst-normal fw-medium">{props.t("joinuser.terms_of_use")}</Link></p>
                                                        </div>
                                                        <div className="mt-4">
                                                            <button className="btn btn-success w-100" type="submit" disabled={!disabled}>{props.t("joinuser.sign_up")}</button>
                                                        </div>
                                                        {/* <div className="mt-4 text-center">
                                                            <div className="signin-other-title">
                                                                <h5 className="fs-13 mb-4 title text-muted">{props.t("joinuser.create_account_with")}</h5>
                                                            </div>
                                                            <div>
                                                                <button type="button"
                                                                    onClick={signUpGoogle}
                                                                    className="w-100 fw-bold btn btn-primary bg-gradient mb-2 btn-label right fw-semibold">
                                                                    <i className="ri-arrow-right-line ri-google-fill fs-16 label-icon align-middle ms-2"></i>
                                                                    {props.t("joinuser.continue_with_google")}
                                                                </button>
                                                            </div>
                                                        </div> */}
                                                    </Form>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        : <JoinError />
                }
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withTranslation()(JoinUser);

