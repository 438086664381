import React from "react";
import { Button, Container, Row } from "reactstrap";
import UserPreferences from "./UserPreferences";
import { useNavigate } from 'react-router-dom';

const index = () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      const onBack =  () => {
        navigate('/');
    }
  document.title = "DTS | Zilter";


  return (
    <div className="position-fixed top-0 bottom-0 w-100 h-100 bg-light" style={{zIndex: 1003}}>
    <div className="d-flex justify-content-between bg-primary p-2 align-items-center">
        <div>
            <Button
                color="primary"
                className="btn-label"
                onClick={() => onBack()}
            >
                    <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                    Back
            </Button>
        </div>
    
            </div>
            <UserPreferences  />
            </div>
  );
};

export default index;