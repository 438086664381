import { useEffect, useState } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Input,
    Row,
    Col,
    Card,
    Button,
} from "reactstrap";
import Select from "react-select";

const AutoPreview = ({ EditInputs, setEditInputs, fieldJson, setFieldJson, fieldType}: any) => {
    const [hover, setHover] = useState<any>(false)
    const [value, setValue] = useState<any>(false)
    const [prefix, setPrefix] = useState<any>('')
    const [autoincrement, setAutoincrement] = useState<any>(1)
    const [resetType, setResetType] = useState<any>(
        {
            label: "Monthly",
            value: "monthly"
        }
    )

    const resetOptions = [
        {
            label: "Yearly",
            value: "yearly"
        },
        {
            label: "Monthly",
            value: "monthly"
        }
    ]

    useEffect(() => {
        setPrefix(EditInputs?.uuidFormat?.prefix)
        setAutoincrement(EditInputs?.uuidFormat?.autoincrement)
        let obj = resetOptions.find((item) => item.value === EditInputs?.uuidFormat?.reset)
        setResetType(obj)
    }, [EditInputs?.uuidFormat]);

    return (
        <div>
            <Row className="hstack gap-2">
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div>
                        <Label className="fs-16 fw-semibold">Auto Increment Format</Label>
                    </div>
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div>
                        <Label className="fs-14 fw-semibold">Prefix</Label>
                        <Input
                            defaultOptions
                            disabled
                            placeholder={'Enter prefix'}
                            className="fs-14 fw-medium"
                            value={prefix}
                            onChange={(e: any) => setPrefix(e?.target?.value)}
                            name="choices-single-default"
                        ></Input>
                    </div>
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div>
                        <Label className="fs-14 fw-semibold">Reset</Label>
                        <Select
                            placeholder={'Select field type'}
                            className="fs-14 fw-medium"
                            isDisabled={true}
                            value={resetType}
                            onChange={(option: any) => setResetType(option)}
                            options={resetOptions}
                            name="choices-single-default"
                        ></Select>
                    </div>
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div>
                        <Label className="fs-14 fw-semibold">Auto-increment by</Label><br />
                        <div className="input-step step-info" style={{ marginLeft: '5px', width: 200 }}>
                            <button
                                type="button"
                                disabled
                                className="minus"
                                onClick={() => {
                                    setAutoincrement(autoincrement - 1);
                                    // handleselected(autoincrement - 1, "autoincrement");
                                }}
                            >
                                –
                            </button>
                            <Input
                                type="number"
                                value={autoincrement}
                                disabled
                                className="w-100"
                                min="30"
                                max="1000"
                                onChange={(e) => setAutoincrement(e.target.value)}
                            />
                            <button
                                type="button"
                                className="plus"
                                disabled
                                onClick={() => {
                                    setAutoincrement(autoincrement + 1);
                                    // handleselected(autoincrement + 1, "autoincrement");
                                }}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* <div id="myDivparent">
                <div className="fs-16 fw-semibold">Preview</div>
                <Card className="m-3" id="myDivChild">
                    <FormBuilder
                        label={EditInputs.label}
                        type={fieldType}
                        value={value}
                        placeholder={EditInputs.placeholder}
                        className="w-100 h-100 text-start"
                        onChange={(value: any) => {
                            setValue(value)
                        }}
                        actionButtons={true}
                        defaultValue={'Sample text'}
                        Editable={true}
                        rest={fieldJson}
                    />
                </Card>
            </div> */}
        </div>
    );
};

export default AutoPreview;
