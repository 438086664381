export interface EmailsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Emails {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export enum EmailsActionTypes {
  GET_EMAILMETADATA_REQUEST = "@@email/GET_EMAILMETADATA_REQUEST",
  GET_EMAILMETADATA_SUCCESS = "@@email/GET_EMAILMETADATA_SUCCESS",
  GET_EMAILMETADATA_ERROR = "@@email/GET_EMAILMETADATA_ERROR",
  GET_EMAIL_REQUEST = "@@email/GET_EMAIL_REQUEST",
  GET_EMAIL_SUCCESS = "@@email/GET_EMAIL_SUCCESS",
  GET_EMAIL_ERROR = "@@email/GET_EMAIL_ERROR",
  GET_EMAILS_REQUEST = "@@email/GET_EMAILS_REQUEST",
  GET_EMAILS_SUCCESS = "@@email/GET_EMAILS_SUCCESS",
  GET_EMAILS_ERROR = "@@email/GET_EMAILS_ERROR",
  PUT_EMAIL_REQUEST = "@@email/PUT_EMAIL_REQUEST",
  PUT_EMAIL_SUCCESS = "@@email/PUT_EMAIL_SUCCESS",
  PUT_EMAIL_ERROR = "@@email/PUT_EMAIL_ERROR",
  DELETE_EMAIL_REQUEST = "@@email/DELETE_EMAIL_REQUEST",
  DELETE_EMAIL_SUCCESS = "@@email/DELETE_EMAIL_SUCCESS",
  DELETE_EMAIL_ERROR = "@@email/DELETE_EMAIL_ERROR",
  POST_EMAIL_REQUEST = "@@email/POST_EMAIL_REQUEST",
  POST_EMAIL_SUCCESS = "@@email/POST_EMAIL_SUCCESS",
  POST_EMAIL_ERROR = "@@email/POST_EMAIL_ERROR",
}

export interface EmailsState {
  readonly list: Emails[] | null;
  readonly details: Emails | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: EmailsMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;
}
