/* eslint-disable @typescript-eslint/no-unused-vars */
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "./ChartsDynamicColor";
import { calculateObjectDetails, ObjectDetails } from '../../utils';
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { handleSelectValue } from "utils";
import UpdateInfo from "./RecordDetails/innerComponent/UpdateInfo";
import SimpleBar from "simplebar-react";

interface ParsedField {
    id: string;
    // Add other properties that will be included after parsing
    parsedValues: any; // Adjust this type based on the actual structure of parsed values
}

const ProgressBar = ({ details, dataFields, parsedValuesJson, model, getDetailsAction, updateAction, metaDataFields }: any) => {
    const [modal_backdrop, setmodal_backdrop] = useState<boolean>(false);
    const [formValues, setFormValues] = useState([]);
    const [complete, setComplete] = useState<number>(0);
    const [inComplete, setInComplete] = useState<number>(0);
    const [modalTodo, setModalTodo] = useState<boolean>(false);
    const [completePercentage, setCompletePercentage] = useState<number>(0);
    const [inCompletePercentage, setInCompletePercentage] = useState<number>(0);

    useEffect(() => {
        let newData: any = {};
        let invalidCount = 0;
        let validCount = 0;
        let totalCount = 0;

        if (details && parsedValuesJson) {
            let info = details?.valuesJson;
            if (Array.isArray(parsedValuesJson) && parsedValuesJson?.length) {
                totalCount = parsedValuesJson?.length;
                parsedValuesJson?.forEach((item: any) => {
                    let rendervalue = info?.[item.key];

                    if (rendervalue !== null && rendervalue !== undefined && rendervalue !== '') {
                        validCount++;
                    } else {
                        invalidCount++;
                    }
                });
            }
            setFormValues(newData);
        }

        const completePercent = totalCount > 0 ? (validCount / totalCount) * 100 : 0;
        const inCompletePercent = totalCount > 0 ? (invalidCount / totalCount) * 100 : 0;
        setComplete(validCount);
        setInComplete(invalidCount);
        setCompletePercentage(completePercent);
        setInCompletePercentage(inCompletePercent);
    }, [details, dataFields, parsedValuesJson, modal_backdrop]);

    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
    }

    const ApplicationChart = ({ dataColors }: any) => {
        var chartDonutupdatingColors = getChartColorsArray(dataColors);
        const series = [completePercentage, inCompletePercentage]
        var options = {
            chart: {
                height: 200,
                type: 'donut',
            },
            labels: ["Completed", "Incomplete"],
            plotOptions: {
                pie: {
                    donut: {
                        // size: '80%', // Adjust donut size to reduce space
                        labels: {
                            show: false,
                        },
                    },
                },
            },
            stroke: {
                width: 0 // Remove border/stroke around segments
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                position: 'bottom'
            },
            colors: chartDonutupdatingColors,
            tooltip: {
                y: {
                    formatter: function (val: number, { seriesIndex }: { seriesIndex: number }) {
                        const messages = [
                            "Completed: " + val.toFixed(2) + "%",
                            "Incomplete: " + val.toFixed(2) + "%",
                        ];
                        return messages[seriesIndex]; // Display a relevant message for each segment
                    },
                },
                style: {
                    background: "info",
                    fontSize: '13px',  // Consistent font size
                    fontFamily: 'Arial, sans-serif',  // Consistent font family
                },
                theme: "dark",  // Use "dark" or "light" theme for background consistency
            },
            fill: {
                opacity: 1, // Disable hover effect by keeping opacity constant
            },
            states: {
                hover: {
                    filter: {
                        type: 'none' // No hover effect on chart slices
                    }
                }
            }
        };
        return (
            <ReactApexChart dir="ltr"
                className="apex-charts"
                series={series}
                options={options}
                type="donut"
                height={200}
            />

        )
    }

    return (
        <>
            <Modal
                isOpen={modal_backdrop}
                toggle={() => {
                    tog_backdrop();
                }}
                backdrop={'static'}
                id="staticBackdrop"
                centered
            >
                <ModalHeader className="align-items-center bg-primary-subtle pb-2 text-light" toggle={() => {
                    tog_backdrop();
                }}>
                    <h5 className="" id="staticBackdropLabel">Complete profile</h5>

                </ModalHeader>
                <ModalBody className="p-2">
                    <div className="border border-1 rounded-3 p-3">
                        <UpdateInfo model={model} details={details} getDetailsAction={getDetailsAction} updateAction={updateAction} metaDataFields={dataFields} />

                    </div>

                </ModalBody>
            </Modal>
            {completePercentage === 0 && inCompletePercentage === 0 ? (
                <div className="text-center border border-1 mb-2 p-3 rounded-3">
                    <Label className="fs-16 text-muted mb-0">No fields selected for profile completion information.</Label>
                </div>
            ) : (
<div className="h-100 border border-1 mb-2 p-3 rounded-3">
                <Row>
                    <Col xl={4} className="text-center">
                        <Label>
                            {model === "students" ? "Student" : "Application"} Profile Completeness
                        </Label>
                        <ApplicationChart dataColors='[ "--vz-success", "--vz-primary"]' />
                    </Col>
                    {inCompletePercentage > 0 ? (
                        <Col xl={8} className="fs-30 align-items-baseline mt-5 fw-bold">
                            <p>
                                You're almost there! Just <strong className="text-primary">{inCompletePercentage?.toFixed(0)}%</strong> more to complete your profile.
                            </p>
                            <Button
                                onClick={() => setmodal_backdrop(true)}
                                color="primary"
                                className="btn-sm btn-label">
                                <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                {"Complete profile"}
                            </Button>
                        </Col>
                    ) : (
                        <Col xl={8} className="fs-30 align-items-baseline mt-5 fw-bold">
                            <p>
                                Your profile completion status is <strong className="text-primary">{completePercentage?.toFixed(0)}%</strong>.
                            </p>
                           
                        </Col>
                    )}
                </Row>
            </div>
            )}
            

        </>
    )
}

export default ProgressBar;