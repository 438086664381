import {
    Modal,
    ModalHeader,
    ModalBody,
    Card,
    CardBody,
} from "reactstrap";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { useEffect, useState } from "react";
import { viewCreateRequest, viewListGetRequest } from "store/views/action";

const UpdateView = ({ props, show, onCloseClick, dataFields, onUpdate, data}: any) => {   
    

    return (
        <Modal
            direction="end"
            isOpen={show}
            id="ModalExample"
            toggle={onCloseClick}
            backdrop={false}
        >

            <ModalHeader className="p-3 Modal-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={() => { onCloseClick(!show) }}>
                <span className="m-0 me-2 text-white">
                    {props.t("student.add_new_entry")}
                </span>
            </ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody>
                        <div className="live-preview vstack gap-2">
                            <QuickForm dataFields={dataFields} btnAction={onUpdate} btn_text={'Update Entry'} formfor="students" data={data} />
                        </div>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};

export default UpdateView;

