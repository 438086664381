export interface TasksMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Tasks {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export enum TasksActionTypes {
  GET_TASKMETADATA_REQUEST = "@@task/GET_TASKMETADATA_REQUEST",
  GET_TASKMETADATA_SUCCESS = "@@task/GET_TASKMETADATA_SUCCESS",
  GET_TASKMETADATA_ERROR = "@@task/GET_TASKMETADATA_ERROR",
  GET_TASK_REQUEST = "@@task/GET_TASK_REQUEST",
  GET_TASK_SUCCESS = "@@task/GET_TASK_SUCCESS",
  GET_TASK_ERROR = "@@task/GET_TASK_ERROR",
  GET_TASKS_REQUEST = "@@task/GET_TASKS_REQUEST",
  GET_TASKS_SUCCESS = "@@task/GET_TASKS_SUCCESS",
  GET_TASKS_ERROR = "@@task/GET_TASKS_ERROR",
  PUT_TASK_REQUEST = "@@task/PUT_TASK_REQUEST",
  PUT_TASK_SUCCESS = "@@task/PUT_TASK_SUCCESS",
  PUT_TASK_ERROR = "@@task/PUT_TASK_ERROR",
  DELETE_TASK_REQUEST = "@@task/DELETE_TASK_REQUEST",
  DELETE_TASK_SUCCESS = "@@task/DELETE_TASK_SUCCESS",
  DELETE_TASK_ERROR = "@@task/DELETE_TASK_ERROR",
  POST_TASK_REQUEST = "@@task/POST_TASK_REQUEST",
  POST_TASK_SUCCESS = "@@task/POST_TASK_SUCCESS",
  POST_TASK_ERROR = "@@task/POST_TASK_ERROR",
}

export interface TasksState {
  readonly list: Tasks[] | null;
  readonly details: Tasks | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: TasksMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;
}
