/**
 * Converts snake_case, kebab-case, or space-separated strings to Capitalized Words,
 * and removes trailing "_id" if present.
 * @param str The string to transform
 * @returns The transformed string with capitalized words and "_id" removed
 */
const convertToTitleCase = (str: string): string => {
  return str
      .replace(/_id$/, '') // Remove trailing "_id"
      .replace(/[_-]/g, ' ') // Replace underscores or hyphens with spaces
      .split(' ') // Split by spaces
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      .join(' '); // Join with a space
}

export default convertToTitleCase;
