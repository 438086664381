import React from 'react';
import { Container, Row } from 'reactstrap';
import FormBuilder from './Form';

const CustomForms = () => {
    
	document.title = "DTS | Zilter - Forms";

    return (
        <React.Fragment>
            <div className="p-5">
                <Container fluid>                           
                    <Row>
                        <FormBuilder />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CustomForms;