import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { convertCurrency, handleSelectValue, isJson } from "utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { currencyGetRequest } from "store/currency/action";
import { ApplicationState } from "store";

const PreviewModal: React.FC<any> = ({recommendLoading, onRemove,disabled, handleButtonClick, props,link, createRecommend,show, onCloseClick, selectedlist, shortlistFields, isSubagent, createRecommendSubAgent }: any) => {  
    const dispatch: any = useDispatch();    const conversionRates = useSelector((state: ApplicationState) => state.currency.conversion);
    const updateCurrecyState = useSelector((state: ApplicationState) => state.currency.updatedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
    const [currencySymbol, setCurrencySymbol] = useState<any>(null)
    const [assignRates, setAssignRates] = useState<any>(null)
    const [parsedRates, setParsedRates] = useState<any>(null)

    useEffect(() => {
        dispatch(currencyGetRequest())
    }, [])

    useEffect(() => {
        if (updateCurrecyState) {
            setSelectedCurrency(updateCurrecyState.selectedCurrency);
            setCurrencySymbol(updateCurrecyState.selectedCurrencySymbol)
        }
    }, [updateCurrecyState]);

    useEffect(() => {
        if (conversionRates) {
            setAssignRates(conversionRates)
        }
    }, [conversionRates])

    useEffect(() => {
        if (assignRates && assignRates?.length > 0) {
            const targetCurrencyJson = assignRates[0]?.targetCurrencyJson;

            // Check if `targetCurrencyJson` exists and is a valid JSON string
            const convertJson = targetCurrencyJson && isJson(targetCurrencyJson)
                ? JSON.parse(targetCurrencyJson)
                : {};

            // Set the parsed rates only if convertJson is valid
            setParsedRates(convertJson?.conversion_rates);
        }
    }, [assignRates]);

    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    }; 


   return (<>
        <Modal
            direction="end"
            isOpen={show}
            id="ModalExample"
            toggle={onCloseClick}
            size="lg"
        >
            <ModalHeader className="bg-light" toggle={onCloseClick}>
                Review and Send
            </ModalHeader>
            <ModalBody>
                {
                        link ? 
                        <div className="p-5 ms-4 text-center">
                            <h5>
                                Shortlisted Course Sent Successfully...!
                            </h5>                            
                            <Button className="btn-success" onClick={() => handleButtonClick()}>Copy Link</Button>
                        </div>
                    :<div>
                    {!recommendLoading ? 
                        <div className="table-responsive">
                            <table className="table align-middle position-relative table-nowrap">
                                <thead className="cursor-pointer table-active">
                                    <tr>
                                        <th>
                                            Sr. no
                                        </th>
                                        {shortlistFields?.length && shortlistFields.map((item: any) => {
                                            return <th>
                                                {item.label}
                                            </th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody id="task-list">                                    
                                    {selectedlist && selectedlist.map((record: any, key: any) => {
                                            return <tr>
                                                 <td>
                                                    <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onRemove(record, false)}
                                                                >
                                                        <i className="ri-delete-bin-fill"></i>
                                                    </button>
                                                </td>
                                                {
                                                shortlistFields && shortlistFields.length && shortlistFields.map((fields: any) => {
                                                    let rendervalue = record[fields.key];
                                                    let originalFee = parseFloat(record['course_fee']);
                                                    let originalCurrency = typeof record['currency'] === "string" ? record['currency']?.toUpperCase().match(/[A-Z]+/g)?.[0] : null;
                                                   
                                                    if (fields.key === 'course_fee') {
                                                        if (originalCurrency === selectedCurrency || isNaN(originalFee) || !originalCurrency || originalCurrency.toLowerCase() === "na") {
                                                            return <td>{rendervalue}</td>;
                                                        } else {
                                                            if (parsedRates) {
                                                            const convertedAmount = convertCurrency(originalFee, originalCurrency, selectedCurrency, parsedRates);
                                                            return <td>{currencySymbol} {convertedAmount?.toFixed(2)}</td>;
                                                        }
                                                    }
                                                    }
                                                    
                                                    if(fields.key == 'currency') {
                                                        if(selectedCurrency && originalCurrency) {
                                                            return <td>{selectedCurrency}</td>;
                                                        } else {
                                                            return <td>{}</td>
                                                        }
                                                    }
                                                    if(fields.tableRenderType == 'hyperlink' && record?.[fields.key] && record?.[fields.key] !== "NA") {
                                                        rendervalue = <div className='ms-3'>
                                                                <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(record[fields.key])}/>
                                                            </div>
                                                    }                            
                                                    else if(fields.tableRenderType == 'tag') {            
                                                        let value = record?.[fields.key]
                                                        let intakearray = value.split(",")
                                                       rendervalue = <div>
                                                            {intakearray?.length && intakearray.map((item: any) => {                                          
                                                                return <div className='ms-0'>
                                                                    <span className="badge bg-success">{handleSelectValue(item, fields.values)}</span>
                                                                </div>
                                                            })}
                                                        </div>
                                                    }                                                    
                                                    else if (fields?.key && fields?.optionLabel) {
                                                        rendervalue = record?.[fields.key]?.[fields.optionLabel] ? record?.[fields.key]?.[fields.optionLabel] : 'NA'
                                                    }     
                                                    if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
                                                        rendervalue = handleSelectValue(record[fields.key], fields.values);
                                                    }
                                                    return <td>{rendervalue}</td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                            
                        </div>: <DtsTablePlaceholder 
                            rows={selectedlist.length} cols={5}                                     
                            tableClass="align-middle table-nowrap"
                            thClass="border-bottom-1 table-soft-primary"
                        />
                    }
                    </div>
                }
            </ModalBody>
            {
                        link ? null :
                <div className="Modal-footer border-top p-3 text-center hstack gap-2 justify-content-center">
                    <Button
                        className="btn btn-primary w-auto"
                        onClick={() => { 
                            if (!isSubagent){
                                createRecommend();
                            } 
                            else {
                                createRecommendSubAgent();
                            }}}
                        
                        disabled={disabled}
                    >
                      {isSubagent ? "Apply" : props.t("courses.generate_shortlist")}
                    </Button>
                    <Button
                        className="btn btn-light w-auto"
                        onClick={() => onCloseClick()}
                    >
                        {props.t("courses.cancel")}
                    </Button>
                </div>
            }
        </Modal>
    </>
    );
};

export default PreviewModal;
