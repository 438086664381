import { Fragment, useEffect, useState } from "react";
import {
    useTable,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Input, Label } from "reactstrap";

const TableContainer = ({
    columns,
    data,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    setIsMultiDeleteButton, 
    setselectedcount,
    handleselect,
    checkedRows,
    handleToggleCheckbox
}: any) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: {},
            initialState: {
                pageIndex: 0, pageSize: 1000, selectedRowIds: 0, sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useExpanded,
        usePagination,
        useRowSelect
    );

    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
    };

    return (
        <Fragment>
            <div className={divClass}>
                <Table hover {...getTableProps()} className={tableClass}>
                    <thead className={theadClass}>
                        {headerGroups.map((headerGroup: any) => (
                            <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                <th scope="col" style={{ "width": "42px", "justifyContent": "start" }}>
                                    <div className="form-check justify-content-start align-items-center d-flex mt-1">
                                        <Input type="checkbox" className="form-check-input" id="checkBoxAll" checked={data?.length === Object.entries(checkedRows).length} onClick={(e: any) => handleToggleCheckbox(e?.target?.checked)} />
                                        <Label className="form-check-label" for="activetableCheck"></Label>
                                    </div>
                                </th>
                                {headerGroup.headers.map((column: any) => (
                                    <th key={column.id} className={`${thClass} justify-content-start`}>
                                        {column.render("Header")}
                                        {generateSortingIndicator(column)}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row: any) => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr>
                                        <th scope="row">
                                            <div className="form-check">
                                            <Input type="checkbox" key={"checkBoxAll"} className="leadsCheckBox form-check-input" 
                                                    checked={checkedRows[row.original.id] === undefined ? false : checkedRows[row.original.id]}                                          
                                                    onChange={(e) => {handleselect(row.original, e.target.checked);}}
                                                />
                                                <Label className="form-check-label" for={row.getRowProps().key}></Label>
                                            </div>
                                        </th>
                                        {row.cells.map((cell: any) => {
                                            let classList = cell.column.className;
                                            return (
                                                <td key={cell.id} className={`${classList}`} {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    );
};

export default TableContainer;