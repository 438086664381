import produce from 'immer';
import { Reducer } from "redux";
import { EmailsActionTypes, EmailsState } from "./types";


export const initialState: EmailsState =
{
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    loading: false,
    dataLoading: false,
    metaLoading: false
}
const reducer: Reducer<EmailsState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case EmailsActionTypes.GET_EMAILMETADATA_REQUEST: {
            return { ...state, metaLoading: true,  metaDataFields: [] };
        }
        case EmailsActionTypes.GET_EMAILMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case EmailsActionTypes.GET_EMAILMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload,  metaDataFields: [] };
        }
        case EmailsActionTypes.GET_EMAIL_REQUEST: {
            return { ...state, loading: true };
        }
        case EmailsActionTypes.GET_EMAIL_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case EmailsActionTypes.GET_EMAIL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case EmailsActionTypes.POST_EMAIL_REQUEST: {
            return { ...state, loading: true };
        }
        case EmailsActionTypes.POST_EMAIL_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case EmailsActionTypes.POST_EMAIL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case EmailsActionTypes.GET_EMAILS_REQUEST: {
            return { ...state, loading: true, list: [] };
        }
        case EmailsActionTypes.GET_EMAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case EmailsActionTypes.GET_EMAILS_ERROR: {
            return { ...state, loading: false, errors: action.payload, list: []};
        } 
        case EmailsActionTypes.PUT_EMAIL_REQUEST: {
            return { ...state, loading: true };
        }
        case EmailsActionTypes.PUT_EMAIL_SUCCESS: {
            return {
                ...state,
                loading: false,
                emaillist: action.payload.content
            };
        }
        case EmailsActionTypes.PUT_EMAIL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case EmailsActionTypes.DELETE_EMAIL_REQUEST: {
            return { ...state, loading: true };
        }
        case EmailsActionTypes.DELETE_EMAIL_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case EmailsActionTypes.DELETE_EMAIL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as EmailsReducer };
