import { useState, useEffect, useRef } from 'react'
import TreeView from './Treeview';
import SimpleBar from 'simplebar-react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

const CustomSelect = ({ options, selectedOption, setSelectedOption, onFocus, loading, label }: any) => {
    const dropdownRef: any = useRef(null)
    const [expanded, setExpanded] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOptionObject, setSelectedOptionObject] = useState<any>(null)

    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleExpand = (id: any, e: any) => {
        e.preventDefault()
        setExpanded((prev: any) => ({ ...prev, [id]: !prev[id] }))
    }

    const handleSelect = (option: any, e: any ) => {
        e.preventDefault()
        setSelectedOption(option)
        setSelectedOptionObject(option)
    }

    useEffect(() => {
        setSelectedOptionObject(selectedOption)
    }, [selectedOption])
    return (
        <div className="relative w-100" ref={dropdownRef}>
            <div className="w-100">
                <button
                    className="form-control form-select-sm p-0 d-flex align-items-center"
                    onClick={() => setIsOpen(!isOpen)}
                    style={{height: '37px'}}    
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}           
                    onFocus={onFocus}
                    type="button"
                ><div style={{width:'92%', color:layoutModeType == "light" ? "rgb(33, 37, 41)" : "white" }} className='hstack justify-content-between ps-2 fw-bold'>
                    {selectedOptionObject?.label ? <span>{selectedOptionObject?.label}</span> :<span style={{color: layoutModeType == 'light' ? '#AAAAAA' : '#808080'}} className='fw-nomal'>{`Select ${label}`}</span>}
                </div>                    
                <div className="border border-0 border-start border-1 border-dark border-opacity-25 ps-1 mx-1 " style={{width:'8%'}}>
                    {isOpen ? (
                        <i className={'ri-arrow-up-s-line cursor-pointer fs-18 text-opacity-25'} />
                    ) : (
                        <i className={' ri-arrow-down-s-line cursor-pointer fs-18  text-opacity-25'} />
                    )}
                </div>
                </button>
                {isOpen && (
                <div className="border p-2" style={{position: "absolute" ,zIndex:999, backgroundColor:layoutModeType == "light" ? "white" : "#495057", width:'95%'}}>
                   <SimpleBar style={{maxHeight: 200}}>
                        { options && options.length ? options?.map((option: any) => {
                        return <TreeView
                                    key={option.value}
                                    option={option}
                                    handleExpand={handleExpand}
                                    handleSelect={handleSelect}
                                    expanded={expanded}
                                    selectedOption={selectedOption}
                                    setIsOpen={setIsOpen}
                            />
                        }) : 
                            <TreeView
                                key={""}
                                option={!loading ? {label:"No options...", value:""}: {label:"Loading...", value:""}}
                                handleExpand={""}
                                handleSelect={""}
                                expanded={""}
                                selectedOption={""}
                                setIsOpen={""}
                            />
                        }
                    </SimpleBar>
                </div>
                )}
            </div>
           
        </div>
    )
}

export default CustomSelect