import BreadCrumb from "Components/Common/BreadCrumb";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    Button,
    Col,
    Container,
    Nav,
    NavItem,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Label,
    UncontrolledAlert,
    ListGroup,
    ListGroupItem,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { tenantGetRequest, tenantUpdateRequest, userUpdateRequest } from "store/user/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { getGoogleCalendarData } from "store/calendarSync/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store"; 
import { toast } from "react-toastify";
import ConfirmSyncModal from "../ConfirmSyncModal";
import SyncOptionModal from "../SyncOptionModal";
import { userGetRequest } from "store/auth/action";
import { permissionsGetRequest } from "store/role/action";
import Upload from "./Upload/Upload";
import { useParams } from "react-router";
import UserAvatar from "Components/Common/UserAvtar/UserAvatar"; 
import config from "../../../config"
import { isJson } from "utils";

const Profile = ({ t, avtarImage2 }: any) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const user: any = useSelector((state: ApplicationState) => state.auth.userProfile);
    const tenatDetails: any = useSelector((state: ApplicationState) => state.user?.tenatDetails);
    const googleConnect = useSelector((state: ApplicationState) => state.calendarSync?.data);
    const googleResponse = useSelector((state: ApplicationState) => state.calendarSync.data);
    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState(false);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState<any>(null);
    const [modal, setModal] = useState(false);
    const [modalPicture, setModalPicture] = useState(false)
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const [userData, setUserData] = useState<any>(user);
    const [picture, setPicture] = useState<string>("")
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [pictureLoading, setPictureLoading] = useState<boolean>(false);
    const [data, setData] = useState(0)
    const [fileInfo, setFileInfo] = useState<any>({});
    const [filterObject, setFilterObject] = useState<any>({});
    const [dataPreviewModal, setDataPreviewModal] = useState<boolean>(false);
    const [uploadModel, setUploadModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    useEffect(() => {
        if (user) {
            dispatch(userGetRequest());
            dispatch(permissionsGetRequest());
        }
    }, [user.profilePic]);

    useEffect(() => {
        dispatch(getGoogleCalendarData())
    }, [])

    useEffect(() => {
        setUserData(user);
        dispatch(tenantGetRequest(user?.tenant?.id))
    }, [user]);
    
    
    const handleUpdateInfo = () => {
        const attributes = [];

        if (userData.company_name !== user.company_name) {
            attributes.push({
                key: "company_name",
                value: userData.company_name
            });
        }
        if (userData.no_of_employees !== user.no_of_employees) {
            attributes.push({
                key: "no_of_employees",
                value: userData.no_of_employees
            });
        }
        if (userData.website !== user.website) {
            attributes.push({
                key: "website",
                value: userData.website
            });
        }
        attributes.push({
            key: "logo",
            value: 'file_upload_2b90fe01-8ecb-4a33-b62f-6ff81c8bc54b_CV.jpg'
        });

        // Only dispatch if there are attributes to update
        if (attributes.length > 0) {
            const data = {
                attributes: attributes
            };
            const handleSuccess = (body: any): void => {
                // const message = () => toast(`User information updated successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                // message();
                dispatch(tenantGetRequest())
            };
            const handleError = (body: any): void => {
                const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                message();
            };
            dispatch(userUpdateRequest(id, data, handleSuccess, handleError));
        }
    };

    const handleDeleteProfilePic = () => {
        let formValues: any = {logo:""}
        const data = {
            "attributes": [
              {
                "key": "settings",
                "value": "{logo:''}"
              }
            ]
          }
        const handleSuccess = (body: any): void => {
            setDeleteModal(false)
            // toast(`Profile image removed successfully.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(tenantGetRequest(id))
        };
        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        };
        dispatch(tenantUpdateRequest(data, handleSuccess, handleError));
    };

   
    
    const fileToggle = useCallback(() => {
        if (uploadModel) {
            setUploadModal(false);
        } else {
            setUploadModal(true);
        }
    }, [uploadModel]);

    const handleFileClicks = () => {
        setUploadModal(!uploadModel);
        fileToggle();
    };


    const handleClick = (event: any) => {
        setTarget(event.target);
        setShow(!show);
        setModalPicture(!modalPicture);
    };

    useEffect(() => {
        let settings= tenatDetails?.settings && isJson(tenatDetails?.settings) ? JSON.parse(tenatDetails?.settings) : {}
        setUserData({company_name: tenatDetails?.companyName, no_of_employees:  tenatDetails?.noOfEmployees, website:  tenatDetails?.website, email: tenatDetails?.email, logo: settings?.logo})
    }, [tenatDetails])

    return (
        <React.Fragment>
            <Modal fade={true} isOpen={deleteModal} toggle={() => setDeleteModal(false)} centered={true}>
                <ModalBody className="py-3 px-5">
                    <div className="mt-2 text-center">
                        <i className="ri-delete-bin-line display-5 text-danger"></i>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Are you sure you want to remove company logo ?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={() => setDeleteModal(false)}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={() => handleDeleteProfilePic()}
                        >
                            Yes, Delete It!
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <ConfirmSyncModal
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
            // props={props}
            />
            <SyncOptionModal
                modal={modal}
                setModal={setModal}
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
            // props={props}
            />
            <Modal backdrop="static" id="createFileModal" isOpen={uploadModel} toggle={() => { fileToggle(); setselectedFiles([]); }} modalClassName="zoomIn" centered tabIndex={1}>
                <ModalHeader toggle={() => { fileToggle(); setselectedFiles([]); }} className="p-3 bg-primary-subtle">{"Upload File"}</ModalHeader>
                <ModalBody>
                    <Upload  onCloseModal={() => setUploadModal(false)} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles}  fileInfo={fileInfo} setFileInfo={setFileInfo} />
                </ModalBody>
            </Modal>

            <div className="hstack mt-0 mb-4">
                <Row className="p-2 w-100 hstack gap-3 justify-items-center">
                    {/* <Col lg={12} className="vstack justify-content-between"> */}
                    <div className="vstack gap -1 justify-content-center">
                        <h4 className="">Profile</h4>
                        <p>This applies across any Zilter account they have.</p>
                    </div>
                    <div className="vstack gap-3 align-items-left">
                        <Label className="fw-bold fs-14">Company Logo</Label>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex position-relative gap-3">
                                <div className="vstack">
                                    <div className="profile-user position-relative d-inline-block mx-auto">
                                        {userData.logo ? (
                                            <img
                                                src={userData?.logo}
                                                className="rounded-circle avatar-lg img-thumbnail user-profile-image"
                                                alt="company-logo"
                                                onClick={handleClick}
                                                ref={setTarget}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        ) :
                                            <UserAvatar className="rounded-circle avatar-lg img-thumbnail user-profile-image" img={userData?.logo} firstName={userData?.company_name} />
                                        }
                                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                            <Label
                                                htmlFor="profile-img-file-input"
                                                className="profile-photo-edit avatar-xxs"
                                            >
                                                <span className="avatar-title rounded-circle bg-light text-body">
                                                    <i onClick={handleFileClicks} className="ri-camera-fill"></i>
                                                </span>
                                            </Label>
                                        </div>
                                    </div>
                                    <p className="fs-12 text-center mt-1">166X132(WxH)</p>
                                </div>
                                <div className="mt-3">
                                    <Label className="fs-16">{userData?.company_name}</Label>
                                    <div className="vstack gap-1 align-self-center flex-grow-1">
                                        <h6>{user?.tenant?.email}</h6>
                                    </div>
                                </div>
                            </div>
                            {userData.logo !== "" && userData.logo !== null ? (
                                <div>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        className="btn-danger"
                                        onClick={() => setDeleteModal(true)}
                                    >
                                        <i className="ri-delete-bin-2-line label-icon align-middle fs-16 me-2"></i>
                                        Remove Logo
                                    </Button>
                                </div>
                            ) : null}


                        </div>
                        <div className="d-flex flex-wrap justify-content-between gap-2">
                            <div className="w-50">
                                <FormBuilder
                                    label={"Company Name"}
                                    type={"textfield"}
                                    value={userData?.company_name}
                                    defaultValue={userData?.company_name}
                                    name={"company_name"}
                                    placeholder={`Enter Company Name`}
                                    className="flex-grow-1 me-3 mb-3"
                                    onChange={(value: any) => {
                                        setUserData({ ...userData, company_name: value })
                                    }}
                                    Editable={true}
                                />
                            </div>
                            <div className="w-50">
                                <FormBuilder
                                    label={"Number of employees"}
                                    type={"textfield"}
                                    value={userData?.no_of_employees}
                                    defaultValue={userData?.no_of_employees}
                                    name={"last_name"}
                                    placeholder={`Enter number of employees`}
                                    className="flex-grow-1 me-3 mb-3"
                                    onChange={(value: any) => {
                                        setUserData({ ...userData, no_of_employees: value })
                                    }}
                                    Editable={true}
                                />
                            </div>
                            <div className="w-50">
                                <FormBuilder
                                    label={"Website"}
                                    type={"textfield"}
                                    defaultValue={userData?.website}
                                    value={userData?.website}
                                    name={"phone_number"}
                                    placeholder={`Enter website`}
                                    className="flex-grow-1 me-3 mb-3"
                                    onChange={(value: any) => {
                                        setUserData({ ...userData, website: value })
                                    }}
                                    Editable={true}
                                />
                                <Button className='align-self-end' color="primary" onClick={handleUpdateInfo}>Update Info</Button>
                            </div>
                        </div>

                    </div>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Profile);
