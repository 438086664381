import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, ButtonGroup, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Table, UncontrolledDropdown } from 'reactstrap';
import { propertyGetRequest, propertyUpdateRequest } from 'store/properties/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DNDRow } from './Common/DNDRow';
import CreateStatusDrawer from './Common/CreateStatusDrawer';
import StatusUpdateDrawer from './Common/StatusUpdateDrawer';
import Select from "react-select";
import DeleteStatusModal from './Common/DeleteStatusModal';
import CreateProcessFlowDrawer from './Common/CreateProcessFlowDrawer';
import DeleteProcessFlowModal from './Common/DeleteProcessFlowModal';
import ProcessFlowUpdateDrawer from './Common/ProcessFlowUpdateDrawer';
import Workflow from './Common/Workflow/Workflow';

const PROPERTY_ID = '04f3764a-a37f-4634-970d-27e845bfca60';
let TIMER: any = null;

// Utility function to check if a string is a valid JSON and parse it
const parseJson = (str: string) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return null;
    }
};

const checkProcessFlowExists = (parsedData: any) => {
    // Ensure process_flows exists
    if (!parsedData.process_flows) {
        parsedData.process_flows = [];
    }
    return parsedData
}

const checkConfigurationKeysExists = (parsedData: any) => {
    // Ensure configuration exists
    if (!parsedData.configuration) {
        parsedData.configuration = {};
    }

    // Ensure automation exists inside configuration
    if (!parsedData.configuration.statuses) {
        parsedData.configuration.statuses = [];
    }

    // Ensure automation exists inside configuration
    if (!parsedData.configuration.automation) {
        parsedData.configuration.automation = {};
    }

    // Ensure created exists inside automation
    if (!parsedData.configuration.automation.created) {
        parsedData.configuration.automation.created = {};
    }

    // Ensure default exists inside created
    if (!parsedData.configuration.automation.created.default) {
        parsedData.configuration.automation.created.default = {};
    }

    // Ensure workflow exists inside created
    if (!parsedData.configuration.workflow) {
        parsedData.configuration.workflow = {};
    }

    // Ensure workflow flow exists inside created
    if (!parsedData.configuration.workflow.flow) {
        parsedData.configuration.workflow.flow = {};
    }

    // Ensure workflow flow nodes exists inside created
    if (!parsedData.configuration.workflow.flow.nodes) {
        parsedData.configuration.workflow.flow.nodes = [];
    }

    // Ensure workflow flow edges exists inside created
    if (!parsedData.configuration.workflow.flow.edges) {
        parsedData.configuration.workflow.flow.edges = [];
    }
    return parsedData
}

const Application = ({ model, ...props }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const property = useSelector((state: ApplicationState) => state.properties.property);

    const [lifeCycleData, setLifeCycleData]: any = useState(null);
    const [automationEnabled, setAutomationEnabled] = useState(false);
    const [createdStatusEnabled, setCreatedStatusEnabled] = useState(false);
    const [selectedCreatedStatus, setSelectedCreatedStatus] = useState<any>(null);
    const [isCreatingStatus, setIsCreatingStatus] = useState(false);
    const [isCreateProcessFlow, setIsCreateProcessFlow] = useState(false);
    const [isUpdateProcessFlow, setIsUpdateProcessFlow] = useState(false);
    const [statuses, setStatuses] = useState<any[]>([]);
    const [processFlows, setProcessFlows] = useState<any[]>([]);
    const [selectedProcessFlow, setSelectedProcessFlow] = useState<any>(null);
    const [selectedStatusToUpdate, setSelectedStatusToUpdate] = useState<any>(null);
    const [selectedStatusToDelete, setSelectedStatusToDelete] = useState<any>(null);
    const [isDeleteProcessFlow, setIsDeleteProcessFlow] = useState<boolean>(false);

    // Fetch the property data when the component mounts
    useEffect(() => {
        dispatch(propertyGetRequest(PROPERTY_ID));
    }, [dispatch]);

    // Parse and set lifecycle and statuses data when property data is available
    useEffect(() => {
        if (property?.valuesJson) {
            const parsedData = parseJson(property.valuesJson);
            setLifeCycleData(checkProcessFlowExists(parsedData))
            const processFlows = parsedData?.process_flows || [];
            setProcessFlows(processFlows);
            if(processFlows?.length && selectedProcessFlow) {
                const newProcessFlow = processFlows.find((processFlow: any) => processFlow.value === selectedProcessFlow.value)
                if(newProcessFlow) {
                    onSelectProcessFlow(newProcessFlow)
                }
            }
        }
    }, [property]);

    const updateProperty = (updatedLifecycleData: any, timeOut=0) => {
        if(TIMER) {
            clearTimeout(TIMER)
        }
        TIMER = setTimeout(() => {
            const { createdAt, updatedAt, deletedAt, createdBy, id, ...remainingProperties } = property;
            const updatedPropertyData = {
                ...remainingProperties,
                valuesJson: JSON.stringify(updatedLifecycleData),
                model: "applications"
            };
            const handleSuccess = () => {
                dispatch(propertyGetRequest(PROPERTY_ID));
            };
            const handleFailure = () => { /* Handle failure if necessary */ };
            dispatch(propertyUpdateRequest(PROPERTY_ID, updatedPropertyData, handleSuccess, handleFailure, true));
        }, timeOut)
    }

    const onUpdateProcessFlow = (newProcessFlow: any, timeOut=0) => {
        const updatedProcessFlows = processFlows.map((processFlow: any) => {
            if (processFlow.value === selectedProcessFlow.value) {
                return newProcessFlow
            } else {
                return processFlow
            }
        })
        if(updatedProcessFlows?.length) {
            lifeCycleData.process_flows = updatedProcessFlows
        } else {
            lifeCycleData.process_flows.push(newProcessFlow)
        }
        updateProperty(lifeCycleData, timeOut)
        setProcessFlows(updatedProcessFlows);
    }

    const onAddProcessFlow = (processFlow: any) => {
        setProcessFlows((prevStatuses) => {
            const updatedProcessFlows = [...prevStatuses, processFlow]
            lifeCycleData.process_flows = updatedProcessFlows
            updateProperty(lifeCycleData)
            onSelectProcessFlow(processFlow)
            return updatedProcessFlows
        });
    }

    const onSelectProcessFlow = (processFlow: any) => {
        const selectedProcessFlow = checkConfigurationKeysExists(processFlow)
        setStatuses(selectedProcessFlow?.configuration?.statuses || []);
        setAutomationEnabled(selectedProcessFlow?.configuration?.automation?.status || false);
        setCreatedStatusEnabled(selectedProcessFlow?.configuration?.automation?.created?.status || false);
        setSelectedCreatedStatus(selectedProcessFlow?.configuration?.automation?.created?.default?.status || '');
        setSelectedProcessFlow(selectedProcessFlow)
    }

    const onAutomationUpdate = () => {
        setAutomationEnabled(prev => {
            selectedProcessFlow.configuration.automation.status = !prev;
            onUpdateProcessFlow(selectedProcessFlow)
            return !prev
        })
    }

    const onCreateStatusUpdate = () => {
        setCreatedStatusEnabled(prev => {
            selectedProcessFlow.configuration.automation.created.status = !prev;
            onUpdateProcessFlow(selectedProcessFlow)
            return !prev
        })
    }

    const onSelectedCreatedStatus = (status: any) => {
        setSelectedCreatedStatus(status)
        selectedProcessFlow.configuration.automation.created.default.status = status;
        onUpdateProcessFlow(selectedProcessFlow)
    }

    // Function to handle moving statuses in the list
    const moveStatus = useCallback((dragIndex: number, hoverIndex: number) => {
        setStatuses((prevStatuses) => {
            const updatedStatuses = [...prevStatuses];
            const [draggedStatus] = updatedStatuses.splice(dragIndex, 1);
            updatedStatuses.splice(hoverIndex, 0, draggedStatus);
            selectedProcessFlow.configuration.statuses = updatedStatuses
            onUpdateProcessFlow(selectedProcessFlow, 1200)
            return updatedStatuses;
        });
    }, [selectedProcessFlow]);

    const onAddStatus = (status: any) => {
        setStatuses((prevStatuses) => {
            const updatedStatuses = [...prevStatuses, status]
            selectedProcessFlow.configuration.statuses = updatedStatuses
            onUpdateProcessFlow(selectedProcessFlow)
            return updatedStatuses
        });
    }

    const onUpdateStatus = (oldStatus: any, newStatus: any) => {
        const updatedStatuses = statuses.map((status: any) => {
            if (status.value === oldStatus.value) {
                return newStatus
            } else {
                return status
            }
        })
        selectedProcessFlow.configuration.statuses = updatedStatuses
        onUpdateProcessFlow(selectedProcessFlow)
        setStatuses(updatedStatuses);
    }

    const onDeleteStatus = () => {
        const updatedStatuses = statuses.filter((status) => status.value !== selectedStatusToDelete.value);
        selectedProcessFlow.configuration.statuses = updatedStatuses
        onUpdateProcessFlow(selectedProcessFlow)
        setStatuses(updatedStatuses);
        setSelectedStatusToDelete(null)
    }

    const onDeleteProcessFlow = () => {
        const updatedProcessFlows = processFlows.filter((processFlow) => processFlow.value !== selectedProcessFlow.value);
        lifeCycleData.process_flows = updatedProcessFlows
        updateProperty(lifeCycleData)
        setSelectedProcessFlow(null)
        setIsDeleteProcessFlow(false)
    }
    return (
        <div className='dts-lifecycle-application'>
            <Card>
                <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                    <div className='d-flex align-items-end justify-content-between'>
                        <div className="d-flex gap-2 flex-column" style={{minWidth: '300px'}}>
                            <div className='d-flex gap-2'>
                                <Label className="fw-bold">
                                    {props.t('properties.process_flow.application.select_process_flow')}
                                </Label>
                                <span className="text-muted small mark">
                                    <abbr title={props.t('properties.process_flow.application.select_process_flow_helper')}>
                                        {props.t('properties.process_flow.application.select_process_flow_helper')}
                                    </abbr>
                                </span>
                            </div>
                            <div className='d-flex gap-2 w-100 align-items-center'>
                                <Select
                                    className="fs-16 fw-medium w-100"
                                    defaultOptions
                                    placeholder={props.t('properties.process_flow.application.select_process_flow_placeholder')}
                                    value={selectedProcessFlow}
                                    onChange={(option : any) => onSelectProcessFlow(option)}
                                    options={processFlows}
                                ></Select>
                                {selectedProcessFlow ? <Button
                                    color="primary"
                                    className="btn-icon"
                                    onClick={() => setIsUpdateProcessFlow(true)}
                                    outline>
                                        <i className="ri-edit-2-fill" />
                                    </Button> : null}
                            </div>
                        </div>
                        <Button
                            onClick={() => setIsCreateProcessFlow(true)}
                            color="primary"
                            className="btn-label btn-primary">
                            <i className="ri-play-list-add-line label-icon align-middle fs-16 me-2"></i>
                            {props.t("properties.process_flow.application.add_process_flow")}
                        </Button>
                    </div>
                </CardBody>
            </Card>
            {selectedProcessFlow ?
                <div>
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                            <h5>
                                {props.t('properties.process_flow.application.configuration.automation.automation_configuration')}
                            </h5>
                            <p className="pb-3 border-bottom border-light-subtle">
                                {props.t('properties.process_flow.application.configuration.automation.heading_line')}
                            </p>
                            <div className="d-flex flex-column gap-4 mb-4">
                                {/* Automation Toggle */}
                                <div className="form-check form-switch form-switch-lg" dir="ltr">
                                    <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={automationEnabled}
                                        onChange={() => onAutomationUpdate()}
                                    />
                                    <div className="d-flex gap-2">
                                        <Label className="form-check-label fw-bold fs-5">
                                            {props.t('properties.process_flow.application.configuration.automation.automation')}
                                        </Label>
                                        <span className="text-muted small mark">
                                            <abbr title={automationEnabled ? "Enabled" : "Disabled"}>
                                                {props.t('properties.process_flow.application.configuration.automation.automation_helper')}
                                            </abbr>
                                        </span>
                                    </div>
                                </div>

                                {/* Automation Configuration */}
                                <div className={`d-flex w-100 flex-column gap-4 ps-4 ${automationEnabled ? '' : 'opacity-75'}`}>
                                    {/* Created Status Configuration */}
                                    <div>
                                        <div className="form-check form-switch form-switch-md" dir="ltr">
                                            <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={createdStatusEnabled}
                                                onChange={() => onCreateStatusUpdate()}
                                                disabled={!automationEnabled}
                                            />
                                            <Label className="form-check-label fw-bold">
                                                {props.t('properties.process_flow.application.configuration.automation.toggle_create_label')}
                                            </Label>
                                        </div>
                                        <p className="text-muted ps-5">
                                            {props.t('properties.process_flow.application.configuration.automation.toggle_create_description')}
                                        </p>
                                        <div className="d-flex flex-column ps-5">
                                            <Label className={automationEnabled ? "fw-bold" : "fw-bold text-muted"}>
                                                {props.t('properties.process_flow.application.configuration.automation.dropdown_label')}
                                            </Label>
                                            <ButtonGroup>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        tag="button"
                                                        className="btn btn-light text-left d-flex align-items-center justify-content-between"
                                                        style={{ width: '200px' }}
                                                        disabled={!automationEnabled || !createdStatusEnabled}
                                                    >
                                                        {selectedCreatedStatus?.label} <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdownmenu-primary">
                                                        {statuses.map((status, index) => (
                                                            <DropdownItem
                                                                key={index}
                                                                onClick={() => onSelectedCreatedStatus(status)}
                                                            >
                                                                {status?.label}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                            <h5>
                                {props.t('properties.process_flow.application.configuration.statuses_table.status_configuration')}
                            </h5>
                            <p>{props.t('properties.process_flow.application.configuration.statuses_table.heading_line')}</p>
                            {/* Status Table */}
                            <div className="table-responsive mt-3">
                                <Table className="align-middle table-nowrap mb-0 table-hover">
                                    <thead className='table-primary'>
                                        <tr>
                                            <th className='border-bottom text-center table-soft-primary text-primary align-middle' scope="col">{props.t('properties.process_flow.application.configuration.statuses_table.table.status_name')}</th>
                                            <th className='border-bottom text-center table-soft-primary text-primary align-middle' scope="col">{props.t('properties.process_flow.application.configuration.statuses_table.table.roles')}</th>
                                            <th className='border-bottom text-center table-soft-primary text-primary align-middle' scope="col">{props.t('properties.process_flow.application.configuration.statuses_table.table.snapshot_view')}</th>
                                        </tr>
                                    </thead>
                                    <tbody id="applicationStatusList">
                                        <DndProvider backend={HTML5Backend}>
                                            {
                                                statuses.map((value: any, valueIndex: number) => {
                                                    return (
                                                        <DNDRow
                                                            key={value.value}
                                                            index={valueIndex}
                                                            id={value.value}
                                                            value={value}
                                                            moveRow={moveStatus}
                                                            onUpdateClick={() => setSelectedStatusToUpdate(value)}
                                                            onDeleteClick={() => setSelectedStatusToDelete(value)}
                                                        />
                                                    )
                                                })
                                            }
                                        </DndProvider>
                                    </tbody>
                                    <tfoot className="table-light">
                                        <tr>
                                            <td>
                                                <Button
                                                    onClick={() => setIsCreatingStatus(true)}
                                                    color="primary"
                                                    className="btn-label btn-soft-primary">
                                                    <i className="ri-play-list-add-line label-icon align-middle fs-16 me-2"></i>
                                                    {props.t("properties.process_flow.application.configuration.statuses_table.add_status")}
                                                </Button>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                    <Workflow statuses={statuses} onUpdateProcessFlow={onUpdateProcessFlow} selectedProcessFlow={selectedProcessFlow} />
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                            <div className='d-flex align-items-center justify-content-start gap-2'>
                                <Button
                                    onClick={() => setIsDeleteProcessFlow(true)}
                                    color="danger"
                                    className="btn-label btn-danger">
                                    <i className="ri-delete-bin-6-line label-icon align-middle fs-16 me-2"></i>
                                    {props.t("properties.process_flow.application.configuration.delete.delete_process_flow")}
                                </Button>
                                <span className="text-muted small mark">
                                    <abbr title={props.t("properties.process_flow.application.configuration.delete.delete_process_flow_helper")}>
                                        {props.t("properties.process_flow.application.configuration.delete.delete_process_flow_helper")}
                                    </abbr>
                                </span>
                            </div>
                        </CardBody>
                    </Card>
                    {/* Status Creation Drawer */}
                    {
                        isCreatingStatus ?
                            <CreateStatusDrawer
                                model="applications"
                                statuses={statuses}
                                isOpen={isCreatingStatus}
                                toggle={setIsCreatingStatus}
                                onSubmit={onAddStatus}
                            /> : null
                    }
                    {/* Status Update Drawer */}
                    {
                        selectedStatusToUpdate ?
                            <StatusUpdateDrawer
                                model="applications"
                                isOpen={!!selectedStatusToUpdate}
                                statuses={statuses}
                                status={selectedStatusToUpdate}
                                toggle={setSelectedStatusToUpdate}
                                onSubmit={onUpdateStatus}
                            /> : null
                    }
                    {/* Status Delete Model */}
                    {
                        selectedStatusToDelete ?
                            <DeleteStatusModal
                                isOpen={!!selectedStatusToDelete}
                                toggle={() => setSelectedStatusToDelete(null)}
                                status={selectedStatusToDelete}
                                onDelete={() => onDeleteStatus()}
                            /> : null
                    }
                </div> : 
                <Card>
                    <CardBody className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                        <div className='d-flex align-items-center justify-content-center' style={{height: '300px'}}>
                            <h4 className='text-muted'>{props.t('properties.process_flow.application.not_selected')}</h4>
                        </div>
                    </CardBody>
                </Card>
            }
            {/* Status Creation ProcessFlow Drawer */}
            {
                isCreateProcessFlow ?
                    <CreateProcessFlowDrawer
                        processFlows={processFlows}
                        isOpen={isCreateProcessFlow}
                        toggle={setIsCreateProcessFlow}
                        onSubmit={onAddProcessFlow}
                    /> : null
            }
            {/* ProcessFlow Update Drawer */}
            {
                isUpdateProcessFlow ?
                    <ProcessFlowUpdateDrawer
                        isOpen={isUpdateProcessFlow}
                        processFlows={processFlows}
                        processFlow={selectedProcessFlow}
                        toggle={setIsUpdateProcessFlow}
                        onSubmit={onUpdateProcessFlow}
                    /> : null
            }
            {/* ProcessFlow Delete Model */}
            {
                isDeleteProcessFlow ?
                    <DeleteProcessFlowModal
                        isOpen={!!isDeleteProcessFlow}
                        toggle={() => setIsDeleteProcessFlow(false)}
                        processFlow={selectedProcessFlow}
                        onDelete={() => onDeleteProcessFlow()}
                    /> : null
            }
        </div>
    );
};

export default withTranslation()(Application);
