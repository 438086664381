export interface CallsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Calls {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export interface Sentiment {
  sentiment: string
} 

export enum CallsActionTypes {
  GET_CALLMETADATA_REQUEST = "@@call/GET_CALLMETADATA_REQUEST",
  GET_CALLMETADATA_SUCCESS = "@@call/GET_CALLMETADATA_SUCCESS",
  GET_CALLMETADATA_ERROR = "@@call/GET_CALLMETADATA_ERROR",
  GET_CALL_REQUEST = "@@call/GET_CALL_REQUEST",
  GET_CALL_SUCCESS = "@@call/GET_CALL_SUCCESS",
  GET_CALL_ERROR = "@@call/GET_CALL_ERROR",
  GET_CALLS_REQUEST = "@@call/GET_CALLS_REQUEST",
  GET_CALLS_SUCCESS = "@@call/GET_CALLS_SUCCESS",
  GET_CALLS_ERROR = "@@call/GET_CALLS_ERROR",
  PUT_CALL_REQUEST = "@@call/PUT_CALL_REQUEST",
  PUT_CALL_SUCCESS = "@@call/PUT_CALL_SUCCESS",
  PUT_CALL_ERROR = "@@call/PUT_CALL_ERROR",
  DELETE_CALL_REQUEST = "@@call/DELETE_CALL_REQUEST",
  DELETE_CALL_SUCCESS = "@@call/DELETE_CALL_SUCCESS",
  DELETE_CALL_ERROR = "@@call/DELETE_CALL_ERROR",
  POST_CALL_REQUEST = "@@call/POST_CALL_REQUEST",
  POST_CALL_SUCCESS = "@@call/POST_CALL_SUCCESS",
  POST_CALL_ERROR = "@@call/POST_CALL_ERROR",  
  CALLS_DOWNLOAD_DOCUMENT_REQUEST = "@@call/CALLS_DOWNLOAD_DOCUMENT_REQUEST",
  CALLS_DOWNLOAD_DOCUMENT_SUCCESS = "@@call/CALLS_DOWNLOAD_DOCUMENT_SUCCESS",
  CALLS_DOWNLOAD_DOCUMENT_ERROR = "@@call/CALLS_DOWNLOAD_DOCUMENT_ERROR",
  CALLS_SENTIMENT_REQUEST = "@@call/CALLS_SENTIMENT_REQUEST",
  CALLS_SENTIMENT_SUCCESS = "@@call/CALLS_SENTIMENT_SUCCESS",
  CALLS_SENTIMENT_ERROR = "@@call/CALLS_SENTIMENT_ERROR",
  PUSH_NOTIFICATION_REQUEST = "@@call/PUSH_NOTIFICATION_REQUEST",
  PUSH_NOTIFICATION_SUCCESS = "@@call/PUSH_NOTIFICATION_SUCCESS",
  PUSH_NOTIFICATION_ERROR = "@@call/PUSH_NOTIFICATION_ERROR"
}

export interface CallsState {
  readonly list: Calls[] | null;
  readonly details: Calls | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: CallsMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;  
  readonly sentiment: Sentiment | null;
  readonly callloading: boolean
}
