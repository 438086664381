import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import RenderComponent from './RenderComponent';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { isJson } from 'utils';
import { whatsappCreateRequest, whatsappDelete, whatsappGetRequest, whatsappListGetRequest, whatsappsMetadataGetRequest } from 'store/whatsapp/action';

const Whatsapp = (props: any) => {
    const { id } = useParams()
    const { details, studentId, applicationId } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const metaData = useSelector((state: ApplicationState) => state.tasks.metaDataFields);   
    const [ create, setCreate ] = useState<boolean>(false);  
    const [ parentField, setParentField ] = useState({})
    const [selectedTask, setSelectedTask] = useState<any>(null);
    const [modal_positionTop, setmodal_positionTop] = useState<boolean>(false);
    
    useEffect(() => {
        metaData && metaData.length && metaData.map((item: any) =>{
            let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
            if(field.key === "student") setParentField({...field, ...item})
        })
    }, [metaData])

    const { viewId, isCreateModal, setCreateOpen } = props;

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.tasks;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    useEffect(() => {
        let filterObject = {PageSize: 500}
        dispatch(whatsappsMetadataGetRequest("whatsapp", filterObject))
    }, [])

    const handleConfirmComplete = (item: any, checked: boolean) => {
        setSelectedTask(item);
        // If checked, open the top modal for confirmation
        if (checked) {
            setmodal_positionTop(true);
        } else {
            setmodal_positionTop(true);
        }
    };

    const checkbox = (row: any) => {
        if(create) return <span className="align-content-center" >
            <Input
                className="form-check-input me-1 mt-1 ms-3"
                type="checkbox"
                value={row.id}
                id={"todo" + row.id}
                checked={row.status === "COMPLETED"} // Dynamically set checked attribute
                onChange={(e) => {
                    handleConfirmComplete(row, e.target.checked);
                }}
            />
            </span>
        else return false
    }

    return (
        <div>   
            <RenderComponent 
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                getListAction={whatsappListGetRequest}
                deleteAction={whatsappDelete}
                detailsGetAction={whatsappGetRequest}
                metaDatagetRequest={whatsappsMetadataGetRequest} 
                createAction={whatsappCreateRequest}           
                model={"whatsapp"}
                reducerState={"whatsapp"}
                parentId={id}
                parent={"student"}
                parentField={parentField}
                checkbox={checkbox}
                details={details}
                selectedTask={selectedTask}
                modal_positionTop={modal_positionTop}
                setmodal_positionTop={setmodal_positionTop}
                applicationId={applicationId}
                studentId={studentId}
            />
        </div>
    );
};
export default withTranslation()(Whatsapp);


