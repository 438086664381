import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import logoLight from "../../../assets/images/dtsimg/logo/logo-placeholder.jpg";
import ImageComponent from './ImageComponent';

const Logo = (props:any) => {
    const { tenant, height } = props;
    return (
        <div>
             <div className="navbar-brand-box horizontal-logo mb-2">
					<Link to="" className="logo logo-dark">
						<span className="logo-sm">
							{/* <img src={tenant?.logo || logoLight} alt="" height={height || "40"} /> */}
                            <ImageComponent src={tenant?.logo || logoLight} />
						</span>
						<span className="logo-lg">
                            <ImageComponent src={tenant?.logo || logoLight} />
							{/* <img src={tenant?.logo || logoLight} alt="" height={height || "40"} /> */}
						</span>
					</Link>
					<Link to="" className="logo logo-light">
						<span className="logo-sm">
							{/* <img src={tenant?.logo || logoLight} alt=""  height={height || "40"} /> */}
                            <ImageComponent src={tenant?.logo || logoLight} />
						</span>
						<span className="logo-lg">
							{/* <img src={tenant?.logo || logoLight} alt=""  height={height || "40"} /> */}
                            <ImageComponent src={tenant?.logo || logoLight} />
						</span>
					</Link>
				</div>  
                {
                    isMobile ?
                    <Row>
                        <Col className='hstack justify-content-center'>
                            <div className="horizontal-logo mb-2">
                                <Link to="" className="logo logo-dark">
                                    <span className="logo-sm">
                                        {/* <img src={tenant?.logo || logoLight} alt="" height="40" /> */}
                                        <ImageComponent src={tenant?.logo || logoLight} />
                                    </span>
                                    <span className="logo-lg">
                                        {/* <img src={tenant?.logo || logoLight} alt="" height="40" /> */}
                                        <ImageComponent src={tenant?.logo || logoLight} />
                                    </span>
                                </Link>
                                <Link to="" className="logo logo-light">
                                    <span className="logo-sm">
                                        {/* <img src={tenant?.logo || logoLight} alt="" height="40" /> */}
                                        <ImageComponent src={tenant?.logo || logoLight} />
                                    </span>
                                    <span className="logo-lg">
                                        {/* <img src={tenant?.logo || logoLight} alt="" height="40" /> */}
                                        <ImageComponent src={tenant?.logo || logoLight} />
                                    </span>
                                </Link>
                            </div>  
                        </Col>
                    </Row>
                    : null
                }
        </div>
    );
};

export default Logo;