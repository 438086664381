import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { contractCreateRequest, contractDelete, contractGetRequest, contractListGetRequest, contractsMetadataGetRequest } from 'store/contracts/action';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import AddEntryModal from './AddEntryModal';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { isJson } from 'utils';

const Contracts = (props: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const metaData = useSelector((state: ApplicationState) => state.contracts.metaDataFields);   

    const [ isCreate, setCreateModal ] = useState<boolean>(false);     
    const [ create, setCreate ] = useState<boolean>(false);  
    const [ parentField, setParentField ] = useState({})
    
    useEffect(() => {
        metaData && metaData.length && metaData.map((item: any) =>{
            let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
            if(field.key === "channels") setParentField({...field, ...item})
        })
    }, [metaData])

    const { viewId, isCreateModal, setCreateOpen } = props;

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.contracts;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() === 'create') {
                            item.value === "" || item.value === "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const createBtn = () => {
        if (create) return <Button
            onClick={() => { setCreateModal(true)}}
            color="primary"
            size='sm'
            className="btn-label py-2">
            <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
            {props.t("company.create_entry")}
        </Button>
        else return <Button
            color="primary"
            size='sm'
            disabled
            className="btn-label py-2">
            <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
            {props.t("company.create_entry")}
        </Button>
    }

    useEffect(() => {
        let filterObject = {PageSize: 500}
        dispatch(contractsMetadataGetRequest(filterObject))
    }, [])

    return (
        <div>
            {isCreate && <AddEntryModal
                show={isCreate}
                onCloseClick={() => setCreateModal(false)}
                dataFields={metaData}
                props={props}
                parentField={parentField}
            />}
            <Listing 
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                getListAction={contractListGetRequest}
                deleteAction={contractDelete}
                detailsGetAction={contractGetRequest}
                metaDatagetRequest={contractsMetadataGetRequest} 
                createAction={contractCreateRequest}           
                model={"contracts"}
                reducerState={"contracts"}
                createBtn={createBtn}
                parentId={id}
                parent={"channels"}
                parentField={parentField}
            />
        </div>
    );
};
export default withTranslation()(Contracts);


