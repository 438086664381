import produce from 'immer';
import { Reducer } from "redux";
import { WhatsappActionTypes, WhatsappState } from "./types";


export const initialState: WhatsappState =
{
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    loading: false,
    dataLoading: false,
    metaLoading: false,
    sentiment: null,
    whatsapploading: false
}
const reducer: Reducer<WhatsappState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case WhatsappActionTypes.GET_WHATSAPPMETADATA_REQUEST: {
            return { ...state, metaLoading: true,  metaDataFields: [] };
        }
        case WhatsappActionTypes.GET_WHATSAPPMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case WhatsappActionTypes.GET_WHATSAPPMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload,  metaDataFields: [] };
        }
        case WhatsappActionTypes.GET_WHATSAPP_REQUEST: {
            return { ...state, loading: true };
        }
        case WhatsappActionTypes.GET_WHATSAPP_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case WhatsappActionTypes.GET_WHATSAPP_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case WhatsappActionTypes.POST_WHATSAPP_REQUEST: {
            return { ...state, loading: true };
        }
        case WhatsappActionTypes.POST_WHATSAPP_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case WhatsappActionTypes.POST_WHATSAPP_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case WhatsappActionTypes.GET_WHATSAPPS_REQUEST: {
            return { ...state, loading: true, list: [] };
        }
        case WhatsappActionTypes.GET_WHATSAPPS_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case WhatsappActionTypes.GET_WHATSAPPS_ERROR: {
            return { ...state, loading: false, errors: action.payload, list: []};
        } 
        case WhatsappActionTypes.PUT_WHATSAPP_REQUEST: {
            return { ...state, loading: true };
        }
        case WhatsappActionTypes.PUT_WHATSAPP_SUCCESS: {
            return {
                ...state,
                loading: false,
                whatsapplist: action.payload.content
            };
        }
        case WhatsappActionTypes.PUT_WHATSAPP_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case WhatsappActionTypes.DELETE_WHATSAPP_REQUEST: {
            return { ...state, loading: true };
        }
        case WhatsappActionTypes.DELETE_WHATSAPP_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case WhatsappActionTypes.DELETE_WHATSAPP_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case WhatsappActionTypes.WHATSAPP_DOWNLOAD_DOCUMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case WhatsappActionTypes.WHATSAPP_DOWNLOAD_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case WhatsappActionTypes.WHATSAPP_DOWNLOAD_DOCUMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 
        case WhatsappActionTypes.WHATSAPP_SENTIMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case WhatsappActionTypes.WHATSAPP_SENTIMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                sentiment: action.payload
            };
        }
        case WhatsappActionTypes.WHATSAPP_SENTIMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }          
        default: {
            return state;
        }
    }
};

export { reducer as whatsappReducer };
