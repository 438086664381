import { useState } from "react";
import Select from "react-select";
import classnames from "classnames";
import { Card, CardBody, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import NoEvents from "../../../../assets/images/noevents.svg"
const dissortbyname = [
    {
        options: [
            { label: "Alabama", value: "AL" },
            { label: "Madrid", value: "MA" },
            { label: "Toronto", value: "TO" },
            { label: "Londan", value: "LO" },
            { label: "Wyoming", value: "WY" }
        ],
    },
];

const MeetingsDisplay = () => {
    const [cardHeaderTab, setcardHeaderTab] = useState<any>("1");
    const [disable, setdisable] = useState<boolean>(false);
    const [dissortBy, setdissortBy] = useState<any>(null);
    const [isUp, setIsUp] = useState(false);


    const cardHeaderToggle = (tab: any) => {
        if (tab === "4") {
            setIsUp((prevIsUp) => !prevIsUp);
        }
        if (cardHeaderTab !== tab) {
            setcardHeaderTab(tab);
        }
    };
    return (
        <>
                <div className="d-flex justify-content-between w-100 gap-3">
                    <div>
                        <Select
                            value={dissortBy}
                            onChange={(sortBy: any) => {
                                setdissortBy(sortBy);
                            }}
                            options={dissortbyname}
                            className="js-example-disabled mb-0"
                            isDisabled={disable}
                        />
                    </div>
                    <div>
                        Displaying 0 – 0 of 0 Events
                    </div>
                </div>
                <div>
                    <Card>
                        <div className="card-header align-items-center d-flex">
                            <div className="d-flex justify-content-between">
                                <div className="flex-shrink-0 ms-2">
                                    <Nav tabs className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0">
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: cardHeaderTab === "1", })} onClick={() => { cardHeaderToggle("1"); }} >
                                                Upcoming
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: cardHeaderTab === "2", })} onClick={() => { cardHeaderToggle("2"); }} >
                                                Pending
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: cardHeaderTab === "3", })} onClick={() => { cardHeaderToggle("3"); }} >
                                                Past
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: cardHeaderTab === "4", })} onClick={() => { cardHeaderToggle("4"); }} >
                                                Date Range <span>
    <i className={isUp ? "ri-arrow-up-s-fill align-middle" : "ri-arrow-down-s-fill align-middle"}></i>
</span>

                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                <div>

                                </div>
                            </div>

                        </div>
                        <CardBody>
                            <TabContent activeTab={cardHeaderTab} className="text-muted">
                                <TabPane tabId="1" id="home2">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <img src={NoEvents} alt="No Events" />
                                    </div>
                                    <div>
                                    <p className="text-center"><Label className="fs-16">No events to display</Label></p>
                                    </div>
                                </TabPane>


                                <TabPane tabId="2" id="profile2">
                                    <p className="me-3 mb-0">
                                    </p>
                                </TabPane>

                                <TabPane tabId="3" id="messages2">
                                    <p className="ms-3 mb-0">
                                    </p>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </div>
        </>
    )
}
export default MeetingsDisplay;