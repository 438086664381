import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, UncontrolledButtonDropdown } from "reactstrap";
import avatar2 from "../../../../assets/images/users/avatar-2.jpg";
import { Link, useParams } from "react-router-dom";
import CreateEventType from "../Modals/CreateEventType";
import { useEffect, useState } from "react";
import AddEntryModal from "./AddEntryModal";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { eventTypeDelete, eventTypeListGetRequest, getGoogleCalendarData } from "store/calendarSync/action";
import moment from "moment";
import config from "config";

const { url: baseUrl } = config.api;

const MeetingEventType = (props: any) => {
    const { tenId, eventTypeId } = useParams();
    const { createAction, parent, parentId, studentId, applicationId } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const eventTypeList = useSelector((state: ApplicationState) => state.calendarSync.list);
    const userProfile: any = useSelector((state: ApplicationState) => state.auth.userProfile);
    const emailProvider: any = useSelector((state: ApplicationState) => state.calendarSync?.data);
    const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
    const [metaData, setMetaData] = useState([]);
    const [triggerApi, setTriggerApi]: any = useState(false);
    const [emailData, setEmailData] = useState<any>();
    const [dynamicUrl, setDynamicUrl] = useState<any>();


    useEffect(() => {
        dispatch(eventTypeListGetRequest())
        dispatch(getGoogleCalendarData())
    }, [])

    useEffect(() => {
        if (emailProvider) {
            setEmailData(emailProvider[0])
        }
    }, [emailProvider])

        const handleEventTypeDelete = (id: any) => {
            const handleSuccess = (body: any) => {
                dispatch(eventTypeListGetRequest())
                setTriggerApi && setTriggerApi((triggerApi: boolean) => !triggerApi)
            }
            const handleFailure = (body: any) => {
            }
            dispatch(eventTypeDelete(id, handleSuccess, handleFailure))
        }

        const generateUrl = (eventTypeId: any) => {
            if (userProfile) {
                const dynamicUrl = `${baseUrl}/${userProfile?.tenant?.id}/${eventTypeId}`; // Use 'baseUrl' here
                setDynamicUrl(dynamicUrl);
            } else {
                alert("Please provide both username and event name.");
            }
        };
    console.log("userProfile", userProfile)
    return (
        <>
            {isCreateModal && <CreateEventType
                show={isCreateModal}
                onCloseClick={() => setIsCreateModal(false)}
                emailData={emailData}
            />}
            {/* {isCreateModal && <AddEntryModal
                show={isCreateModal}
                onCloseClick={() => setIsCreateModal(false)}
                dataFields={metaData}
                setTriggerApi={setTriggerApi}
                props={props}
                createAction={createAction}
                parent={parent}
                parentId={parentId}
                userProfile={userProfile}
                studentId={studentId}
                applicationId={applicationId} picture
            />} */}
            <div>
                <div className="d-flex justify-content-between mt-0">
                    <div className="d-flex align-items-center">
                        <div className={`flex-shrink-0 align-self-center me-2 ms-0`}>
                            <div className="avatar-sm">
                                {emailData && emailData?.socialDataDTO && emailData?.socialDataDTO?.userProfile && emailData?.socialDataDTO?.userProfile?.picture ? (
                                    <img src={emailData?.socialDataDTO?.userProfile?.picture} className="rounded-circle img-fluid userprofile" alt="" />
                                ) : (
                                    <div className={"avatar-title rounded-circle bg-light userprofile"}>
                                        {emailData?.socialDataDTO?.userProfile?.name}
                                    </div>
                                )}
                            </div>
                            <span className="user-status"></span>
                        </div>
                        <div className="mt-2 flex-grow-1 overflow-hidden">
                            <p className="text-truncate mb-0 mt-2">{emailData?.socialDataDTO?.userProfile?.name}</p>
                            <p><small>{emailData?.socialDataDTO?.userProfile?.email}</small></p>
                        </div>
                        {/* {chat.badge &&
                                        <div className="flex-shrink-0" id={"unread-msg-user" + chat.id}>
                                          <span className="badge bg-dark-subtle text-body rounded p-1">{chat.badge}</span>
                                        </div>
                                      } */}
                    </div>
                    <div className="d-flex align-items-center">
                        <Button onClick={() => setIsCreateModal(true)} color="light" className="align-self-center rounded-pill"><i className="ri-add-fill"></i> New Event Type </Button>
                    </div>
                </div>
                <hr className="m-0 mb-2"></hr>
                <div>
                    <Row>

                        {eventTypeList ? eventTypeList.map((item: any, index: any) => {
                            const formattedText = item.valuesJson?.type.replace(/_/g, " ").replace(/\b\w/g, (char: string) => char?.toUpperCase());
                            const duration = moment.duration(item.valuesJson?.duration);
                            const hours = duration.hours();
                            return (
                                <Col xxl={4} xl={4} lg={6}>
                                    <Card className="card-light border-0 border-top border-5" key={index}>
                                        <CardBody>
                                            <div className="d-flex justify-content-between">
                                                <div className="form-check mb-2">
                                                    <Input className="form-check-input" type="checkbox" id="formCheck1" />
                                                </div>
                                                <div style={{ zIndex: 1090, position: "relative" }}>
                                                    <UncontrolledButtonDropdown id="btnGroupDrop1">
                                                        <DropdownToggle className="p-0 mt-n1 btn btn-ghost-dark waves-effect waves-light" color="dark">
                                                            <i className="fs-18 ri-settings-4-line align-middle">
                                                                <span>
                                                                    <i className="ri-arrow-down-s-fill align-middle"></i>
                                                                </span>
                                                            </i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem> <i className="ri-pencil-line align-middle"></i> Edit </DropdownItem>
                                                            <DropdownItem onClick={()=>handleEventTypeDelete(item.id)}><i className="ri-delete-bin-line align-middle"></i> Delete </DropdownItem>
                                                            <hr />
                                                            <DropdownItem>
                                                                <div>
                                                                    <div className="d-flex gap-5 form-check form-switch form-check-right mt-0 mb-0">
                                                                        <div>
                                                                            <Label className="form-check-label" htmlFor="flexSwitchCheckRightDisabled">On/Off</Label>
                                                                        </div>
                                                                        <div>
                                                                            <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckRightDisabled" defaultChecked />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1 ms-3">
                                                    <p className="mb-0 card-text">
                                                        <span className="fs-16 fw-light">{item.valuesJson?.event_type}</span>
                                                    </p>
                                                    <p className="text-muted">
                                                        {hours > 0 ? `${moment.duration(item.valuesJson?.duration).hours()} hrs`
                                                            : `${moment.duration(item.valuesJson?.duration).minutes()} mins`} , {formattedText}
                                                    </p>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <div className="card-footer">
                                            <div className="d-flex justify-content-between align-items-center my-n2">
                                                <Link to="#" className="text-primary" onClick={()=>generateUrl(item?.id)}>
                                                    <i className="ri-file-copy-line align-middle lh-1"></i> Copy Link
                                                </Link>
                                                <Button size="sm" color="primary" className="rounded-pill">
                                                    Share
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>


                                </Col>
                            )
                        }) : null}
                    </Row>
                </div>
            </div>
        </>
    )
}
export default MeetingEventType;

/* Needs to e add for specific collor according to type
 <Card
            className={`card-light border-0 border-top border-5 ${
                type === "success"
                    ? "border-success"
                    : type === "warning"
                    ? "border-warning"
                    : type === "danger"
                    ? "border-danger"
                    : "border-primary" // Default color
            }`}
        >
            */