import React, { useState, useEffect, useCallback } from "react";
import { withTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Chatbot from 'Components/Common/Chatbot/Chatbot';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    ListGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import FeatherIcon from "feather-icons-react";
import avatar10 from "../../../assets/images/users/avatar-7.jpg";
import Activities from "./Activities";
import Meetings from "./Meetings";
import MeetingsModal from "./Modals/MeetingsModal";
import UpdateInfo from "./innerComponent/UpdateInfo";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { ToastContainer, toast } from "react-toastify";
import { Action, createSelector, ThunkDispatch } from "@reduxjs/toolkit";
import { getGoogleCalendarData } from "store/calendarSync/action";
import DeleteModal from "./innerComponent/DeleteModal";
import Documents from "./Documents/Documents";
import OverviewPane from "./OverviewPane";
import Calls from "../Activities/Calls/Calls";
import ArrangeFields from "Components/Common/FieldsArranger/ArrangeComponent";
import { viewCreateRequest, viewUpdateRequest } from "store/views/action";
import { getAvailableStatuses } from "helpers/workflowStatusHelper";
import StatusFormModel from 'Components/Common/StatusFormModal';
import CallSelectionModel from "Components/Common/CallSelectionModel";
import { useCCP } from "CCPContext/CCPContext";
import { checkTheyOwn, getViewList, isJson } from "utils";
import testSelector from 'pages/ApplicationsDetails/testSelector.json';
import Logs from "./Logs";
import Tasks from "../Activities/Tasks/Tasks";
import Emails from "../Activities/Emails/Emails";
import NotesModal from "../Activities/Notes/NotesModal";
import Notes from "../Activities/Notes/Notes";
import Whatsapp from "../Activities/Whatsapp/Whatsapp";
import { callCreateRequest, pushNotification } from "store/calls/action";
import { noteCreateRequest } from "store/notes/action";
import EmailModal from "../Activities/Emails/innerComponent/EmailModal";
import AddTask from '../Activities/Tasks/innerComponent/AddEntryModal'
import { taskCreateRequest } from "store/tasks/action";
import AddEntry from "../Activities/Meetings/innerComponent/AddEntryModal";
import { meetingsCreateRequest, meetingssMetadataGetRequest } from "store/meetings/action";


interface ReducerState {
    [key: string]: any;
}

const Details = (props: any) => {
    const googleConnect = useSelector((state: ApplicationState) => state.calendarSync.data);
    const { getListAction, deleteAction, getDetailsAction, updateAction, model, translater, reducerState, mainTabList, getMetaData } = props;
    const { initializeCCP } = useCCP();
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

    const createReducerSelector = (
        reducerName: string,
        properties: any
    ) =>
        createSelector(
            (state: ReducerState) => state[reducerName],
            (reducerState) => {
                const result: any = {};
                properties.forEach((property: any) => {
                    result[property] = reducerState?.[property];
                });
                return result;
            }
        );
    const reducer: any = createReducerSelector(reducerState, ['metaDataFields', 'details', 'loading', "metaLoading"]);
    const derivedState: any = useSelector(reducer);
    const { metaDataFields, details, loading, metaLoading } = derivedState;
    document.title =
        "Profile Settings | Zilter";

    const [activeTab, setActiveTab] = useState<any>("overview");

    const tabChange = (tab: any) => {
        // window.location.hash = `module-${tab}`;
        if (activeTab !== tab) setActiveTab(tab);
        // if(activeTab !== "activities") setActiveButton("activities")
    };
    const [activeSubTab, setActiveSubTab] = useState<string>("activity");
    const toggleTab = (tab: any) => {
        // window.location.hash = `module-${activeTab}&subgroup-${tab}`;
        if (activeSubTab !== tab) setActiveSubTab(tab);
        if (activeSubTab !== "activity") setActiveButton("activities")
    };

    const userProfile: any = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const viewslist: any = useSelector((state: ApplicationState) => state.views.list);
    const meetingMetaData: any = useSelector((state: ApplicationState) => state.meetings.metaDataFields);
    const [activeButton, setActiveButton] = useState('activities');
    const [notesModals, setIsNotesOpen] = useState<boolean>(false);
    const [emailModals, setEmailModals] = useState<boolean>(false);
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [search, setValue] = useState<string>("");
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [viewId, setViewId] = useState<any>('');
    const [fields, setFields] = useState<any>([]);
    const [isWhatsapp, setIsWhatsapp] = useState<boolean>(false);
    const [info, setInfo] = useState<any>({});    
    const [snapShotView, setSnapShotView] = useState<any>();    
    const [studentId, setStudentId] = useState<any>(null);
    const [applicationId, setApplicationId] = useState<any>(null);
    const [renderTasks, setRenderTasks] = useState<boolean>(false)
    const [triggered, setTriggered] = useState<boolean>(false)
    const [meetingsModals, setMeetingsModals] = useState<boolean>(false);
    const [deleteAccess, setDeleteAccess] = useState<boolean>(false);
    const [communicate, setCommunicate] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus]: any = useState(null);
    const [statusForm, setStatusForm] = useState<any>(null);
    const [isCallOpen, setIsCallOpen] = useState<boolean>(false);
    const [isCallLoading, setIsCallLoading] = useState<boolean>(false);
    const [modal_tooltip, setmodal_tooltip] = useState<boolean>(false);
    const [modal_tooltip_call, setmodal_tooltip_call] = useState<boolean>(false);
    const [isSubAgent, setIsSubAgent] = useState<any>()
    const [isEnableCommunition, setIsEnableCommunition] = useState<any>(false)
    const [views, setViews] = useState<any>([])
    const [view, setView]= useState<any>({})
    const [searchValue, setSearchValue]= useState<any>('')
    const [searchOpen, setSearchOpen]= useState<any>('')
    const [viewlist, setViewlist] = useState<any>([])
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

    const [emailData, setEmailData] = useState<any>({
        to: info?.email || info?.student?.email
    })
    
    useEffect(() => {
            const parsedData: any = viewslist && viewslist?.length ? viewslist.map((item: any) => {
                let valuesJson = item?.valuesJson || item;
                let values = { ...item, ...valuesJson }
                return values
            }) : []
            let data = parsedData && parsedData.length ? parsedData : []
            setViewlist(data)
        }, [dispatch, model,viewslist ])
    
    useEffect(() => {
        let filterObject = { pageSize: 500 }
        dispatch(getGoogleCalendarData())
        dispatch(meetingssMetadataGetRequest(filterObject))
    }, [])


    useEffect(() => {
        if (userProfile?.subAgent) {
            const valuesJson = userProfile.subAgent.values_json;
            const expoToken = valuesJson && isJson(valuesJson) ? JSON.parse(valuesJson) : null;
            setIsSubAgent(expoToken)
            if (expoToken?.enable_communication == true) {
                setIsEnableCommunition(true);
            }
        }
    }, [userProfile]);
    


    const handleLogCall = (record: any, provider: string, callBack?: any) => {
        const data = record?.student ? {
            application: id,
            student: record.student.id,
            provider
        } : {
            studentId: id,
            provider
        };

        const handleSuccess = (response: any): void => {            
            initializeCCP();            
            callBack?.(response);
        };

        const handleError = (): void => {
            // Handle error if necessary
            // toast(`Error occurred`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        };

        dispatch(callCreateRequest(data, handleSuccess, handleError));
    };

    const onSendPushNotification = () => {
        let expoToken = userProfile?.valuesJson && isJson(userProfile?.valuesJson) ? JSON.parse(userProfile?.valuesJson) : null
        if (expoToken?.expo_token) {
            const data = {
                to: expoToken?.expo_token,
                "sound": "default",
                "title": "Call",
                "body": "This is a notification to call",
                "data": {
                    "call_to": info?.mobile
                }
            };
            const handleSuccess = () => {
                toast(`Call notification sent to Mobile`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                setIsCallOpen(false)
                setIsCallLoading(false);
            }
            const handleError = () => {
                toast(`Error initiating call on Mobile.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                setIsCallLoading(false);
            }
            dispatch(pushNotification(data, handleSuccess, handleError));
        }
    }

    const onCallClicked = (record: any, method: string) => {
        setIsCallLoading(true);
        if (method === "1") {
            let expoToken = userProfile?.valuesJson && isJson(userProfile?.valuesJson) ? JSON.parse(userProfile?.valuesJson) : null
            if (expoToken?.expo_token) {
                onSendPushNotification();
            } else {
                toast(`Mobile app not installed.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                setIsCallOpen(false)
                setIsCallLoading(false);
            }
        } else {
            initializeCCP();
            handleLogCall(record, "AWS");
            setIsCallOpen(false)
            setIsCallLoading(false);
        }
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setDeleteAccess(true)
            setCommunicate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.[model];
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'delete') {
                            item.value == "" || item.value == "none" ? setDeleteAccess(false) : setDeleteAccess(true)
                        }

                    })
                }
            }
            let communicateaccess = subLevelPermissionsList?.communicate;
            if (communicateaccess && communicateaccess?.length) {
                if (communicateaccess && communicateaccess.length) {
                    communicateaccess.map((item: any, index: any) => {
                        if (item.value == "they_own") {
                            if (details) {
                                let they_own: any = checkTheyOwn(details, userProfile?.id)
                                setCommunicate(they_own)
                            }
                        }
                        else if (item.name.toLowerCase() == 'communicate') {
                            item?.value == "" || item?.value == "none" ? setCommunicate(false) : setCommunicate(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile, details])

    useEffect(() => {
        dispatch(getGoogleCalendarData())
        dispatch(getDetailsAction(id))
    }, [])

    useEffect(() => {
        if (details && details.valuesJson) {
            let values: any = details?.valuesJson
            setInfo(values)
            if (model === "students") {
                setSelectedStatus(values?.student_status)
            } else {
                setSelectedStatus(values?.application_status)
                handleExtensionData(values)
            }
        }
        if (details && details.valuesJson) {
            if (model === "students") {
                setStudentId(details?.id)
            } else {
                setApplicationId(details?.id)
                setStudentId(details?.valuesJson?.student?.id)
            }
        }
    }, [details])

    const handleExtensionData = (data: any) => {
        // Define data that you want to expose to the extension
        const dataToExpose: any = {
            formData: {
                student: {
                    ...data.student,
                    birthDate: "11 March 2009",
                    nationality: "India",
                    addressLine1: "S.B. Road",
                    addressLine2: "Chatushrungi area",
                    town: "Pune",
                    postalCode: "411036",
                    country: "India",
                    communicationMode: 'phone',
                    mobile: "+91 9090909090"
                },
                course: {
                    ...data.courses,
                    course_name: "Anthropology-MA",
                    startDate: "January 2025",
                    budgetINR: "India Rupee - INR - r",
                    budgetAmount: "4900000",
                    funding: "family",
                    currentlyLocated: "India",
                    ukHistoryNoButton: "No",
                    educationCounsellor: "No",
                    additionalInfo: "No",
                    visaRefusedUK: "No",
                    visaRefusedAny: "No",
                },
            },
            selector: testSelector,
            link: 'https://apply.intostudy.com/application/course/1QotJ49H368OKgsoeOuwGc/INTOQUB_BEL_PW_40?_gl=1*162qos6*_gcl_au*NDI2OTkxMzMzLjE3MTk5MjI4NzQ'
        };

        // Attach data to the global window object
        (window as any).zilterExtData = dataToExpose;

        // Dispatch a custom event to signal that the data is ready
        const event = new CustomEvent('webAppDataReady', {
            detail: dataToExpose
        });
        window.dispatchEvent(event);
    }

    const onClickview = () => {
        navigate('/fields')
    }

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Student Deleted Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(getListAction())
            setisDeleteOpen(false)
            navigate(`/${model}`)
        }
        const handleFailure = (body: any) => {
            setisDeleteOpen(false)
        }
        dispatch(deleteAction(id, handleSuccess, handleFailure))
    }

    const onChange = (fields: any, viewname: any, viewId: any) => {
        if (viewId) {
            // setFields(fields)
            let data = {
                "fields": JSON.stringify(fields),
                "name": viewname,
            }
            const handleSuccess = (body: any) => {
                getViewList(dispatch, model, "about")
            }
            const handleFailure = () => {

            }
            dispatch(viewUpdateRequest(viewId, data, handleSuccess, handleFailure))
        }
        else {
            // setFields(fields)
            let data = {
                "name": viewname,
                "primary": "true",
                "model": model,
                "location": "about",
                "fields": JSON.stringify(fields),
                "filters": JSON.stringify([]),
            }
            const handleSuccess = () => {
                getViewList(dispatch, model, "about")
            }
            const handleFailure = () => {

            }
            dispatch(viewCreateRequest(data, handleSuccess, handleFailure))
        }
    }

    const handleChange = (value: any) => {
        setValue(value)
    }

    const onChangeStatus = (value: any) => {
        let status_key = model == "students" ? "student_status" : "application_status"
        if (value?.fields?.length) {
            const formFields: any = []
            metaDataFields.forEach((fieldJson: any) => {
                const field = fieldJson.valuesJson && isJson(fieldJson.valuesJson) ? JSON.parse(fieldJson.valuesJson) : {}
                const fieldExists = value?.fields.find((vField: any) => vField?.value === field?.key)
                if (fieldExists) {
                    if (field) {
                        if (!field.validate) {
                            field.validate = {}; // Initialize validate object if it doesn't exist
                        }
                        field.validate.required = fieldExists?.mandatory ?? false; // Set required based on mandatory, defaulting to false if undefined
                        formFields.push(field); // Push the updated field into formFields
                    }
                }
            })
            setStatusForm({ fields: formFields, id, status: value })
        } else {
            setSelectedStatus(value?.value);
            const handleSuccess = (body: any) => {
                dispatch(getDetailsAction(id));
                setTriggered((triggered) => !triggered)
            }
            dispatch(updateAction(id, { [status_key]: value?.value }, handleSuccess, () => { }))
        }
    };

    const onUpdate = (formValues: any, form?: any) => {
        let status_key = model == "students" ? "student_status" : "application_status"
        const { id, status } = form;
        setSelectedStatus(status?.value);
        const handleSuccess = (body: any) => {
            dispatch(getDetailsAction(id));
        }
        const data = { ...formValues, ...{ [status_key]: status?.value } }
        dispatch(updateAction(id, data, handleSuccess, () => { }))
        setStatusForm(null)
    }

    let statusField: any = null;
    metaDataFields && metaDataFields.length && metaDataFields.forEach((meta: any) => {
        const valuesJson = isJson(meta.valuesJson);
        if (valuesJson.key === 'student_status' || valuesJson.key === 'application_status') {
            statusField = valuesJson;
        }
    })
    const { statuses, status, snapshot } = getAvailableStatuses(statusField?.process_flows || [], selectedStatus, info?.process_flow);
    

    useEffect(() => {
        if (viewlist && viewlist.length) {     
            let allviews = viewlist.filter((item: any) => (item?.location === 'about' && item?.model === model && item?.deletedAt == null))
            setViews(allviews);     
            if(snapshot) {
                setViewId(snapshot)
                let newView = allviews.find((item: any) => item?.id === snapshot)
                setView(newView)
                if(newView?.fields && isJson(newView?.fields)) {
                    setFields(JSON.parse(newView?.fields))
                }
                else if(newView?.fields) {
                    setFields(newView?.field)
                }
            } 
            else if (view && view?.id && viewId === view?.id) {
                let view = allviews.find((item: any) => (viewId === item?.id))
                setViewId(view?.id) 
                setView(view)
                let newFields: any = view && view?.fields && isJson(view?.fields) ? JSON.parse(view?.fields) : view?.fields
                if(newFields?.length) {
                    setFields(newFields)
                }                
                else {
                    setFields([])
                }
            }
            else {
                let view = allviews.find((item: any) => (viewId === item?.id));
                if (view && view?.id) {
                    setViewId(view?.id) 
                    setView(view)
                    let newFields: any = view && view?.fields && isJson(view?.fields) ? JSON.parse(view?.fields) : view?.fields
                    if(newFields?.length) {
                        setFields(newFields)
                    }
                    else {
                        setFields([])
                    }
                }
            }
        }
    }, [viewlist, snapshot, triggered])

    const goback = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    function tog_tooltip() {
        setmodal_tooltip(!modal_tooltip);
    }

    function tog_tooltip_call() {
        setmodal_tooltip_call(!modal_tooltip_call);
    }

    const handleEmailCheck = () => {
        if (googleConnect && googleConnect.length > 0) {
            setEmailModals(true)
        } else {
            setmodal_tooltip(true)
        }
    }

    const handleMeetingsCheck = () => {
        if (googleConnect && googleConnect.length > 0) {
            setMeetingsModals(true)
        } else {
            setmodal_tooltip(true)
        }
    }

    const handleCallInitiate = () => {
        if (info?.id || info?.mobile || info?.student?.mobile)
        {
            setIsCallOpen(true)
        }
        else {
            setmodal_tooltip_call(true)
        }
    }

   useEffect(() => {
        const filters = { search: searchValue, pageSize: 100}      
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(getMetaData(model,filters));  
        }, 600);

        setTypingTimeout(newTimeout);
    }, [searchValue])

    return (
        <React.Fragment>
            <ToastContainer />
            <Modal
                isOpen={modal_tooltip_call}
                toggle={() => {
                    tog_tooltip_call();
                }}
            >
                <ModalHeader className="d-flex justify-content-end">
                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_tooltip_call(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    >
                    </Button>
                </ModalHeader>
                <ModalBody>
                    <Label className="text-center fs-16">
                        You need to add contact number of student in Students Profile to call.
                    </Label>

                </ModalBody>
            </Modal>
            <Modal
                isOpen={modal_tooltip}
                toggle={() => {
                    tog_tooltip();
                }}
            >
                <ModalHeader className="d-flex justify-content-end">
                    <Button
                        type="button"
                        onClick={() => {
                            setmodal_tooltip(false);
                        }}
                        className="btn-close"
                        aria-label="Close"
                    >
                    </Button>
                </ModalHeader>
                <ModalBody>
                    <Label className="text-center fs-16">
                        You need to add your social email client account to use Email feature to log emails in Zilter. Click below link to add your account.
                    </Label>

                </ModalBody>
                <ModalFooter>
                    <div className="mx-auto">
                        <Link to={`/user-preferences/${id}`} className="btn btn-link fw-medium">Add account <i className="ri-arrow-right-line ms-1 align-middle"></i></Link>
                    </div>
                </ModalFooter>
            </Modal>
            {emailModals && <EmailModal
                emailModals={emailModals}
                setEmailModals={setEmailModals}
                to={details?.valuesJson?.email || details?.valuesJson?.student?.email}
                info={info}
                record={details}
                model={model}
                studentId={studentId}
                applicationId={applicationId} 
                setTriggerApi={null}            
            />}
            {tasksModals && <AddTask
                show={tasksModals}
                onCloseClick={() => setTasksModals(false)}
                setTriggerApi={null}
                props={props}
                createAction={taskCreateRequest}
                userProfile={userProfile}
                studentId={studentId}
                applicationId={applicationId} 
                model={model}
            />}
            {meetingsModals && <AddEntry
                show={meetingsModals}
                onCloseClick={() => setMeetingsModals(false)}
                dataFields={meetingMetaData}
                props={props}
                createAction={meetingsCreateRequest}
                meetingsModals={meetingsModals}
                setMeetingsModals={setMeetingsModals}
                info={info}
                model={model}
                to={details?.valuesJson?.email || details?.valuesJson?.student?.email}
            />}
            {isDeleteOpen && <DeleteModal
                props={props}
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                record={info}
                onDelete={onDelete}
            />}
            {isCallOpen && <CallSelectionModel
                show={isCallOpen}
                onCloseClick={() => setIsCallOpen(false)}
                props={props}
                record={info}
                onCallClicked={onCallClicked}
                isCallLoading={isCallLoading}
            />}                      
            {notesModals && <NotesModal
                props={props}
                notesModals={notesModals}
                setIsNotesOpen={setIsNotesOpen}
                record={details}
                model={model}
                studentId={studentId}
                applicationId={applicationId}
                userProfile={userProfile}
            />}
            {statusForm && <StatusFormModel form={statusForm} onUpdate={onUpdate} onToggle={() => setStatusForm(null)} details={details} />}
            <Col xxl={3} md={4} lg={4}>
                <Card className="">
                    <CardBody className="p-4">
                        <ListGroup className="mb-3 justify-content-between" horizontal>
                            <Link to={`#`} onClick={() => goback()} color="primary" className="btn-sm">
                                <span>
                                    <FeatherIcon icon="chevron-left" className="icon-sm" />
                                    <strong>{props.t(`${translater}.back_link`)}</strong>
                                </span>
                            </Link>
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    href="#"
                                    className="btn btn-ghost-primary btn-sm dropdown"
                                    tag="button"
                                >
                                    <span>
                                        <strong className="fs-14 fw-bold">{props.t(`${translater}.actions_link`)}</strong>
                                        <FeatherIcon icon="chevron-down" className="icon-sm" />
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem className="dropdown-item" href={`/fields`}>
                                        <span className="fs-12 fw-normal"  >
                                            {props.t(`${translater}.view_all_properties_button`)}
                                        </span>
                                    </DropdownItem>
                                    {
                                        deleteAccess ?
                                            <DropdownItem className="dropdown-item" onClick={() => setisDeleteOpen(true)} >
                                                <span className="fs-12">
                                                    {props.t(`${translater}.delete`)}
                                                </span>
                                            </DropdownItem> : null}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ListGroup>
                        <div className="text-center d-flex flex-column gap-2">
                            <div className="profile-user position-relative d-inline-block mx-auto">
                                <img
                                    src={avatar10}
                                    className="rounded-circle avatar-lg img-thumbnail user-profile-image"
                                    alt="user-profile"
                                />
                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                    <Input
                                        id="profile-img-file-input"
                                        type="file"
                                        className="profile-img-file-input"
                                    />
                                    <Label
                                        htmlFor="profile-img-file-input"
                                        className="profile-photo-edit avatar-xxs"
                                    >
                                        <span className="avatar-title rounded-circle bg-light text-body">
                                            <i className="ri-camera-fill"></i>
                                        </span>
                                    </Label>
                                </div>
                            </div>
                            <h5>{info.name || `${info?.first_name ? info?.first_name : ""} ${info?.last_name ? info?.last_name : ""}`}</h5>
                            <p className="text-muted">{info.email}</p>

                            <ul id="auto-apply-button" className="list-inline mb-0">
                                <li className="list-inline-item avatar-xs">
                                    <Link
                                        id="notes"
                                        to="#"
                                        onClick={() => setIsNotesOpen(true)}
                                        className="avatar-title bg-primary-subtle text-primary fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="notes">
                                            {props.t(`${translater}.notes`)}
                                        </UncontrolledTooltip>
                                        <i className="ri-sticky-note-line"></i>
                                    </Link>
                                </li>
                                {
                                    (isEnableCommunition || communicate) && (
                                        <li className="list-inline-item avatar-xs">
                                            <Link
                                                id="email"
                                                to="#"
                                                onClick={handleEmailCheck}
                                                className="avatar-title bg-success-subtle text-success fs-15 rounded"
                                            >
                                                <UncontrolledTooltip placement="top" target="email">
                                                    {props.t(`${translater}.email`)}
                                                </UncontrolledTooltip>
                                                <i className="ri-mail-line"></i>

                                            </Link>
                                        </li>)}
                                {
                                    (isEnableCommunition || communicate) && (
                                        <li className="list-inline-item avatar-xs">
                                            <Link
                                                onClick={handleCallInitiate}
                                                id="call"
                                                to="#"
                                                className="avatar-title bg-danger-subtle text-danger fs-15 rounded"
                                            >
                                                <UncontrolledTooltip placement="top" target="call">
                                                    {props.t(`${translater}.call`)}
                                                </UncontrolledTooltip>
                                                <i className="ri-phone-line"></i>
                                            </Link>
                                        </li>)}
                                <li className="list-inline-item avatar-xs">
                                    <Link
                                        id="task"
                                        to="#"
                                        onClick={() => setTasksModals(true)}
                                        className="avatar-title bg-info-subtle text-info fs-15 rounded"
                                    >
                                        <UncontrolledTooltip placement="top" target="task">
                                            {props.t(`${translater}.tasks`)}
                                        </UncontrolledTooltip>
                                        <i className="ri-task-line"></i>
                                    </Link>
                                </li>
                                {
                                    (isEnableCommunition || communicate) && (
                                        <li className="list-inline-item avatar-xs">
                                            <Link
                                                id="meeting"
                                                to="#"
                                                onClick={handleMeetingsCheck}
                                                className="avatar-title bg-warning-subtle text-warning fs-15 rounded"
                                            >
                                                <UncontrolledTooltip placement="top" target="meeting">
                                                    {props.t(`${translater}.meetings`)}
                                                </UncontrolledTooltip>
                                                <i className="ri-calendar-check-line"></i>
                                            </Link>
                                        </li>)}
                                {
                                    (isEnableCommunition || communicate) && (
                                        <li className="list-inline-item avatar-xs">
                                            <Link
                                                id="whatsapp"
                                                to="#"
                                                onClick={() => {
                                                    setIsWhatsapp(true)
                                                }}
                                                className="avatar-title bg-success-subtle text-success fs-15 rounded"
                                            >
                                                <UncontrolledTooltip placement="top" target="whatsapp">
                                                    {props.t(`${translater}.whatsapp`)}
                                                </UncontrolledTooltip>
                                                <i className="ri-whatsapp-line"></i>
                                            </Link>
                                        </li>)}
                            </ul>
                            <div>
                                <ButtonGroup>
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            tag="button"
                                            className="btn btn-soft-primary text-left d-flex align-items-center justify-content-between"
                                            style={{ width: '200px' }}
                                        >
                                            {status?.label} <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdownmenu-primary">
                                            {statuses.map((status: any, index: number) => (
                                                <DropdownItem
                                                    key={index}
                                                    onClick={(e) => {
                                                        onChangeStatus(status)
                                                    }}
                                                >
                                                    {status?.label}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </ButtonGroup>
                            </div>
                        </div>
                        <hr></hr>
                        <div>
                            <div className="mb-3 hstack justify-content-between">
                                <h5 className="fs-16 mb-0 pb-0">{'About'}({view?.name}) </h5>
                                <ArrangeFields model={model} setFields={setFields} metadata={metaDataFields} onChange={onChange} fields={fields} views={views} view={view} props={props} setView={setView} setViewId={setViewId}  />
                            </div>     
                            <div className="search-box p-2 ps-0">
                                <Input className='' placeholder="Search for field..." style={{ padding: '1.2rem 0.9rem' }} onChange={(e: any) => setSearchValue(e.target.value)} value={searchValue}></Input>
                                {searchValue === "" ? null : <i className=" ri-close-circle-fill search-icon cursor-pointer text-danger fs-16" style={{ right: 12, left: 'auto', top: 0, position: "absolute", zIndex: 99 }} onClick={() => {setSearchOpen(false); setSearchValue("")}}></i>}
                            </div> 
                        </div>
                        <UpdateInfo fields={fields} model={model} details={details} getDetailsAction={getDetailsAction} updateAction={updateAction} metaDataFields={metaDataFields} props={props} />
                    </CardBody>
                    <CardFooter className="d-flex align-items-center justify-content-center">
                        <Button
                            onClick={() => onClickview()}
                            color="primary"
                            className="btn btn-ghost-primary waves-effect waves-light">
                            {props.t(`${translater}.view_all_properties_button`)}
                        </Button>
                    </CardFooter>
                </Card>
            </Col>

            <Col xxl={9} md={8} lg={8}>
                <Card className="">
                    <CardHeader>
                        <Nav
                            className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                            role="tablist"
                        >
                            {/* <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "timeline" })}
                                    onClick={() => {
                                        tabChange("timeline");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-road-map-line"></i>{" "}
                                    <strong>{"Timeline"}</strong>
                                </NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "overview" })}
                                    onClick={() => {
                                        tabChange("overview");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-home-3-line"></i>{" "}
                                    <strong>{props.t(`${translater}.overview_tab`)}</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "activities" })}
                                    onClick={() => {
                                        tabChange("activities");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-discuss-line"></i>{" "}
                                    <strong>{props.t(`${translater}.activities_tab`)}</strong>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to="#"
                                    className={classnames({ active: activeTab === "documents" })}
                                    onClick={() => {
                                        tabChange("documents");
                                    }}
                                    type="button"
                                >
                                    <i className="ri-file-line"></i>{" "}
                                    <strong>{props.t("applications.documents")}</strong>
                                </NavLink>
                            </NavItem>
                            {
                                mainTabList?.length && mainTabList.map((item: any) => {
                                    return <NavItem>
                                        <NavLink
                                            to="#"
                                            className={classnames({ active: activeTab === `${item.id}` })}
                                            onClick={() => {
                                                tabChange(`${item.id}`);
                                            }}
                                            type="button"
                                        >
                                            <i className={`${item.icon}`}></i>{" "}
                                            <strong>{item.title}</strong>
                                        </NavLink>
                                    </NavItem>
                                })
                            }
                        </Nav>
                    </CardHeader>
                    <CardBody className="p-2">
                        <TabContent activeTab={activeTab}>
                            {/* {activeTab === "timeline" && <TabPane tabId="timeline">
                                <Logs />
                            </TabPane>} */}
                            {activeTab == "overview" && <TabPane tabId="overview">
                                <OverviewPane fields={fields} model={model} details={details} getDetailsAction={getDetailsAction} updateAction={updateAction} metaDataFields={metaDataFields} status={status} />
                            </TabPane>}
                            {activeTab == "activities" && <TabPane tabId="activities">
                                <Row className="g-1">
                                    <CardHeader className="mt-0">
                                        <Nav
                                            className="nav-custom-light rounded card-header-tabs nav-border-top"
                                            role="tablist"
                                        >
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: activeSubTab === "activity",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("activity");
                                                    }}
                                                >
                                                    <strong>{props.t(`${translater}.activity`)}</strong>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: activeSubTab === "notes",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("notes");
                                                    }}
                                                >
                                                    <strong>{props.t(`${translater}.notes`)}</strong>
                                                </NavLink>
                                            </NavItem>
                                            {
                                                (isEnableCommunition || communicate) && (
                                                    <NavItem>
                                                        <NavLink
                                                            href="#"
                                                            className={classnames({
                                                                active: activeSubTab === "emails",
                                                            })}
                                                            onClick={() => {
                                                                toggleTab("emails");
                                                            }}
                                                        >
                                                            <strong>{props.t(`${translater}.email`)}</strong>
                                                        </NavLink>
                                                    </NavItem>)}
                                            {
                                                (isEnableCommunition || communicate) && (
                                                    <NavItem>
                                                        <NavLink
                                                            href="#"
                                                            className={classnames({
                                                                active: activeSubTab === "call",
                                                            })}
                                                            onClick={() => {
                                                                toggleTab("call");
                                                            }}
                                                        >
                                                            <strong>{props.t(`${translater}.calls`)}</strong>
                                                        </NavLink>
                                                    </NavItem>)}
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: activeSubTab === "tasks",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("tasks");
                                                    }}
                                                >
                                                    <strong>{props.t(`${translater}.tasks`)}</strong>
                                                </NavLink>
                                            </NavItem>
                                            {
                                                (isEnableCommunition || communicate) && (
                                                    <NavItem>
                                                        <NavLink
                                                            href="#"
                                                            className={classnames({
                                                                active: activeSubTab === "meetings",
                                                            })}
                                                            onClick={() => {
                                                                toggleTab("meetings");
                                                            }}
                                                        >
                                                            <strong>{props.t(`${translater}.meetings`)}</strong>
                                                        </NavLink>
                                                    </NavItem>)}
                                            {
                                                (isEnableCommunition || communicate) && (
                                                    <NavItem>
                                                        <NavLink
                                                            href="#"
                                                            className={classnames({
                                                                active: activeSubTab === "whatsapp",
                                                            })}
                                                            onClick={() => {
                                                                toggleTab("whatsapp");
                                                            }}
                                                        >
                                                            <strong>{props.t(`${translater}.whatsapp`)}</strong>
                                                        </NavLink>
                                                    </NavItem>)}
                                        </Nav>
                                    </CardHeader>
                                    <CardBody className="p-4">
                                        <TabContent activeTab={activeSubTab}>
                                            <TabPane tabId="activity">
                                                {activeSubTab === 'activity' && (
                                                    <div>
                                                        <div className="d-flex gap-2 mb-2">
                                                            <Button
                                                                size="sm"
                                                                color={activeButton === 'activities' ? 'primary' : 'light'}
                                                                className="mb-1 rounded-pill"
                                                                onClick={() => setActiveButton('activities')}
                                                            >
                                                                Activities
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                color={activeButton === 'logs' ? 'primary' : 'light'}
                                                                className="mb-1 rounded-pill"
                                                                onClick={() => setActiveButton('logs')}
                                                            >
                                                                Logs
                                                            </Button>
                                                        </div>
                                                        {activeButton === 'logs' && (
                                                            <Logs />
                                                        )}
                                                        {activeButton === 'activities' && (
                                                            <Activities isSubAgent={isEnableCommunition} model={model} details={details} 
                                                                studentId={studentId}
                                                                applicationId={applicationId}
                                                                userProfile={userProfile}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </TabPane>
                                            <TabPane tabId="notes">
                                                {activeSubTab === 'notes' && <Notes model={model} details={details} notesModals={notesModals} setIsNotesOpen={setIsNotesOpen} mainModel={model} applicationId={applicationId} studentId={studentId} activeSubTab={activeSubTab} />}
                                            </TabPane>

                                            <TabPane tabId="emails" id={"emailtab"}>                                                 
                                                {activeSubTab === 'emails' && <Emails 
                                                to={details?.valuesJson?.email || details?.valuesJson?.student?.email} studentId={studentId} applicationId={applicationId} setTriggerApi={null} communicate={communicate} model={model} details={details} mainModel={model} />}
                                            </TabPane>
                                            <TabPane tabId="call" id={"emailtab"}>
                                                {activeSubTab === 'call' && <Calls communicate={communicate} model={model} details={details} mainModel={model} applicationId={applicationId} studentId={studentId} />}
                                            </TabPane>
                                            <TabPane tabId="tasks">
                                                {activeSubTab === 'tasks' && <Tasks model={model} details={details} mainModel={model} applicationId={applicationId} studentId={studentId} />}
                                            </TabPane>
                                            <TabPane tabId="meetings">
                                                {activeSubTab === 'meetings' && <Meetings info={info} />}
                                            </TabPane>
                                            <TabPane tabId="whatsapp">
                                                {activeSubTab === 'whatsapp' && <Whatsapp communicate={communicate} model={model} details={details} id={details?.valuesJson?.student?.id || details?.id} applicationId={applicationId} studentId={studentId} />}
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Row>
                            </TabPane>}
                            {activeTab == "documents" && <TabPane tabId="documents" id={"documenttab"}>
                                <Documents isSubAgent={isEnableCommunition} tenantId={userProfile.tenant.id} model={model} details={details} />
                            </TabPane>}
                            {
                                mainTabList?.length ? mainTabList.map((item: any) => {
                                    let id = `${item.id}`;
                                    return activeTab == id && <TabPane tabId={item.id} id={item.id}>
                                        <item.component {...{
                                            ...props.componentProps,
                                            model,
                                            details,
                                            isEnableCommunition,
                                        }}
                                        />
                                    </TabPane>
                                }) : null
                            }
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
            {isWhatsapp ? <Chatbot onClose={setIsWhatsapp} /> : null}
        </React.Fragment>
    );
};

export default withTranslation()(Details);
function callsListGetRequest(id: string | undefined): any {
    throw new Error("Function not implemented.");
}

