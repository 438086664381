export interface MeetingsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Meetings {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export enum MeetingsActionTypes {
  GET_MEETINGMETADATA_REQUEST = "@@meeting/GET_MEETINGMETADATA_REQUEST",
  GET_MEETINGMETADATA_SUCCESS = "@@meeting/GET_MEETINGMETADATA_SUCCESS",
  GET_MEETINGMETADATA_ERROR = "@@meeting/GET_MEETINGMETADATA_ERROR",
  GET_MEETING_REQUEST = "@@meeting/GET_MEETING_REQUEST",
  GET_MEETING_SUCCESS = "@@meeting/GET_MEETING_SUCCESS",
  GET_MEETING_ERROR = "@@meeting/GET_MEETING_ERROR",
  GET_MEETINGS_REQUEST = "@@meeting/GET_MEETINGS_REQUEST",
  GET_MEETINGS_SUCCESS = "@@meeting/GET_MEETINGS_SUCCESS",
  GET_MEETINGS_ERROR = "@@meeting/GET_MEETINGS_ERROR",
  PUT_MEETING_REQUEST = "@@meeting/PUT_MEETING_REQUEST",
  PUT_MEETING_SUCCESS = "@@meeting/PUT_MEETING_SUCCESS",
  PUT_MEETING_ERROR = "@@meeting/PUT_MEETING_ERROR",
  DELETE_MEETING_REQUEST = "@@meeting/DELETE_MEETING_REQUEST",
  DELETE_MEETING_SUCCESS = "@@meeting/DELETE_MEETING_SUCCESS",
  DELETE_MEETING_ERROR = "@@meeting/DELETE_MEETING_ERROR",
  POST_MEETING_REQUEST = "@@meeting/POST_MEETING_REQUEST",
  POST_MEETING_SUCCESS = "@@meeting/POST_MEETING_SUCCESS",
  POST_MEETING_ERROR = "@@meeting/POST_MEETING_ERROR",
}

export interface MeetingsState {
  readonly list: Meetings[] | null;
  readonly details: Meetings | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: MeetingsMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;
}
