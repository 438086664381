import { useEffect, useState } from "react";
import {
    Label,
    Input,
    FormFeedback,
    Button,
    ButtonGroup,
} from "reactstrap";
import Select from "react-select";
import { Action,  ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getFilterOptions, getnewOptions, getOptions, optionsListGetRequest } from "store/auth/action";
import { ApplicationState } from "store";
import AsyncSelect from 'react-select/async';
import { components } from "react-select";
import { createSelector } from 'reselect';
import { isJson } from "utils";

interface DtsSelectInputProps {
    label?: string;
    type?: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    onSaveClick?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    classes?: any;
    resetvalue?: any;
}

const DtsSelectInput: React.FC<DtsSelectInputProps> = ({resetvalue,classes, setdisabled, onSaveClick, formValues, dataFields, invalid, rest, defaultValue, label, type, value, placeholder, onChange, isEditable, name, icon, iconPosition, errorText, actionButtons, readOnly, disabled }: any) => {
    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [defaultoption, setDefaultOption] = useState<any>(null)
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const modelOptions = useSelector((state: ApplicationState) => state.auth.optionData);
    const loading = useSelector((state: ApplicationState) => state.auth.optionsLoading);
    const [option, setOption] = useState({})
    const [options, setOptions] = useState([]);
    const [search, setsearch] = useState<string>("");
    const [resetValue, setResetValue] = useState<any>(null)
    const [filterObject, setFilterObject] = useState<any>({
        pageSize: 10
    });
    const [hover, setHover] = useState<any>(false)
    const [valid, setvalid] = useState<any>(false)
    const [errormsg, setErrormsg] = useState();
    const onhandleChange = (option: any) => {  
        if(option == null){
            onChange("");
            setDefaultOption(option)
        }     
        else {
            onChange(option);
            setDefaultOption(option)
        }
    }
    const validateInput = (value: any) => { 
        if(rest && rest.validate && rest.validate.required) {
            if(value === "") {
               let error: any =  `Please enter ${label}`;
                setErrormsg(error)
                return false
            }
        }
        setErrormsg(undefined)
        return true
    }
    useEffect(() => {
        if(rest && rest.validate) {           
            if(errormsg)
            { 
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    useEffect(() => {
        let newoptions: any = []
        let options: any = modelOptions && modelOptions?.[rest.model]?.length ? modelOptions?.[rest.model].map((item: any) => {
            let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item?.valuesJson
             let labelvalue = rest.model == "students" && label == "name" ? valueJson?.[label] || (`${valueJson?.["first_name"] || ""} ${valueJson?.["last_name"] || ""}`) : valueJson?.[rest.optionLabel]              
             return { label: labelvalue, value: item?.id }
        }) : []
        if(rest && rest.returnValue && rest.returnValue === "label") 
        {
            newoptions = handleduplicates(options)
            setOptions(newoptions)
        }
        else {   
            newoptions = options         
            setOptions(newoptions)
        }
        let option = newoptions.filter((item: any) => item.value === defaultValue || item.label === defaultValue || item.value === defaultValue?.value)
        if(option?.length) { 
            setDefaultOption(option[0])
        }            
        else if (defaultValue?.value) {
            setDefaultOption(defaultValue);
        }
    },[modelOptions?.[rest.model],hover])

    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            if (value === '') {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.add('d-block');
            }
            else {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.remove('d-block');
            }
        }
    }, [value])


    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            const feedback: any = document.getElementById(`${name}`);
            if (feedback) feedback.classList.remove('d-none');
        }
    }, [rest])

    const handletypetext = (value: any) => {   
        let search = null;
        if(value) {
            search =  {
                "term": value,
                "field": "all"
            }
        }     
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            let filter ={
                "filters": [
                {
                    "search": search
                }
                ],
                "sorts": [
                {
                    "field": "name",
                    "order": "asc"
                }
                ]
            }
            let filterObject=null
            if(rest.model == 'sources' || rest.responseType == 'treeselect') filterObject= {responseType:'treeselect'}
            dispatch(optionsListGetRequest(rest.model, filter, 0, 20, filterObject)); 	
     
        }, 2000);
        setTypingTimeout(newTimeout);
    };

    const onCheckClick = (e: any) => {
        e.preventDefault();
        onSaveClick();
        setHover(false)
    }

    const handleduplicates = (options: any) => {
        const seenLabels = new Set();
        const uniqueOptions: any= [];
        options && options.length && options.forEach((option: any) => {
            if (!seenLabels.has(option.label)) {
                seenLabels.add(option.label);
                uniqueOptions.push(option);
            }
        });
        return uniqueOptions;
    }

    useEffect(() => {
        let options: any = modelOptions && modelOptions?.[rest.model]?.length ? modelOptions?.[rest.model].map((item: any) => {
            let valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item?.valuesJson
            let label = rest?.optionLabel ? rest?.optionLabel : "name";
            let labelvalue = rest.model == "students" && label == "name" ? valueJson?.[label] || (`${valueJson?.["first_name"] || ""} ${valueJson?.["last_name"] || ""}`) : valueJson?.[rest.optionLabel]
            return { label: labelvalue, value: item?.id }
        }) : []
        if(rest && rest.returnValue && rest.returnValue === "label") 
        {
            let newoptions = handleduplicates(options)
            setOptions(newoptions)
        }
        else {   
            let newoptions = options;     
            setOptions(newoptions)
        }
    }, [modelOptions?.[rest.model]])
    
    const CustomOption = (props: any) => {
        return (
          <components.Option {...props}
            onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                e.preventDefault();
                }
            }} 
          >
            <div className='hstack justify-content-between'>
                {props.children}
                {props.isSelected && props.value !== undefined && <i className="ri-check-line fs-20" />}
            </div>
          </components.Option>
        );
      };
      


    const customStyles = {
        // Customize the style of the dropdown menu
        control: (provided: any, state: any) => ({
                    ...provided,
                    // color: '#495057',
                    width: '100%',
                    fontWeight: 900,
                    borderRadius: 3,
                    boxShadow: 'none',
                    '&:hover': {
                    // border: '0.5px solid lightgrey',
                    },
                    backgroundColor: 'transparent'
        }),
        menuPortal: (base: any) => {           
            const { zIndex, ...rest } = base;
            return { ...rest, zIndex: 9999 };
        },
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          borderRadius: 4,
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          '&:hover': {
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
          },
        }),
    }

    const onFocus = () => {        
        setOptions([])
        if (rest?.conditionalFilter?.isFilteredData && rest?.conditionalFilter?.filterFieldValue && rest?.conditionalFilter?.filterField && formValues[rest?.conditionalFilter?.filterFieldValue]) {  
            if(rest?.conditionalFilter?.filterFieldValue) {
                if (rest && rest.model && formValues[rest?.conditionalFilter?.filterFieldValue]) {
                    let value = formValues[rest?.conditionalFilter?.filterFieldValue]
                    let filter ={
                        "filters": [
                          {
                            "quick": [
                              {
                                "key": rest?.conditionalFilter?.filterField,
                                "condition": "IN",
                                "values": [
                                    value
                                ],
                                "quick": true
                              }
                            ],
                            "advance": [],
                            "search": null
                          }
                        ],
                        "sorts": [
                          {
                            "field": "createdAt",
                            "order": "asc"
                          }
                        ]
                    }
                    let filterObject=null
                    if(rest.model == 'sources' || rest.responseType == 'treeselect') filterObject= {responseType:'treeselect'}
                   dispatch(optionsListGetRequest(rest.model, filter, 0, 20, filterObject));        
                }
            }
        }        
        else {
            let filter ={
                "filters": [
                  {
                    "quick": [],
                    "advance": [],
                    "search": null
                  }
                ],
                "sorts": [
                  {
                    "field": "name",
                    "order": "asc"
                  }
                ]
            }
            let filterObject=null
            if(rest.model == 'sources' || rest.responseType == 'treeselect') filterObject= {responseType:'treeselect'}
         dispatch(optionsListGetRequest(rest.model, filter, 0, 20, filterObject)); 	
        }
    }

    useEffect(() => {
        options && options.length &&  options.map((item: any) =>{
            if(item.value === defaultValue || item.label === defaultValue || item.value === defaultValue?.value) {
                 let defaultoption = item;
                setDefaultOption(defaultoption);
            }
        })
    }, [defaultValue])

    useEffect(() => {
        if(resetvalue) {
            options && options.length &&  options.map((item: any) =>{
                if(item.value === resetvalue || item.label === resetvalue || item.value === resetvalue?.value) {
                    let defaultoption = item;
                    setResetValue(resetvalue)
                   setDefaultOption(defaultoption);
                }
                else if (resetvalue?.value) {
                    setResetValue(resetvalue)
                    setDefaultOption(resetvalue);
                }
           })
        }
        else {
            setDefaultOption(null)
        }
    }, [resetvalue])

 
    useEffect(() => {
        onFocus()
    }, [])
 
    return (
         <div className={classes ? classes : 'pb-3'}> 
         {
             actionButtons ?
             <div>
                 { hover ?
                 <div>
                     {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}
                     <div className="d-flex border rounded-3">
                            <Select {...rest} 
                                styles={customStyles}
                                menuPortalTarget= {document.getElementById('root')}
                                menuPosition="fixed"
                                onFocus={() => onFocus()}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault();
                                    }
                                  }} 
                                defaultOptions
                                value={defaultoption}
                                components={{ Option: CustomOption }} 
                                isOptionDisabled={(option: any) => option.label == 'Loading...'}
                                className='form-control form-select-sm p-0 border-0 d-flex' 
                                type={type}
                                defaultValue={defaultoption}
                                isLoading={loading}
                                options={loading ? [{ label: 'Loading...', value: null }] : options || []}
                                id={name} placeholder={placeholder ? placeholder : `Select ${rest.label}`} onChange={(selectedOption: any) => onhandleChange(selectedOption)} readOnly={readOnly} disabled={disabled} onInputChange={(inputValue: any) => handletypetext(inputValue)}></Select>
                            <ButtonGroup>
                                <Button onClick={(e) => { e.preventDefault(); setHover(false); onhandleChange(resetvalue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button>
                                <Button onClick={(e) => {onCheckClick(e);onFocus();}} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                            </ButtonGroup>
                        </div>   
                     {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}                     
                 </div>
                 : <div>
                     {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}              
                     <div className="hstack justify-content-between topbar-user">
                         <div className="fs-14 fw-medium form-control border-0 topbar-user text-nowrap text-truncate text-nowrap-ellipsis" >
                            {defaultoption?.label ? defaultoption.label : resetvalue?.label ? resetvalue?.label : resetvalue && typeof resetvalue !== 'object' ? defaultValue : '' }
                         </div>
                         {
                            readOnly ? null : <Button onClick={() => {setHover(true); onhandleChange(resetvalue)}} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-edit-2-fill" /> </Button>}
                     </div>
                 </div>
                 }
             </div>
         :            
             <div>
                 {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}
                 <Select {...rest} 
                    styles={customStyles}
                    menuPortalTarget= {document.getElementById('root')}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }} 
                    onFocus={() => onFocus()}
                    defaultOptions
                    components={{ Option: CustomOption }}
                    isOptionDisabled={(option: any) => option.label == 'Loading...'}
                    className='form-select-sm p-0'   
                    value={defaultoption}
                    menuPosition="fixed"
                 type={type} defaultValue={defaultoption} options={loading ? [{ label: 'Loading...', value: null }] : options || []} id={name} placeholder={placeholder ? placeholder : `Select ${rest.label}`} onChange={(selectedOption: any) => onhandleChange(selectedOption)} readOnly={readOnly} disabled={disabled} onInputChange={(inputValue: any) => handletypetext(inputValue)}></Select>
                 {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
             </div>
         }
         </div>
    );
};

export default DtsSelectInput;
