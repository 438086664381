import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { Action, createSelector, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import UserAvatar from './UserAvatar';
import OverviewTab from './OverviewTab';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { isJson } from 'utils';
import { ApplicationState } from 'store';

interface ReducerState {
    [key: string]: any;
}

const Section = (props: any) => {
    const navigate = useNavigate();
    const { id, childId} = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

    const { detailsGetAction, metaDatagetRequest, reducerState, model, translater, updateAction, label, tabList, createInvoice } = props;

    const createReducerSelector = (
        reducerName: string,
        properties: any
    ) =>
        createSelector(
            (state: ReducerState) => state[reducerName],
            (reducerState: any) => {
                const result: any = {};
                properties.forEach((property: any) => {
                    result[property] = reducerState[property];
                });
                return result;
            }
        );
    const reducer = useMemo(() => createReducerSelector(reducerState, ['metaDataFields', 'details', 'loading', 'metaLoading']), [reducerState]);

    const derivedState: any = useSelector(reducer);
    const userProfile: any = useSelector((state: ApplicationState) => state.auth.userProfile);
    const { metaDataFields, details, loading, metaLoading } = derivedState;
    const [activeTab, setActiveTab] = useState<string>('1');
    const [info, setInfo] = useState<any>({});
    const [metaData, setMetadata] = useState<any>({});

    const toggleTab = useCallback((tab: any) => {
        if (activeTab !== tab) {
          setActiveTab(tab);
        }
      }, [activeTab]);
    
     const goback = useCallback(() => {
        navigate(-1);
      }, [navigate]);

    useEffect(() => {
        dispatch(detailsGetAction(childId || id))
        const filters = { pageSize: 500 }
        dispatch(metaDatagetRequest(model, filters));
    }, [])

    useEffect(() => {
        setMetadata(metaDataFields)
    }, [metaDataFields])

    useEffect(() => {
        let objectDetails = details?.valuesJson && isJson(details?.valuesJson) ? JSON.parse(details.valuesJson) : details?.valuesJson;
        let info = {...objectDetails, ...details}
        if(model === 'deals') {
            info = {...objectDetails, ...details, name: objectDetails?.products?.name}
        }
        if(model === 'courses' || model === 'institutes') {
            if(userProfile?.tenant?.id && objectDetails?.[userProfile?.tenant?.id]) {
                let tenantspecific = objectDetails?.[userProfile?.tenant?.id]
                info = {...objectDetails, ...details, ...tenantspecific}
            }
        }
        setInfo(info)
    }, [details])
    return (
        <React.Fragment>
            <ToastContainer />
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <div className="bg-warning-subtle">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-white rounded-circle">
                                                        <UserAvatar img={info && info.img} firstName={info?.name} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{info?.name}</h4>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        {info?.city ? <div><i className="ri-map-pin-fill align-bottom me-1 fs-16"></i>{info?.city}</div> : null}
                                                        <div className="vr"></div>
                                                        {details?.createdAt ? <div>Create Date : <span className="fw-medium">{moment(details?.createdAt).format('lll')}</span></div> : null}
                                                        <div className="vr"></div>
                                                        {details?.updatedAt ? <div>Updated Date : <span className="fw-medium">{moment(details?.updatedAt).format('lll')}</span></div> : null}
                                                        <div className="vr"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-bold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            {props.t(`${translater}.overview`)}
                                        </NavLink>
                                    </NavItem>
                                    {
                                        tabList && tabList.length && tabList.map((tab: any, index: any) => {
                                            return <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === `${index + 2}` }, "fw-bold")}
                                                    onClick={() => { toggleTab(`${index + 2}`); }}
                                                    href="#">
                                                    {tab.title}
                                                </NavLink>
                                            </NavItem>
                                        })
                                    }
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Button color="primary" className="w-auto btn-sm my-2" onClick={() => goback()}>
                        Back
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                        <TabPane tabId="1">
                            <OverviewTab label={label} model={model} info={info} metaData={metaData} translater={translater} updateAction={updateAction} reducerState={reducerState} detailsGetAction={detailsGetAction} createInvoice={createInvoice} details={details} />
                        </TabPane>
                        {
                            tabList && tabList.length && tabList.map((tab: any, index: any) => {
                                return <TabPane tabId={`${index + 2}`}>
                                    <tab.component {...{
                                            ...props.componentProps
                                        }}
                                    />
                                </TabPane>
                            })
                        }
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default withTranslation()(Section);