import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Label, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from "react-redux";
import { confirmRecommendations } from "store/recommendation/action";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import RecommendSuccess from './RecommendSuccess';
import { getRecommendationsDetails } from '../../store/recommendation/action'
import { ApplicationState } from 'store';
import { isMobile } from 'react-device-detect'
import { tenantGetOpenRequest } from 'store/user/action';
import { isJson } from 'utils';
import Logo from 'Components/Common/Logo/Logo';

interface Inputs {
    password: string;
    confirm_password: string;
}

interface ResetPasswordProps {
    t: (key: string) => string;
}

const Recommendations: React.FC<ResetPasswordProps> = ({ t }) => {
    const { tenant, id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const recommendDetails = useSelector((state: ApplicationState) => state.recommend.details);
    const tenatDetails = useSelector((state: ApplicationState) => state.user.tenatDetails);
    const [name, setName] = useState<any>(null)
    const [companyName, setCompanyName] = useState<any>(null)
    const [confirmedList, setConfirmed] = useState<any>([]);
    const [selectedList, setSelectedList] = useState<any>([]);
    const [linktrue, setLinktrue] = useState<boolean>(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [tenantObject, setTenant] = useState<any>({})

    const onSubmit = () => {
        const course_name = confirmedList.join(",")
        const data = {
            application_ids: course_name
        }
        const handleSuccess = (body: any): void => {
            setIsConfirm(true)
        }
        const handleError = (body: any): void => {
            setIsConfirm(true)
        }
        dispatch(confirmRecommendations(tenant, id, data, handleSuccess, handleError));
    }

    const handleselect = (obj: any, value: boolean) => {
        if (value) {
            let newList = confirmedList;
            newList.push(obj.id)
            setLinktrue(true)
            setConfirmed(newList)
        }
        else {
            let newList = confirmedList.filter((item: any) => item !== obj.id);
            if (!newList.length) {
                setLinktrue(false)
            }
            setConfirmed(newList)
        }
    }

    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        const handleSuccess = () => { }
        const handlefailure = () => { }
        dispatch(getRecommendationsDetails(tenant, id, handleSuccess, handlefailure))
        dispatch(tenantGetOpenRequest(tenant))
    }, [])

    useEffect(() => {
        let tenant = tenatDetails?.settings && isJson(tenatDetails?.settings) ? JSON.parse(tenatDetails?.settings) : tenatDetails?.settings;
        setTenant(tenant) 
    }, [tenatDetails])


    useEffect(() => {
        const parsedData: any = recommendDetails && recommendDetails.length ? recommendDetails.map((item: any) => {
            const valuesJson = item.valuesJson ? item.valuesJson : {};
            const student = valuesJson?.student
            const course = valuesJson?.courses;
            const university = valuesJson?.institutes;
            let values = { id: item.id, createdAt: item.createdAt, company_name: item.companyName, course: course, university: university }
            setName(student?.name)
            setCompanyName(item?.companyName)
            return values
        }) : []
        let courses = parsedData && parsedData.length && parsedData
        setSelectedList(courses)
    }, [recommendDetails])
    return (
        <div>
            {!isMobile ? 
                <div className='page-content'>
                    <Container fluid>
                        <ToastContainer />
                        {
                            isConfirm ?
                                <RecommendSuccess tenant={tenantObject} />
                                :
                                <Row className="hstack justify-content-center">
                                    <Col xs={12} sm={12} lg={10} md={10} xl={10} className="hstack justify-content-center">
                                        <Row className="hstack justify-content-center">
                                            <Col xs={12} sm={12} lg={10} md={10} xl={10}>
                                                <Card>
                                                    <div className="bg-info-subtle position-relative">
                                                        <CardBody className="card-body p-5 vstack gap-4">                                                            
                                                            <Logo tenant={tenantObject} />
                                                            <div className="text-center">
                                                                <h3>Courses Recommendations</h3>
                                                            </div>
                                                        </CardBody>
                                                        <div className="shape">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                version="1.1"
                                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                xmlns-svgjs="http://svgjs.com/svgjs"
                                                                width="1440"
                                                                height="60"
                                                                preserveAspectRatio="none"
                                                                viewBox="0 0 1440 60"
                                                            >
                                                                <g mask='url("#SvgjsMask1001")' fill="none">
                                                                    <path
                                                                        d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                                                                        style={{ fill: "var(--vz-secondary-bg)" }}
                                                                    ></path>
                                                                </g>
                                                                <defs>
                                                                    <mask id="SvgjsMask1001">
                                                                        <rect width="1440" height="60" fill="#ffffff"></rect>
                                                                    </mask>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <CardBody className="card-body p-5">
                                                        <div className="d-flex px-5 py-2">
                                                            <div className="flex-grow-1">
                                                                <h5>Dear {`${name}`},</h5>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex px-5 py-2">
                                                            <p className="fs-16 fw-medium">
                                                                It was great knowing you. Understanding your needs,
                                                                budget and requirements after our call, I have shortlisted
                                                                some courses for you. Please have a look and let me know your
                                                                choices in the last column or add any comments. Happy to discuss
                                                                in more detail. WhatsApp me.
                                                            </p>
                                                        </div>

                                                        <div className="d-flex px-5 py-2">
                                                            <p className="fs-16 fw-medium">
                                                                But before that I would like you to check further if we are good fit:
                                                            </p>
                                                        </div>

                                                        <div className="d-flex px-5 py-2">
                                                            <div className="flex-shrink-0 me-3">
                                                                <FeatherIcon icon="check-circle" className={"text-primary icon-dual-primary icon-md"} />
                                                                <i
                                                                    data-feather="check-circle"
                                                                    className="text-primary icon-dual-primary icon-md"
                                                                ></i>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-18 fw-bold text-dark">Who should not apply with {`${companyName}`}:</h5>
                                                                <ul>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            If you are a student not serious about your career or studies;
                                                                            OR consider studying abroad as an escape route to work illegally;
                                                                            OR you are looking for other shortcuts in your career.
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            {`${companyName}`} is not a good fit for you.
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex px-5 py-2">
                                                            <div className="flex-shrink-0 me-3">
                                                                <FeatherIcon icon="check-circle" className={"text-primary icon-dual-primary icon-md"} />
                                                                <i data-feather="check-circle"
                                                                    className="text-primary icon-dual-primary icon-md"
                                                                ></i>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-18 fw-bold text-dark">Who should apply with {`${companyName}`}:</h5>
                                                                <ul>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            If you are a career-focused student and want to study in a reputed university;
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            If you want to get employed in a graduate role as soon as you graduate; OR
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            If you want to gain work experience while you study and cover your living expenses; OR
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            If you want to get access to the biggest database of scholarships and enhance your chances of being funded
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            OR all of the above.
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            You must work with {`${companyName}`}. It will be life-changing for you.
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex px-5 py-2">
                                                            <div className="flex-shrink-0 me-3">
                                                                <FeatherIcon icon="check-circle" className={"text-primary icon-dual-primary icon-md"} />
                                                                <i
                                                                    data-feather="check-circle"
                                                                    className="text-primary icon-dual-primary icon-md"
                                                                ></i>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-18 fw-bold text-dark">If you are ready:</h5>
                                                                <p className="fs-16 fw-medium">
                                                                    Choose/ discuss the options of the university from below:
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="vstack px-5 py-2">
                                                            <div className="table-responsive">
                                                                <table className="table align-middle position-relative table-nowrap border border-1">
                                                                    <thead className="cursor-pointer table-active bg-info bg-opacity-25">
                                                                        <tr>
                                                                            <th className='border border-1 fw-bolder fs-16' style={{ width: 50 }}>
                                                                                Checkbox
                                                                            </th>
                                                                            <th className='border border-1 fw-bolder fs-16'>
                                                                                Country
                                                                            </th>
                                                                            <th className='border border-1 fw-bolder fs-16'>
                                                                                Course
                                                                            </th>
                                                                            <th className='border border-1 fw-bolder fs-16'>
                                                                                Institute
                                                                            </th>
                                                                            <th className='border border-1 fw-bolder fs-16'>
                                                                                Fee
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody id="task-list">
                                                                        {selectedList && selectedList.map((item: any, key: any) => {
                                                                            return <tr key={key}>
                                                                                <td style={{ width: 50 }} className="align-content-center border border-2" >
                                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                                        <input
                                                                                            className="form-check-input ms-3 cursor-pointer"
                                                                                            type="checkbox"
                                                                                            onChange={(e) => { handleselect(item, e.target.checked) }}
                                                                                        />
                                                                                    </ul>
                                                                                </td>
                                                                                <td className='border border-2'>
                                                                                    <Label>{item?.university?.country ? (item?.university?.country).toUpperCase() : ""}</Label>
                                                                                </td>
                                                                                <td className='border border-2'>
                                                                                    {item?.course?.course_link && item?.course?.course_link != 'NA' ? <label className="cursor-pointer text-primary" onClick={() => { handleIconClick(item?.course?.course_link) }}>{item?.course?.course_name}</label> : <label>{item?.course?.course_name}</label>}
                                                                                </td>
                                                                                <td className='border border-2'>{item?.university?.institute_name || item?.university?.name}</td>
                                                                                <td className='border border-2'>
                                                                                    {item?.course?.currency && item?.course?.currency != "NA" && item?.course?.course_fee && item?.course?.course_fee != "NA" ? <>{item?.course?.currency}{item?.course?.course_fee}</> : null}
                                                                                </td>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="flex-shrink-0 me-3 text-center m-1">
                                                                <Button disabled={!linktrue} onClick={() => onSubmit()}>Confirm</Button>
                                                            </div>
                                                            <p className="fs-16 fw-medium mt-1">
                                                                Discuss these options with me WhatsApp me and meanwhile share your documents for the application process:
                                                            </p>
                                                        </div>
                                                        <div className="d-flex px-5 py-2">
                                                            <div className="flex-shrink-0 me-3">
                                                                <FeatherIcon icon="check-circle" className={"text-primary icon-dual-primary icon-md"} />
                                                                <i
                                                                    data-feather="check-circle"
                                                                    className="text-primary icon-dual-primary icon-md"
                                                                ></i>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-18 fw-bold text-dark"> Share the document (checklist below):</h5>
                                                                <p className="fs-16 fw-medium">
                                                                    Please share the below documents for the applications.<br />
                                                                    All the below documents to be sent to us in pdf format except SOP and CV ( word file)
                                                                </p>
                                                                <ul>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            Marksheets and transcripts- 10th, 12th or Diploma, UG degree ( All semester marksheet in ascending order),PG (if applicable)
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            Proof of English language proficiency IELTS (if available)
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            Passport copy (1st and last page)
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            Detailed CV with start and end date of activities/ education/ job experience.
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            Marksheets and transcripts- 10th, 12th or Diploma, UG degree ( All semester marksheet in ascending order),PG (if applicable)
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            2 Letter of References (1 Work -if any +1 academic)
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            Gap explanation (If any)
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            Detailed SOP (To be provided before application once the university has been shortlisted by the student)
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex px-5 py-2">
                                                            <div className="flex-shrink-0 me-3">
                                                                <FeatherIcon icon="check-circle" className={"text-primary icon-dual-primary icon-md"} />
                                                                <i
                                                                    data-feather="check-circle"
                                                                    className="text-primary icon-dual-primary icon-md"
                                                                ></i>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-18 fw-bold text-dark">What is unique about {`${companyName}`}?</h5>
                                                                <p className="fs-16 fw-medium">
                                                                    We personally care for you and your career. We shall support you while you are in India and when you come abroad for studies. From career counselling, course and university shortlisting, applications, scholarships, visa applications, accommodation, flights and even job preparation.
                                                                </p>
                                                                <br />
                                                                <p className="fs-16 fw-medium">
                                                                    Most students choose us because
                                                                </p>
                                                                <ul>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            <strong>Unique Scholarship database:</strong>
                                                                            Over the years and with our technology we have a database of 54,000 active scholarships, where we map your profile and apply to help you secure the scholarship you deserve. On average approx £5,000 to £7,000 in scholarships.
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="fs-16 fw-medium">
                                                                            <strong>Free Graduate Job Bootcamp:</strong>
                                                                            Ultimately most students want to land on a suitable graduate job, but it's not as easy as it sounds. Thus we partnered with IBM, Google, Coca Cola, JP Morgan, Santander and many more employers to deliver a free 8-week training to help you achieve your dream graduate job.
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                        }
                    </Container>
                </div>
                :
                <Container fluid>   
                     <ToastContainer />
                    {
                        isConfirm ?
                            <RecommendSuccess  tenant={tenantObject} />
                            :                             
                            <Row className="p-1 hstack justify-content-center">
                                <Col xs={12} sm={12} lg={10} md={10} xl={10}>
                                    <Card>
                                        <div className="bg-info-subtle position-relative">
                                            <Logo tenant={tenantObject} />
                                            <CardBody className="card-body p-5 pt-2">
                                                <div className="text-center">
                                                    <h3>Courses Recommendations</h3>
                                                </div>
                                            </CardBody>
                                            <div className="shape">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    version="1.1"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    xmlns-svgjs="http://svgjs.com/svgjs"
                                                    width="1440"
                                                    height="60"
                                                    preserveAspectRatio="none"
                                                    viewBox="0 0 1440 60"
                                                >
                                                    <g mask='url("#SvgjsMask1001")' fill="none">
                                                        <path
                                                            d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                                                            style={{ fill: "var(--vz-secondary-bg)" }}
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <mask id="SvgjsMask1001">
                                                            <rect width="1440" height="60" fill="#ffffff"></rect>
                                                        </mask>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <CardBody className="card-body p-2">
                                            <div className="d-flex px-2 py-2">
                                                <div className="flex-grow-1">
                                                    <h5>Dear {`${name}`},</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex px-2 py-2">
                                                <p className="fs-16 fw-medium">
                                                    It was great knowing you. Understanding your needs,
                                                    budget and requirements after our call, I have shortlisted
                                                    some courses for you. Please have a look and let me know your
                                                    choices in the last column or add any comments. Happy to discuss
                                                    in more detail. WhatsApp me.
                                                </p>
                                            </div>

                                            <div className="d-flex px-2 py-2">
                                                <p className="fs-16 fw-medium">
                                                    But before that I would like you to check further if we are good fit:
                                                </p>
                                            </div>

                                            <div className="d-flex px-2 py-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <FeatherIcon icon="check-circle" className={"text-primary icon-dual-primary icon-md"} />
                                                    <i
                                                        data-feather="check-circle"
                                                        className="text-primary icon-dual-primary icon-md"
                                                    ></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-18 fw-bold text-dark">Who should not apply with {`${companyName}`}:</h5>
                                                    <ul>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                If you are a student not serious about your career or studies;
                                                                OR consider studying abroad as an escape route to work illegally;
                                                                OR you are looking for other shortcuts in your career.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                {`${companyName}`} is not a good fit for you.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="d-flex px-2 py-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <FeatherIcon icon="check-circle" className={"text-primary icon-dual-primary icon-md"} />
                                                    <i data-feather="check-circle"
                                                        className="text-primary icon-dual-primary icon-md"
                                                    ></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-18 fw-bold text-dark">Who should apply with {`${companyName}`}:</h5>
                                                    <ul>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                If you are a career-focused student and want to study in a reputed university;
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                If you want to get employed in a graduate role as soon as you graduate; OR
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                If you want to gain work experience while you study and cover your living expenses; OR
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                If you want to get access to the biggest database of scholarships and enhance your chances of being funded
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                OR all of the above.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                You must work with {`${companyName}`}. It will be life-changing for you.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="d-flex px-2 py-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <FeatherIcon icon="check-circle" className={"text-primary icon-dual-primary icon-md"} />
                                                    <i
                                                        data-feather="check-circle"
                                                        className="text-primary icon-dual-primary icon-md"
                                                    ></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-18 fw-bold text-dark">If you are ready:</h5>
                                                    <p className="fs-16 fw-medium">
                                                        Choose/ discuss the options of the university from below:
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="vstack px-2 py-2">
                                                <div className="table-responsive">
                                                    <Table responsive className="table align-middle position-relative table-nowrap border border-1">
                                                        <thead className="cursor-pointer table-active bg-info bg-opacity-25">
                                                            <tr>
                                                                <th className='border border-1 fw-bolder fs-16' style={{ width: 50 }}>
                                                                    Checkbox
                                                                </th>
                                                                <th className='border border-1 fw-bolder fs-16'>
                                                                    Country
                                                                </th>
                                                                <th className='border border-1 fw-bolder fs-16'>
                                                                    Course
                                                                </th>
                                                                <th className='border border-1 fw-bolder fs-16'>
                                                                    Institute
                                                                </th>
                                                                <th className='border border-1 fw-bolder fs-16'>
                                                                    Fee
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="task-list">
                                                            {selectedList && selectedList.map((item: any, key: any) => {
                                                                return <tr key={key}>
                                                                    <td style={{ width: 50 }} className="align-content-center border border-2" >
                                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                                            <input
                                                                                className="form-check-input ms-3 cursor-pointer"
                                                                                type="checkbox"
                                                                                onChange={(e) => { handleselect(item, e.target.checked) }}
                                                                            />
                                                                        </ul>
                                                                    </td>
                                                                    <td className='border border-2'>
                                                                        <Label>{item?.university?.country ? (item?.university?.country).toUpperCase() : ""}</Label>
                                                                    </td>
                                                                    <td className='border border-2'>
                                                                        {item?.course?.course_link && item?.course?.course_link != 'NA' ? <label className="cursor-pointer text-primary" onClick={() => { handleIconClick(item?.course?.course_link) }}>{item?.course?.course_name}</label> : <label>{item?.course?.course_name}</label>}
                                                                    </td>
                                                                    <td className='border border-2'>{item?.university?.institute_name || item?.university?.name}</td>
                                                                    <td className='border border-2'>
                                                                        {item?.course?.currency && item?.course?.currency != "NA" && item?.course?.course_fee && item?.course?.course_fee != "NA" ? <>{item?.course?.currency}{item?.course?.course_fee}</> : null}
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="flex-shrink-0 me-3 text-center m-1">
                                                    <Button disabled={!linktrue} onClick={() => onSubmit()}>Confirm</Button>
                                                </div>
                                                <p className="fs-16 fw-medium mt-1">
                                                    Discuss these options with me WhatsApp me and meanwhile share your documents for the application process:
                                                </p>
                                            </div>
                                            <div className="d-flex px-2 py-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <FeatherIcon icon="check-circle" className={"text-primary icon-dual-primary icon-md"} />
                                                    <i
                                                        data-feather="check-circle"
                                                        className="text-primary icon-dual-primary icon-md"
                                                    ></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-18 fw-bold text-dark"> Share the document (checklist below):</h5>
                                                    <p className="fs-16 fw-medium">
                                                        Please share the below documents for the applications.<br />
                                                        All the below documents to be sent to us in pdf format except SOP and CV ( word file)
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                Marksheets and transcripts- 10th, 12th or Diploma, UG degree ( All semester marksheet in ascending order),PG (if applicable)
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                Proof of English language proficiency IELTS (if available)
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                Passport copy (1st and last page)
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                Detailed CV with start and end date of activities/ education/ job experience.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                Marksheets and transcripts- 10th, 12th or Diploma, UG degree ( All semester marksheet in ascending order),PG (if applicable)
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                2 Letter of References (1 Work -if any +1 academic)
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                Gap explanation (If any)
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                Detailed SOP (To be provided before application once the university has been shortlisted by the student)
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="d-flex px-2 py-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <FeatherIcon icon="check-circle" className={"text-primary icon-dual-primary icon-md"} />
                                                    <i
                                                        data-feather="check-circle"
                                                        className="text-primary icon-dual-primary icon-md"
                                                    ></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-18 fw-bold text-dark">What is unique about {`${companyName}`}?</h5>
                                                    <p className="fs-16 fw-medium">
                                                        We personally care for you and your career. We shall support you while you are in India and when you come abroad for studies. From career counselling, course and university shortlisting, applications, scholarships, visa applications, accommodation, flights and even job preparation.
                                                    </p>
                                                    <br />
                                                    <p className="fs-16 fw-medium">
                                                        Most students choose us because
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                <strong>Unique Scholarship database:</strong>
                                                                Over the years and with our technology we have a database of 54,000 active scholarships, where we map your profile and apply to help you secure the scholarship you deserve. On average approx £5,000 to £7,000 in scholarships.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p className="fs-16 fw-medium">
                                                                <strong>Free Graduate Job Bootcamp:</strong>
                                                                Ultimately most students want to land on a suitable graduate job, but it's not as easy as it sounds. Thus we partnered with IBM, Google, Coca Cola, JP Morgan, Santander and many more employers to deliver a free 8-week training to help you achieve your dream graduate job.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </Container>
            }
        </div>
    );
};

export default withTranslation()(Recommendations);