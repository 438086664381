import { Button, Col, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";

const ConfirmSchedule = () => {
    return (
        <>
            <div>
                <div><Label className="fs-18 fw-bold">Event details</Label> </div>
                <div>
                    <Col xxl={9} md={9}>
                        <div>
                            <Label htmlFor="exampleInputrounded" className="fw-bold form-label">Name *</Label>
                            <Input type="text" className="form-control" id="exampleInputrounded" placeholder="Enter name" />
                        </div>
                        <div className="mt-2">
                            <Label htmlFor="exampleInputrounded" className="fw-bold form-label">Email *</Label>
                            <Input type="text" className="form-control" id="exampleInputrounded" placeholder="Enter email" />
                        </div>
                        <div className="mt-3 mb-2">
                            <Label htmlFor="exampleInputrounded" className="fw-bold form-label">Please share anything that will help prepare for our meeting.</Label>
                            <textarea
                                className="form-control bg-light border-light"
                                id="exampleFormControlTextarea1"
                                // value={newNote}
                                // onChange={(e) => handleNewNoteChange(e)}
                                rows={3}
                            ></textarea>
                        </div>
                        <div>
                            <Label>By proceeding, you confirm that you have read and agree to <Link className="fw-bold" to="#">{("Zilter's Terms of Use")}</Link> and <Link className="fw-bold" to="#">{("Privacy Notice")}</Link>.</Label>
                        </div>
                        <div>
                        <Button color="primary" className="rounded-pill"> Schedule Event </Button>
                        </div>
                    </Col>
                </div>
            </div>
        </>
    )
}
export default ConfirmSchedule;