import { withTranslation } from "react-i18next";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label, ListGroup, ListGroupItem, UncontrolledAlert } from "reactstrap";
import { ApplicationState } from "store";
import { useState } from "react";
import googleicon from "../../../../assets/images/googleicon.png";
import CircleBadge from "pages/StudentsDetails/CircleBadge";
import { disconnectGoogleAccount, getGoogleCalendarData } from "store/calendarSync/action";
import { toast } from "react-toastify";
import ConfirmSyncModal from "../../ConfirmSyncModal";
import SyncOptionModal from "../../SyncOptionModal";

const MeetingAccount = ({ t, avtarImage2 }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const googleConnect = useSelector((state: ApplicationState) => state.calendarSync?.data);
    const [modal, setModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleGoogleAccountDisconnect = (email: any) => {
        const handleSuccess = (body: any): void => {
            dispatch(getGoogleCalendarData());
        };
        const handleError = (body: any): void => {
            const message = () =>
                toast(`${body.status}`, {
                    position: "top-center",
                    hideProgressBar: true,
                    className: "bg-danger text-white",
                });
            message();
        };
        dispatch(disconnectGoogleAccount(email, handleSuccess, handleError));
    };
    return (
        <>
         <ConfirmSyncModal
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
            // props={props}
            />
            <SyncOptionModal
                modal={modal}
                setModal={setModal}
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
            // props={props}
            />
         <div>
                            {googleConnect?.length === 0 ? (
                                <div>

                                    <div className="mt-3">
                                        <div>
                                            <h4>{t("userpreferences.calendar")}</h4>
                                            <UncontrolledAlert color="danger" className="mb-xl-3">
                                                <strong>
                                                    {t(
                                                        "userpreferences.your_calendar_is_disconnected"
                                                    )}
                                                </strong>
                                                &nbsp;
                                                <span>
                                                    {t(
                                                        "userpreferences.connect_it_to_access_all_the_features_in_the_meetings_tool"
                                                    )}
                                                </span>
                                            </UncontrolledAlert>
                                            <p>
                                                {t(
                                                    "userpreferences.connect_your_calendar_to_use_zilter_meetings_and_calendar_sync"
                                                )}
                                            </p>
                                            <div>
                                                <ListGroup>
                                                    <ListGroupItem>
                                                        <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                                                        {t(
                                                            "userpreferences.send_meetings_directly_from_zilter"
                                                        )}
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                                                        {t(
                                                            "userpreferences.log_outgoing_meetings_automatically"
                                                        )}
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                                                        {t(
                                                            "userpreferences.sync_your_calendar_so_contacts_can_schedule_time_with_you"
                                                        )}
                                                        <p className="mx-4 fs-12">
                                                            &nbsp;
                                                            {t(
                                                                "userpreferences.requires_calendar_sync"
                                                            )}
                                                        </p>
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                                                        {t(
                                                            "userpreferences.see_your_zilter_tasks_in_your_calendar"
                                                        )}
                                                        <p className="mx-4 fs-12">
                                                            &nbsp;
                                                            {t(
                                                                "userpreferences.requires_an_additional_step_with_task_sync_only_available_on_Sales_starter_and_up_plans"
                                                            )}
                                                        </p>
                                                    </ListGroupItem>
                                                </ListGroup>
                                            </div>
                                            <div className="my-3">
                                                <Button
                                                    onClick={() => setModal(true)}
                                                    color="primary"
                                                    className="bg-gradient"
                                                >
                                                    {" "}
                                                    {t(
                                                        "userpreferences.connect_your_calendar"
                                                    )}{" "}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <h4>{t("userpreferences.calendar")}</h4>
                                    {googleConnect?.map((item: { email: any }) => (
                                        <div
                                            key={item.email}
                                            className="d-flex justify-content-between my-3 mx-0"
                                            onMouseEnter={() => setIsHovered(item.email)}
                                            onMouseLeave={() => setIsHovered(false)}
                                        >
                                            <div className="d-flex justify-content-start">
                                                <div>
                                                    <img
                                                        className="avatar-sm"
                                                        src={googleicon}
                                                        alt="Google Icon"
                                                    />
                                                </div>
                                                <div>
                                                    <Label className="ms-1 mb-0 mt-1 fs-15 fw-medium">
                                                        <strong>{item.email}</strong>
                                                    </Label>
                                                    <div>
                                                        <span className="ms-1">
                                                            <CircleBadge />
                                                        </span>
                                                        {t("userpreferences.connected")}
                                                    </div>
                                                </div>
                                            </div>
                                            {isHovered === item.email && (
                                                <div>
                                                    <Button
                                                        onClick={() =>
                                                            handleGoogleAccountDisconnect(item.email)
                                                        }
                                                        className="btn-danger"
                                                    >
                                                        {t("userpreferences.disconnect")}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
        </>
    )
}
export default withTranslation()(MeetingAccount);