import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { applicationCreateRequest, applicationDelete, applicationGetRequest, applicationsMetadataGetRequest, applicationUpdateRequest, applicationsListGetRequest } from 'store/applications/action';
import { useParams } from 'react-router-dom';
import { ApplicationState } from 'store';
import { useSelector } from 'react-redux';
import { isJson } from 'utils';
const Applications = (props: any) => {  
    const metaDataFields: any = useSelector((state: ApplicationState) => state.applications.metaDataFields);
    const { id } = useParams();  
    const { viewId, isCreateModal, setCreateOpen, isSubAgent } = props;  
    const [ parentField, setParentField ] = useState({})
    useEffect(() => {
        metaDataFields && metaDataFields.length && metaDataFields.map((item: any) => {
            let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
            if(field.key === "student") setParentField({...field, ...item})
        })
    }, [metaDataFields])
    document.title = "DTS | Zilter";
    return ( 
        <React.Fragment>   
            <Listing 
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                updateAction={applicationUpdateRequest}
                getListAction={applicationsListGetRequest}
                deleteAction={applicationDelete}
                detailsGetAction={applicationGetRequest}
                metaDatagetRequest={applicationsMetadataGetRequest}
                createAction={applicationCreateRequest}
                model={"applications"}
                reducerState={"applications"}
                translater={"applications"}
                parent={"student"}
                parentId={id}
                parentField={parentField}
                isSubAgent={isSubAgent}
            />
        </React.Fragment>
    );
};
export default withTranslation()(Applications);


