import { Reducer } from "redux";
import { ViewsActionTypes, ViewsState } from "./types";
import { isJson } from "utils";


export const initialState: ViewsState =
{
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    loading: false,
    dataLoading: false,
    metaLoading: false,    
    storeFilters: null,    
    storeView: null
}
const reducer: Reducer<ViewsState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case ViewsActionTypes.GET_VIEWMETADATA_REQUEST: {
            return { ...state, metaLoading: true,  metaDataFields: [] };
        }
        case ViewsActionTypes.GET_VIEWMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case ViewsActionTypes.GET_VIEWMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload,  metaDataFields: [] };
        }
        case ViewsActionTypes.GET_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewsActionTypes.GET_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case ViewsActionTypes.GET_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case ViewsActionTypes.POST_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewsActionTypes.POST_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case ViewsActionTypes.POST_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ViewsActionTypes.GET_VIEWS_REQUEST: {
            return { ...state, loading: true, list: [] };
        }
        case ViewsActionTypes.GET_VIEWS_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case ViewsActionTypes.GET_VIEWS_ERROR: {
            return { ...state, loading: false, errors: action.payload, list: []};
        } 
        case ViewsActionTypes.PUT_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewsActionTypes.PUT_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                viewlist: action.payload.content
            };
        }
        case ViewsActionTypes.PUT_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ViewsActionTypes.DELETE_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewsActionTypes.DELETE_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case ViewsActionTypes.DELETE_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 
        case ViewsActionTypes.POST_FILTER_SUCCESS: {   
            let filterData = localStorage?.getItem('storeFilters')       
            let filters: any = filterData && isJson(filterData) ? JSON.parse(filterData) : {}             
            localStorage.setItem('storeFilters', JSON.stringify({...filters, ...action.payload}))
            return { ...state, loading: false, storeFilters: action.payload };
        } 
        case ViewsActionTypes.GET_FILTER_REQUEST: {    
            let localFilters = localStorage?.getItem('storeFilters')       
            let filters: any = localFilters && isJson(localFilters) ? JSON.parse(localFilters) : null        
            return { ...state, loading: true, storeFilters: filters };
        }  
        case ViewsActionTypes.GET_FILTER_SUCCESS: {     
            return { ...state, loading: false };
        }           
        case ViewsActionTypes.POST_STORE_VIEW_SUCCESS: {   
            let viewData = localStorage?.getItem('storeView')       
            let views: any = viewData && isJson(viewData) ? JSON.parse(viewData) : {}        
            localStorage.setItem('storeView', JSON.stringify({...views, ...action.payload}))
            return { ...state, loading: false };
        }  
        case ViewsActionTypes.GET_STORE_VIEW_REQUEST: {    
            let localView = localStorage?.getItem('storeView')       
            let View: any = localView && isJson(localView) ? JSON.parse(localView) : null        
            return { ...state, loading: true, storeView: View };
        }  
        case ViewsActionTypes.GET_STORE_VIEW_SUCCESS: {     
            return { ...state, loading: false };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as ViewsReducer };
