import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { tablesGetRequest } from "store/report/action";
import { Table } from "store/report/types";

interface DataSourceDrawerProps {
    isOpen: boolean;
    initData: any;
    toggle: (value: boolean) => void;
    onDataSource: (primary: any, secondary: any, tertiary: any) => void;
    setAllModels: (models: any) => void;
    t: (key: string) => string;
}

const DataSourceDrawer: React.FC<DataSourceDrawerProps> = ({
    isOpen,
    toggle,
    onDataSource,
    setAllModels,
    initData,
    t
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user = useSelector((state: ApplicationState) => state.auth.userProfile);
    const tables = useSelector((state: ApplicationState) => state.report.tables);
    const tablesLoading = useSelector((state: ApplicationState) => state.report.tables_loading);

    const [models, setModels] = useState<any[]>([]);
    const [secondaryModels, setSecondaryModels] = useState<any[]>([]);
    const [tertiaryModels, setTertiaryModels] = useState<any[]>([]);
    const [selectedPrimarySource, setSelectedPrimarySource] = useState<any>(null);
    const [selectedSecondarySources, setSelectedSecondarySources] = useState<any[]>([]);
    const [selectedTertiarySources, setSelectedTertiarySources] = useState<any[]>([]);

    // Fetch tables and transform them into models
    useEffect(() => {
        if (user?.id) {
            const data = {
                tables: [],
                filters: {
                    primary_keys: true,
                    foreign_keys: true,
                    metadata_types: ["datasetselect", "treeselect"]
                }
            };
            dispatch(tablesGetRequest(data));
        }
    }, [user, dispatch]);

    // Transform fetched tables into model structure
    useEffect(() => {
        if (tables?.length) {
            const models = tables.map((item: Table) => {
                const columns = item?.columns?.length
                    ? item.columns.map((column) => ({
                          additional_details: {
                              id: column.additional_details?.id,
                              key: column.additional_details?.key,
                              model: column.additional_details?.model,
                              optionLabel: column.additional_details?.optionLabel,
                              placeholder: column.additional_details?.placeholder,
                              type: column.additional_details?.type,
                              values: column.additional_details?.values,
                              process_flows: column.additional_details?.process_flows
                          },
                          column_name: column.column_name,
                          key: column.column_name,
                          column_type: column.column_type,
                          foreign_key_table: column.foreign_key_table,
                          input_type: column.input_type,
                          json_field: column.is_json_field,
                          primary_key: column.is_primary_key,
                          label: column.label
                      }))
                    : [];
                return {
                    label: item.table_name.charAt(0).toUpperCase() + item.table_name.slice(1),
                    value: item.table_name,
                    schema: item?.schema_name,
                    type: item?.type,
                    table_name: item.table_name,
                    columns: columns
                };
            });

            setModels(models);
            setAllModels(models);
        }
    }, [tables, setAllModels]);

    // Handle initial data if provided
    useEffect(() => {
        if (initData?.length && models?.length) {
            // Find the primary data source
            const primary = initData.find((dataSource: any) => dataSource.primary);
            if (!primary) {
                console.warn("No primary data source found");
                setSelectedPrimarySource(null);
                setSelectedSecondarySources([]);
                setSelectedTertiarySources([]);
                setSecondaryModels([]);
                setTertiaryModels([]);
                return;
            }
    
            // Get secondary and tertiary directly from initData
            const secondary = initData.filter((dataSource: any) => dataSource.secondary);
            const tertiary = initData.filter((dataSource: any) => dataSource.tertiary);
    
            // Set the selected models
            setSelectedPrimarySource(primary);
            setSelectedSecondarySources(secondary || []);
            setSelectedTertiarySources(tertiary || []);
    
            // Update the models dynamically based on relationships
            const dynamicSecondary = getRelatedModels(models, [primary]) || [];
            setSecondaryModels(dynamicSecondary);
    
            const dynamicTertiary = getRelatedModels(models, secondary).filter(
                (model: any) =>
                    !secondary.some((sec: any) => sec.value === model.value) && // Exclude already selected secondary models
                    model.value !== primary.value // Exclude the primary model
            );
            setTertiaryModels(dynamicTertiary);
        }
    }, [initData, models]);

    // Helper: Get related models based on foreign key and metadata
    const getRelatedModels = (models: any[], relatedTo: any[]) => {
        if (!relatedTo || relatedTo.length === 0) return [];
        return models.filter((model: any) =>
            model?.columns?.some((column: any) =>
                relatedTo?.some(
                    (rel: any) =>
                        // Check forward relationships
                        column?.foreign_key_table === rel?.value ||
                        ((column.additional_details?.type === "datasetselect" || column.additional_details?.type === "treeselect") &&
                            column.additional_details?.model === rel.value) ||
                        // Check reverse relationships
                        rel?.columns?.some(
                            (relColumn: any) =>
                                relColumn?.foreign_key_table === model?.value ||
                                ((relColumn.additional_details?.type === "datasetselect" || relColumn.additional_details?.type === "treeselect") &&
                                    relColumn.additional_details?.model === model?.value)
                        )
                )
            )
        );
    }; 


    // Update secondary and tertiary models based on primary selection
    const updateDependentModels = (models: any[], primary: any) => {
        // Get secondary models related to the primary
        const secondaryModels = getRelatedModels(models, [primary]);
        setSecondaryModels(secondaryModels);
    
        // Get tertiary models related to the secondary
        const tertiaryModels = getRelatedModels(
            models,
            secondaryModels.map((sec) => ({ value: sec.value }))
        ).filter(
            (model: any) =>
                !secondaryModels.some((sec: any) => sec.value === model.value) && // Exclude secondary models
                model.value !== primary.value // Exclude primary model
        );
        setTertiaryModels(tertiaryModels);
    };

    // Handle Primary selection
    const onSelectedPrimarySource = (selectedModel: any) => {
        // Find the full object from models using the value
        const fullModel = models.find((model) => model.value === selectedModel?.value);
    
        setSelectedPrimarySource(fullModel);
        setSelectedSecondarySources([]);
        setSelectedTertiarySources([]);
    
        if (fullModel) {
            updateDependentModels(models, fullModel);
        }
    };    

    // Handle Secondary selection
    const onSelectedSecondarySources = (selectedModels: any[]) => {
        // Find full objects for all selected secondary models
        const fullModels = selectedModels.map((selectedModel) =>
            models.find((model) => model.value === selectedModel.value)
        );
    
        setSelectedSecondarySources(fullModels);
    
        // Get tertiary options based on the full secondary models
        const tertiaryOptions = getRelatedModels(
            models,
            fullModels.map((sec: any) => sec)
        ).filter(
            (model: any) =>
                !(fullModels || []).some((sec: any) => sec?.value === model.value) && // Exclude selected secondary
                model.value !== selectedPrimarySource?.value // Exclude primary model
        );
    
        setTertiaryModels(tertiaryOptions);
        setSelectedTertiarySources([]);
    };

    // Handle Tertiary selection
    const onSelectedTertiarySources = (selectedModels: any) => {
        setSelectedTertiarySources(selectedModels || []);
    };

    // Add selected sources and reset state
    const onAdd = () => {
        onDataSource(selectedPrimarySource, selectedSecondarySources, selectedTertiarySources);
        resetValues();
        toggle(false);
    };

    const resetValues = () => {
        setSelectedPrimarySource(null);
        setSelectedSecondarySources([]);
        setSelectedTertiarySources([]);
        setSecondaryModels([]);
        setTertiaryModels([]);
    };

    // Custom styles for react-select
    const customStyles = {
        input: (provided: any) => ({ ...provided, color: "black" }),
        control: (provided: any) => ({ ...provided, backgroundColor: "transparent" }),
        menu: (provided: any) => ({ ...provided, backgroundColor: "white", color: "black" }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#e9ecef" : "white",
            color: "black"
        })
    };

    if(tablesLoading) {
        return (
            <Offcanvas
                direction="end"
                isOpen={isOpen}
                backdrop={false}
                toggle={() => toggle(false)}
                className="placeholder-glow"
            >
                <OffcanvasHeader
                    className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary"
                    id="processFlowCreateHeader"
                    toggle={() => toggle(false)}
                >
                    <span className="m-0 me-2 text-white">
                        {t('reports.add.heading')}
                    </span>
                </OffcanvasHeader>
                <OffcanvasBody className="bg-light" id="processFlowCreateBody">
                    <div className="d-flex flex-column gap-4">
                        <div className="d-flex flex-column gap-1">
                            <Label className="fw-bold placeholder">{t('reports.add.primary_data_source')}</Label>
                            <p className="placeholder w-100 mb-0"></p>
                            <p className="placeholder w-50 mb-1"></p>
                            <div className="placeholder w-100" style={{height: '36px'}}></div>
                        </div>
                        <div className="d-flex flex-column gap-1">
                            <Label className='fw-bold placeholder'>{t('reports.add.secondary_data_sources')}</Label>
                            <p className="placeholder w-100 mb-0"></p>
                            <p className="placeholder w-50 mb-1"></p>
                            <div className="placeholder w-100" style={{height: '36px'}}></div>
                        </div>
                    </div>
                </OffcanvasBody>
                <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                    <div className="placeholder" style={{height: '36px', width: '80px'}}>{t('reports.add.btn_cancel')}</div>
                    <div className="placeholder" style={{height: '36px', width: '80px'}}>{t('reports.add.btn_add')}</div>
                </div>
            </Offcanvas>
        );
    }

    return (
        <Offcanvas direction="end" isOpen={isOpen} toggle={() => toggle(false)}>
            <OffcanvasHeader toggle={() => toggle(false)} className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary">
                <span className="m-0 me-2 text-white">
                    {t("reports.add.heading")}
                </span>
            </OffcanvasHeader>
            <OffcanvasBody className="d-flex flex-column gap-4">
                <div>
                    <Label>{t("reports.add.primary_data_source")}</Label>
                    <Select
                        options={models}
                        styles={customStyles}
                        value={selectedPrimarySource}
                        onChange={onSelectedPrimarySource}
                    />
                </div>
                <div>
                    <Label>{t("reports.add.secondary_data_sources")}</Label>
                    <Select
                        isMulti
                        options={secondaryModels}
                        styles={customStyles}
                        value={selectedSecondarySources}
                        onChange={onSelectedSecondarySources}
                    />
                </div>
                <div>
                    <Label>{t("reports.add.tertiary_data_sources")}</Label>
                    <Select
                        isMulti
                        options={tertiaryModels}
                        styles={customStyles}
                        value={selectedTertiarySources}
                        onChange={onSelectedTertiarySources}
                    />
                </div>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <Button color="danger" onClick={() => toggle(false)}>
                    {t("reports.add.btn_cancel")}
                </Button>
                <Button
                    color="primary"
                    onClick={onAdd}
                    disabled={!selectedPrimarySource}
                >
                    {t("reports.add.btn_add")}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default withTranslation()(DataSourceDrawer);
