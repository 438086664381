import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { components } from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import country from './countrycode.json';
import { ApplicationState } from 'store';

const Course = (props: any) => {
    const { inputs, handleRemoveComponent, courseOptions } = props;
    const loading = useSelector((state: ApplicationState) => state.auth.optionsLoading);
    const [includedCourse, setIncludedCourse] = useState([])
    const [excludedCourse, setExcludedCourse] = useState([])
    const [excludedCourseOption, setExcludedCourseOption] = useState([])
    const [type, setType] = useState<any>({
        label: 'Included',
        value: 'included'
    })
    const [isAllIncluded, setIsAllIncluded] = useState<any>(false)
    const ValueContainer = ({ children, getValue, ...props }: any) => {
        var length = getValue()?.length;
        if (length) {
            return (
                <components.ValueContainer {...props}>
                    {!props.selectProps.inputValue &&
                        `${length} ${length !== 1 ? "Courses" : "Course"} selected`
                    }
                    {React.Children.map(children, child => {
                        return child.type === components.Input ? child : null;
                    })}
                </components.ValueContainer>
            );
        }
        return <components.ValueContainer {...props}>{children}</components.ValueContainer>
    };


    const onDelete = (item: any, type: any) => {
        switch (type) {
            case 'included':
                let included = includedCourse.filter((selected: any) => selected?.value !== item.value)
                setIncludedCourse(included)
                break;
            case 'excluded':
                let excluded = excludedCourse.filter((selected: any) => selected?.value !== item.value)
                setExcludedCourse(excluded)
                break;
        }
    }

    useEffect(() => {
        let courses = inputs?.courses;
        let type = typeOption.find((item: any) => courses?.inclusionType === item.value);
        if(type) {
            setType(type);
        }
        setIncludedCourse(courses?.includedCourse);
        setExcludedCourse(courses?.excludedCourse);
        setIsAllIncluded(courses?.isAllIncluded);
    }, [inputs?.courses])

    const typeOption = [
        {
            label: 'Included',
            value: 'included'
        },
        {
            label: 'Excluded',
            value: 'excluded'
        }
        // ,
        // {
        //     label: 'Both',
        //     value: 'both'
        // }
    ]

    const onAddCourse = () => {
        let courses = {
            inclusionType: type?.value,
            includedCourse: includedCourse,
            excludedCourse: excludedCourse,
            isAllIncluded: isAllIncluded
        }
        props.onAddRule(courses, 'courses')
        setIncludedCourse([]);
        setExcludedCourse([]);
        setIsAllIncluded(false)
        setType({})
    }
    useEffect(() => {
        if(type?.value === 'both'){
            if(!includedCourse?.length && !excludedCourse?.length) {
                setExcludedCourseOption(courseOptions)
            }
            else  if(includedCourse?.length && excludedCourse?.length) {
                const excludedOptions = courseOptions.filter((option: any) => {
                    return !excludedCourse.find((excluded: any) => excluded.value === option.value) &&
                           !includedCourse.find((included: any) => included.value === option.value);
                  });
                setExcludedCourseOption(excludedOptions)
            }
            else  if(includedCourse?.length) {
                const excludedOptions = courseOptions.filter((option: any) => {
                    return !includedCourse.find((included: any) => included.value === option.value);
                  });
                setExcludedCourseOption(excludedOptions)
            }
            else  if(excludedCourse?.length) {
                const excluded = courseOptions.filter((option: any) => {
                    return !excludedCourse.find((excluded: any) => excluded.value === option.value);
                  });
                setExcludedCourseOption(excluded)
            }
        } 
        else {
            setExcludedCourseOption(courseOptions)
        }
    }, [courseOptions, includedCourse, excludedCourse, type?.value])

    return (
        <React.Fragment>
            <Card className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                <CardHeader className='hstack bg-primary-subtle justify-content-between'>
                    <Label className='fw-bold fs-20 text-primary'>Course Wise</Label>
                    <button onClick={() => { props.setOpen(false); handleRemoveComponent('courses') }} className="btn btn-sm btn-soft-danger remove-list fs-12"                                                                                >
                        <i className="ri-delete-bin-fill"></i>{" "}
                    </button>{" "}
                </CardHeader>
                <CardBody>
                    <div>
                        <Label className='fw-bold fs-20 text-primary'>Inclusion Type</Label>
                        <Select
                            placeholder="Select..."
                            closeOnSelect={true}
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            name="fields"
                            options={typeOption}
                            values={inputs?.inclusionType}
                            onChange={(option: any) => { setType(option); setIncludedCourse([]); setExcludedCourse([]); setIsAllIncluded(false); }}
                            value={type}
                        />
                    </div>
                    <div>
                        {type.value !== 'excluded' && includedCourse?.length ?
                            <div>
                                <Label className='fw-semibold fs-16 text-primary'>Selected Included Course</Label>
                                <div className='hstack w-100 p-1 gap-2'>
                                    {includedCourse && includedCourse?.length ? includedCourse.map((item: any) => {
                                        return <div className='ms-0'>
                                            <span className="badge bg-primary fs-12">{item?.label}{"  "}
                                                <i className="ri-close-circle-line text-danger fs-12 cursor-pointer" onClick={() => onDelete(item, 'included')}></i>{" "}</span>
                                        </div>
                                    }) : null}
                                </div>
                                <hr />
                            </div>
                            : null}
                        {type.value !== 'included' && excludedCourse?.length ?
                            <div>
                                <Label className='fw-semibold fs-16 text-primary'>Selected Excluded Course</Label>
                                <div className='hstack w-100 p-1 gap-2'>
                                    {excludedCourse && excludedCourse?.length ? excludedCourse.map((item: any) => {
                                        return <div className='ms-0'>
                                            <span className="badge bg-primary fs-12">{item?.label}{"  "}
                                                <i className="ri-close-circle-line text-danger fs-12 cursor-pointer" onClick={() => onDelete(item, 'excluded')}></i>{" "}</span>
                                        </div>
                                    }) : null}
                                </div>
                                <hr />
                            </div>
                            : null}
                    </div>
                    <Row className='align-item-end p-2'>
                        {type.value !== 'excluded' ?
                            <Col className='align-item-end'>
                                {type.value === 'included' ? <div className='p-4'>
                                    <Input
                                        className={"form-check-input"}
                                        type={"checkbox"}
                                        checked={isAllIncluded}
                                        onChange={(e: any) => {setIsAllIncluded(!isAllIncluded); setIncludedCourse([])}}
                                    />
                                    <Label className="form-label">Include All Courses</Label>
                                </div> : null}
                                {
                                    !isAllIncluded ?
                                        <Select
                                            placeholder="Select Included..."
                                            closeOnSelect={true}
                                            isMulti
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable
                                            isSearchable
                                            name="fields"
                                            options={excludedCourseOption}
                                            isLoading={loading}
                                            values={inputs?.courses?.includedCourse}
                                            onChange={setIncludedCourse}
                                            value={includedCourse}
                                            components={{ ValueContainer }}
                                        />
                                        : null
                                }
                            </Col>
                            : null}
                            {type.value !== 'included' ?
                                <Col className='align-item-end'>
                                    <Select
                                        placeholder="Select Excluded..."
                                        closeOnSelect={true}
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        name="fields"
                                        options={excludedCourseOption}
                                        values={inputs?.courses?.excludedCourse}
                                        onChange={setExcludedCourse}
                                        isLoading={loading}
                                        value={excludedCourse}
                                        components={{ ValueContainer }}
                                    />
                                </Col>
                                : null}
                    </Row>
                    <div className='hstack justify-content-center p-2'>
                        <Button
                            onClick={() => onAddCourse()}
                            color="primary"
                            className="btn-primary">
                            {inputs?.courses && Object.values(inputs?.courses)?.length ? 'Update Rule' : 'Save Rule'}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Course);
