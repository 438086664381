import produce from 'immer';
import { Reducer } from "redux";
import { CallsActionTypes, CallsState } from "./types";


export const initialState: CallsState =
{
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    loading: false,
    dataLoading: false,
    metaLoading: false,
    sentiment: null,
    callloading: false
}
const reducer: Reducer<CallsState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case CallsActionTypes.GET_CALLMETADATA_REQUEST: {
            return { ...state, metaLoading: true,  metaDataFields: [] };
        }
        case CallsActionTypes.GET_CALLMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case CallsActionTypes.GET_CALLMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload,  metaDataFields: [] };
        }
        case CallsActionTypes.GET_CALL_REQUEST: {
            return { ...state, loading: true };
        }
        case CallsActionTypes.GET_CALL_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case CallsActionTypes.GET_CALL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case CallsActionTypes.POST_CALL_REQUEST: {
            return { ...state, loading: true };
        }
        case CallsActionTypes.POST_CALL_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case CallsActionTypes.POST_CALL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CallsActionTypes.GET_CALLS_REQUEST: {
            return { ...state, loading: true, list: [] };
        }
        case CallsActionTypes.GET_CALLS_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case CallsActionTypes.GET_CALLS_ERROR: {
            return { ...state, loading: false, errors: action.payload, list: []};
        } 
        case CallsActionTypes.PUT_CALL_REQUEST: {
            return { ...state, loading: true };
        }
        case CallsActionTypes.PUT_CALL_SUCCESS: {
            return {
                ...state,
                loading: false,
                calllist: action.payload.content
            };
        }
        case CallsActionTypes.PUT_CALL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CallsActionTypes.DELETE_CALL_REQUEST: {
            return { ...state, loading: true };
        }
        case CallsActionTypes.DELETE_CALL_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case CallsActionTypes.DELETE_CALL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case CallsActionTypes.CALLS_DOWNLOAD_DOCUMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case CallsActionTypes.CALLS_DOWNLOAD_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case CallsActionTypes.CALLS_DOWNLOAD_DOCUMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 
        case CallsActionTypes.CALLS_SENTIMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case CallsActionTypes.CALLS_SENTIMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                sentiment: action.payload
            };
        }
        case CallsActionTypes.CALLS_SENTIMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }          
        default: {
            return state;
        }
    }
};

export { reducer as CallsReducer };
