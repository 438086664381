import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';

interface Step7Props {  
    t: (key: string) => string;
}

const Success: React.FC<Step7Props> = ({ t }: Step7Props) => {
  return (
    <div>
      <Row className="hstack gap-3 flex-column justify-content-center">
        <Col lg={12}>
          <div className="text-center">
            <div className="avatar-md mt-5 mb-4 mx-auto">
              <div className="avatar-title bg-light text-success display-4 rounded-circle">
                <i className="ri-checkbox-circle-fill"></i>
              </div>
            </div>
            <div className="vstack gap-2 justify-content-center">
                <h5>{t("migration.thank_you")}</h5>   
                <div className="vstack justify-content-center">
                    <p className="text-muted align-self-center text-center fs-16 flex-wrap" style={{maxWidth: 400}}>{t("migration.success_statement")}</p>
                </div>             
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(Success);
