import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import logoLight from "../../assets/images/dtsimg/svg/zilter_logo_blue.svg";
import { withTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import Logo from 'Components/Common/Logo/Logo';

const RecommendSuccess: React.FC<any> = (props) => {
    const { tenant } = props;
    document.title = "Confirmed Courses | Zilter";
    return (
        <div className="auth-page-content">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="mt-4">
                            <CardBody className="p-4">
                                <Row>
                                    <Logo tenant={tenant} />
                                </Row>
                                <div className="text-center mt-2">
                                    <FeatherIcon icon="check-circle" className="icon-xxl icon-dual-success" />
                                    <h5 className="text-secondary fw-bold fs-28 mb-2 mt-4">Confirmed.</h5>
                                </div>
                                <div className="text-center mt-4 mb-2">
                                    <h5 className="text-secondary fw-bold fs-28">Thanks for confirming your choice, one of our team member will contact you soon to progress further.</h5>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default withTranslation()(RecommendSuccess);