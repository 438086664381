import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Label, Table, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import { confirmRecommendations, getAuthorizationFormDetails } from "store/recommendation/action";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import RecommendSuccess from '../../AuthorizationLetters/StudentForm';
import html2pdf from 'html2pdf.js';
import { ApplicationState } from 'store';
import moment from 'moment';
import InPersonVerify from '../../AuthorizationLetters/InPersonVerify';
import config from "../../../config";
import { authorizationletterGetRequest } from 'store/authorizationletters/action';
import FormBuilder from 'Components/Common/FormBuilder/FormBuilder';
import { isMobile } from 'react-device-detect';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { tenantGetOpenRequest } from 'store/user/action';
import { isJson } from 'utils';

interface Inputs {
    password: string;
    confirm_password: string;
}

interface ResetPasswordProps {
    t: (key: string) => string;
}

const { DTS_API_STUDENT, AWS_API } = config.api;

const AgentChangeForm: React.FC<ResetPasswordProps> = ({ t }) => {
    const { accessToken, applicationId } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const elementRef = useRef(null);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const recommendDetails: any = useSelector((state: ApplicationState) => state.authorizationletters.details);    
    const tenatDetails = useSelector((state: ApplicationState) => state.user.tenatDetails);
    const [name, setName] = useState<any>(null)
    const [companyName, setCompanyName] = useState<any>(null)
    const [confirmedList, setConfirmed] = useState<any>([]);
    const [selectedList, setSelectedList] = useState<any>([]);
    const [linktrue, setLinktrue] = useState<boolean>(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [dataReceived, setDataReceived] = useState<any>()
    const [modalOpen, setModalOpen] = useState(false);
    const [selfieData, setSelfieData] = useState<string | null>(null);
    const [verificationCode, setVerificationCode] = useState<string | null>(null);
    const [showButtons, setShowButtons] = useState(true);
    const [reason, setReason] = useState('');
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [tenant, setTenant] = useState<any>({})
    const getTargetElement: any = () => document.getElementById('form-content');

    useEffect(() => {        
        dispatch(tenantGetOpenRequest(accessToken))
    }, [])

    useEffect(() => {
        let tenant = tenatDetails?.settings && isJson(tenatDetails?.settings) ? JSON.parse(tenatDetails?.settings) : tenatDetails?.settings;
        setTenant(tenant) 
    }, [tenatDetails])

    const options: any = {
        // default is `save`
        method: 'build',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.NORMAL,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.MEDIUM,
            // default is 'A4'
            format: 'A4',
            // default is 'portrait'
            orientation: 'portrait',
        },
        canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: 'image/png',
            qualityRatio: 1
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        },
    };

    const sendBlobAsFormData = async () => {
        let pdf = await generatePDF(getTargetElement, options);
        let Url = pdf?.output("bloburl");
        const response = await fetch(Url);
        const blob = await response.blob();
        if (blob) {
            const formData = new FormData();
            formData.append('file', blob, 'authorization_form.pdf');
            formData.append('documentType', 'authorization_form');
            formData.append('studentId', recommendDetails?.valuesJson?.studentId);
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
            await axios.post(`${DTS_API_STUDENT}/documents/forms/${accessToken}`, formData, config)
                .then((res: any) => {
                    if(res.includes('http')) {
                        setSuccessModal(true)
                    }
                })
        }
    }

    const pdfPreview = () => {
            const element = document.getElementById("form-content");
            if (element) {
                setShowButtons(false);
                const opt = {
                    margin: [10, 0, 10, 0], // Top, Left, Bottom, Right margins in mm
                    filename: 'authorization_form.pdf',
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                };
                html2pdf()
                    .from(element)
                    .set(opt)
                    .toPdf()
                    .get('pdf')
                    .then(async (pdf: any) => {
                        const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                        const selfieSection = document.getElementById("selfie-section");
                        const selfieHeight = selfieSection?.clientHeight || 0;
                        if (selfieHeight >= (pageHeight - 20)) {
                            pdf.addPage(); // Add a new page if the selfie is too large
                        }
                        if (selfieSection) {
                            pdf.html(selfieSection, {
                                x: 20,
                                y: pageHeight + 300, // Place it on the next page
                                callback: function (doc: any) {
                                    doc.save('authorization_form.pdf');
                                }
                            });
                        }
                        else {
                            pdf.save('authorization_form.pdf');
                            setShowButtons(true);
                        }
                    });
            }
        };

    const handleSelfieCapture = (selfie: string, code: string) => {
        setSelfieData(selfie);
        setVerificationCode(code);
        toast.success("Selfie captured successfully!");
        toggleModal(); // Close the modal after capturing the selfie
    };

    useEffect(() => {
        const handleSuccess = () => { setDataReceived(recommendDetails) }
        const handlefailure = () => { }
        dispatch(authorizationletterGetRequest(accessToken, applicationId, handleSuccess, handlefailure))
    }, [dispatch])


    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        if (recommendDetails?.reason) {
            setReason(recommendDetails?.reason)
        }
    }, [recommendDetails])

    const handleRetakeSelfie = () => {
        setSelfieData(null); // Reset the selfie data to allow for retaking the selfie
        toggleModal(); // Open the modal to retake the selfie
    };


    const style = {
        // maxWidth: '250px',
        width: "40%",
        whiteSpace: "normal"
    }

    const stylevalue = {
        width: "60%",
        whiteSpace: "normal"
    }

     let successComponent =  <div className="mt-4 text-center">
        <h4 className="mb-3">Thank you..!</h4>
        <p className="text-muted mb-4">Your Form submited successfully.</p>
        <p className="text-muted mb-4">If you want to download a copy of form please click on download button.</p>
        <div className="hstack gap-2 justify-content-center">                            
            {/* <Button color='danger' onClick={() => setSuccessModal(false)}>Close</Button> */}
            <Button color='primary' onClick={() => pdfPreview()}>Download</Button>
        </div>
    </div>
    return (
        <>
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>Capture Selfie</ModalHeader>
                <ModalBody>
                    <InPersonVerify onSelfieCapture={handleSelfieCapture} />
                </ModalBody>
            </Modal>
            <Modal
                isOpen={successModal}
                backdrop={'static'}
                id="staticBackdrop"
                centered
            >
                <ModalHeader className='hstack justify-content-between' toggle={() => setSuccessModal(!successModal)}>
                    <h5 className="modal-title" id="staticBackdropLabel"></h5>
                </ModalHeader>
                <ModalBody className="text-center p-5">
                    <div className="mt-4">
                        <h4 className="mb-3">Thank you..!</h4>
                        <p className="text-muted mb-4">Your Form submitedd successfully.</p>
                        <p className="text-muted mb-4">If you want to download a copy of form please click on download button.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color='danger' onClick={() => setSuccessModal(false)}>Close</Button>
                            <Button color='primary' onClick={() => pdfPreview()}>Download</Button>
                       </div>
                    </div>
                </ModalBody>
            </Modal>
            <ToastContainer />
            {
                isConfirm ?
                    <RecommendSuccess tenant={tenant} children={successComponent} />
                    :
                    <Row id="form-content" className="px-3 justify-content-center">
                        <Col xs={12} sm={12} lg={8} md={8} xl={8} className="justify-content-center">
                            <Row className="justify-content-center">
                                <Col >
                                    <Card>
                                        <div className="bg-info-subtle position-relative">
                                            <CardBody className="card-body p-5">
                                                <div className="text-center">
                                                    <h3>Authorization Form</h3>
                                                </div>
                                            </CardBody>
                                            <div className="shape">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    version="1.1"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    xmlns-svgjs="http://svgjs.com/svgjs"
                                                    width="1440"
                                                    height="60"
                                                    preserveAspectRatio="none"
                                                    viewBox="0 0 1440 60"
                                                >
                                                    <g mask='url("#SvgjsMask1001")' fill="none">
                                                        <path
                                                            d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                                                            style={{ fill: "var(--vz-secondary-bg)" }}
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <mask id="SvgjsMask1001">
                                                            <rect width="1440" height="60" fill="#ffffff"></rect>
                                                        </mask>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <CardBody className="card-body p-2">
                                            <div className="d-flex justify-content-end">
                                                <div>
                                                    <Label>Date: {moment().format("MM/DD/YYYY")}</Label>
                                                </div>
                                            </div>
                                            <Table responsive bordered className="table align-middle position-relative table-nowrap border border-1">
                                                <tbody>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>First Name</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>{recommendDetails?.valuesJson?.first_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Last Name</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>{recommendDetails?.valuesJson?.last_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Date of Birth:</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>{recommendDetails?.valuesJson?.date_of_birth ? moment(recommendDetails?.valuesJson?.date_of_birth).format("MMM DD YYYY") : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Country of Nationality:</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>{recommendDetails?.valuesJson?.country_of_nationality?.label || recommendDetails?.valuesJson?.country_of_nationality}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%",  whiteSpace: "wrap"  }}>University application reference Number/UCAS ID</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>{recommendDetails?.valuesJson?.ucas_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Course Applied</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%",  whiteSpace: "wrap"  }}>{recommendDetails?.valuesJson?.course_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Date Application Submitted</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>
                                                            {recommendDetails?.createdAt ? moment(recommendDetails?.createdAt).format("DD/MMM/YYYY") : ""}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Your Agency</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>{
                                                            recommendDetails?.valuesJson?.channel_name?.name || recommendDetails?.valuesJson?.channel_name?.label ? recommendDetails?.valuesJson?.channel_name?.label || recommendDetails?.valuesJson?.channel_name?.name || recommendDetails?.valuesJson?.channel_name : ""
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Reason(s) for taking assistance:</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>
                                                            {recommendDetails?.valuesJson?.reason ? recommendDetails?.valuesJson?.reason
                                                                :
                                                                <FormBuilder
                                                                    type={"textarea"}
                                                                    defaultValue={reason}
                                                                    value={reason}
                                                                    name={"reason"}
                                                                    placeholder={"Enter Reason(s) here..."}
                                                                    className="w-100 h-100"
                                                                    onChange={(value: any) => setReason(value)}
                                                                    isEditState={true}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                                <div className='d-flex justify-content-start'>
                                    <div className="">
                                        <h5>Authorization Instructions</h5>
                                        <p>To complete your verification process, we just need you to confirm your identity with a quick selfie.</p>

                                        <h5>How to proceed:</h5>
                                        <ol>
                                            <li>Click on the <strong>"Capture Selfie"</strong> button below.</li>
                                            <li>Ensure you are in a well-lit environment for clear visibility.</li>
                                            <li>Write the <strong>verification code</strong> provided to you on a piece of paper.</li>
                                            <li>Hold the paper with the verification code <strong>clearly visible below your face</strong> while taking the selfie.</li>
                                            <li>Align your face within the frame and click the button to capture your selfie.</li>
                                            <li>Once the selfie is taken, it will be automatically uploaded for verification.</li>
                                        </ol>
                                        <br></br>
                                        <p>Your privacy and security are important to us. The selfie and verification code will only be used for verification purposes.</p>

                                        <p>Thank you for your cooperation!</p>
                                    </div>

                                </div>
                            </Row>
                        </Col>
                        <br />
                        {selfieData && (
                            <div id="selfie-section" className="mt-4 text-center">
                                <div>
                                    <Label>Verification Code: {verificationCode}</Label>
                                </div>
                                <img
                                    src={selfieData}
                                    alt="Captured Selfie"
                                    style={{ maxWidth: "100%", maxHeight: "300px", borderRadius: "10px" }}
                                />
                            </div>
                        )}
                        <div className="text-center pb-3">
                            {showButtons ? (
                                <>
                                    {selfieData ? (
                                        <div className='hstack p-2 gap-2 justify-content-center'>
                                            <Button onClick={handleRetakeSelfie}>Retake Selfie</Button>
                                            <Button color="primary" onClick={sendBlobAsFormData}>
                                                Submit
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button onClick={toggleModal}>Capture Selfie</Button>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </Row>
            }
        </>
    );
};

export default withTranslation()(AgentChangeForm);