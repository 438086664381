import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import conditionsJson from "../Common/Config/MainPanel/ControlPanel/Filter/conditionsJson.json";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { reportGenerateRequest } from "store/report/action";
import { GeneratedReport } from "store/report/types";
import { Label, Button } from "reactstrap";
import ChartLoader from "../Common/ChartLoader";
import processFilterDateType from "helpers/filter_datetime";
import { getStatusByLabel } from "helpers/workflowStatusHelper";

interface ReportProps {
    config: any;
    onDetailsReport: (data: any) => void;
}

const PieReport: React.FC<ReportProps> = ({ config, onDetailsReport }) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [option, setOption]: any = useState(null);
    const [report, setReport] = useState<GeneratedReport | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    
    useEffect(() => {
        if(config) {
            generateReport();
        }
    }, [config])

    const generateReport = () => {
        setLoading(true);
        setError(false);

        const updatedConfig = {
            ...config,
            filters: processFilterDateType(config.filters),
        };
        const handleSuccess = (response: GeneratedReport) => {
            setLoading(false);
            setError(false);
            setReport(response);
        }
        const handleError = () => {
            setLoading(false);
            setError(true);
        }
        dispatch(reportGenerateRequest(updatedConfig, handleSuccess, handleError))
    }
    
    useEffect(() => {
        if(report?.series && !report?.xAxis) {
            const option = {
                tooltip: {
                  trigger: 'item'
                },
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    top: 0,
                    left: 'center',
                    itemWidth: 14,
                    itemHeight: 14,
                    textStyle: {
                        fontSize: 12,
                    },
                    pageIconSize: 12,
                    pageTextStyle: {
                        fontSize: 12,
                    }
                },
                series: report?.series || []
            };
            setOption(option);
        }
    }, [config, report])
    
    const onChartClick = (params: any) => {
        const groupBy = config?.group_by;
        
        // Handle groupBy filter
        if (groupBy) {
            const conditionOptions = conditionsJson[groupBy?.type as keyof typeof conditionsJson];
            let values: any = [], valuesLabel: any = [];
            let { value: condition, label: conditionLabel } = conditionOptions[0];
            const groupName = params?.name || 'No Group';

            if (params?.label === 'Other') {
                condition = 'IS_NULL';
                conditionLabel = 'is null';
                values = [];
                valuesLabel = [{ label: 'No Group', value: 'No Group' }];
            } else {
                if (params?.data?.id) {
                    values = [params?.data?.id];
                    valuesLabel = [{ label: groupName, value: params?.data?.id }];
                } else {
                    if(groupBy?.process_flows?.length) {
                        const status = getStatusByLabel(groupBy?.process_flows, groupName);
                        values = [status?.value];
                        valuesLabel = [{label: status?.label, value: status?.value}];
                    } else if(groupBy?.type === 'select') {
                        const option = groupBy?.options.find((opt: any) => opt?.label === groupName);
                        values = [option?.value];
                        valuesLabel = [{label: option?.label, value: option?.value}];
                    } else {
                        values = [groupName];
                        valuesLabel = [{label: groupName, value: groupName}];
                    }
                }
            }
    
            const filter = {
                key: groupBy.key,
                keyLabel: groupBy.label,
                condition: condition,
                conditionLabel: conditionLabel,
                values: values,
                valuesLabel: valuesLabel,
                property: groupBy,
                data_source: groupBy.data_source,
                json_field: groupBy.json_field,
                detail_field: true,
            };
            onDetailsReport(filter);
        } else {
            onDetailsReport({ key: 'all' });
        }
    };    

    const onEvents = {
        'click': onChartClick,
    };

    if(loading) {
        return (
            <ChartLoader />
        )
    }

    if(error) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100 p-5 gap-3">
                <Label className="fw-semibold text-danger fs-5">This report couldn't load. Please refresh or try again later.</Label>
                <Button
                    size="sm"
                    onClick={() => generateReport()}
                    color="primary"
                    className="btn-label">
                        <i className="ri-refresh-line label-icon align-middle fs-16 me-2"></i>
                        Refresh
                </Button>
            </div>
        )
    }

    if(option) {
        return (
            <ReactEcharts
                style={{ height: "100%", width: "100%" }}
                option={option}
                notMerge={true}
                onEvents={onEvents}
            />
        )
    }
    return null;
};

export default PieReport;
