
// Function to set start of day and end of day for given date
const setStartOfDay = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    return date.toISOString();
};

const setEndOfDay = (date: Date) => {
    date.setHours(23, 59, 59, 999);
    return date.toISOString();
};

// Function to process dateType and set value/high_value
const processFilterDateType = (filters: any[]) => {
    return filters.map(filter => {
        if (filter.dateType) {
            const today = new Date();
            let value = null;
            let high_value = null;

            switch (filter.dateType.value) {
                case 'today':
                    value = setStartOfDay(today);
                    high_value = setEndOfDay(today);
                    break;
                case 'yesterday':
                    const yesterday = new Date(today);
                    yesterday.setDate(today.getDate() - 1);
                    value = setStartOfDay(yesterday);
                    high_value = setEndOfDay(yesterday);
                    break;
                case 'this_week':
                    const startOfWeek = new Date(today);
                    startOfWeek.setDate(today.getDate() - today.getDay());
                    value = setStartOfDay(startOfWeek);
                    high_value = setEndOfDay(today);
                    break;
                case 'last_week':
                    const startOfLastWeek = new Date(today);
                    startOfLastWeek.setDate(today.getDate() - today.getDay() - 7);
                    value = setStartOfDay(startOfLastWeek);
                    const endOfLastWeek = new Date(startOfLastWeek);
                    endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
                    high_value = setEndOfDay(endOfLastWeek);
                    break;
                case 'this_month':
                    value = setStartOfDay(new Date(today.getFullYear(), today.getMonth(), 1));
                    high_value = setEndOfDay(new Date(today.getFullYear(), today.getMonth() + 1, 0));
                    break;
                case 'last_month':
                    value = setStartOfDay(new Date(today.getFullYear(), today.getMonth() - 1, 1));
                    high_value = setEndOfDay(new Date(today.getFullYear(), today.getMonth(), 0));
                    break;
                case 'specific_date':
                    value = setStartOfDay(new Date(filter.value));
                    high_value = setEndOfDay(new Date(filter.high_value || filter.value));
                    break;
                default:
                    value = filter.value;
                    high_value = filter.high_value;
                    break;
            }

            return { ...filter, value, high_value };
        }
        return filter;
    });
};

export default processFilterDateType;