import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Form,
    Row,
    TabContent,
    TabPane,
    Spinner
} from "reactstrap";

import Step1 from './step1'
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";
import Step7 from "./step7";
import Step8 from "./step8";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/dtsimg/svg/zilter_logo_blue.svg"
// import logo from "../../../assets/images/dtsimg/svg/zilter_logo_white.svg";
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { signupRequest, otpVerify, updateRequest, setResponse, userGetRequest } from "store/auth/action";
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import { ApplicationState } from "store";
import { resendOtp } from "store/auth/action";
import _ from "lodash";
import config from "../../../config";
import { jwtDecode } from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";
import { postGoogleCalendarSync } from "store/calendarSync/action";
import * as msal from '@azure/msal-browser';
import { isJson } from "utils";
const { api } = config;
const url = api.url;

interface SignUpState {
    email: string;
    otp: string;
    full_name: string;
    phone_number: string;
    company_name: string;
    company_website: string;
    password: string;
    people_count: string;
    countrycode: any;
}

interface FormWizardProps {
    t: (key: string) => string;
    // Add other prop types as needed
}

const FormWizard: React.FC<FormWizardProps> = ({ t }) => {
    const auth = useSelector((state: ApplicationState) => state.auth);
    const [isInitialValue, setIsInitialValue] = useState<boolean>(false);
    const [isEditEmail, setIsEditEmail] = useState<boolean>(false);
    const { userProfile, loading } = auth;
    const [activeArrowTab, setactiveArrowTab] = useState(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [userData, setUserData] = useState(userProfile);
    const [userid, setUserId] = useState("");
    const navigate = useNavigate();
    const [disableBack, setDisableBack] = useState(false)
    const [inputs, setInputs] = useState<SignUpState>({
        email: '',
        otp: '',
        full_name: '',
        phone_number: '',
        company_name: '',
        company_website: '',
        password: '',
        people_count: '',
        countrycode: '',
    });

    const msalConfig: any = {
        auth: {
            clientId: "9dce3457-ea35-4f69-9c96-aecbda169c12",
            authority: "https://login.microsoftonline.com/common",
            redirectUri: `${url}`
        }
    };

    const onCreate = (): void => {
        const data = {
            "attributes": [
                {
                    "key": "email",
                    "value": inputs.email,
                }
            ]
        }
        const handleSuccess = (body: any): void => {
            dispatch(setResponse(body));
            toggleArrowTab(activeArrowTab + 1)
        }
        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        }
        dispatch(signupRequest(data, handleSuccess, handleError));
    }
    const signUpGoogle = useGoogleLogin({
        onSuccess: codeResponse => {
            handleGoogleSignup(codeResponse.code, "google");
        },
        onError: (error) => console.log('Login Failed:', error),
        flow: 'auth-code',
    });

    const handleGoogleSignup = (code: any, provider: any, scope?: any) => {
        const data =
        {
            code: code,
            scope: provider === 'microsoft' ? "email profile openid https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.Read" : "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly",
            provider: provider,
            isSignup: 'true'
        }
        const handleSuccess = (body: any): void => {
            if (body?.token) {
                localStorage.setItem('dts_token', JSON.stringify(body.token));
                setDisableBack(true)
                toggleArrowTab(5);
                const userData: any = jwtDecode(body.token);
                setUserData(userData)
                setUserId(userData.user_id)

            }
            else {
                const message = () => toast(body?.status, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                message();

            }
        };
        const handleError = (body: any): void => {
            const message = () => toast(body?.status, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        };
        dispatch(postGoogleCalendarSync(data, handleSuccess, handleError));
    };


    const msalInstance: any = new msal.PublicClientApplication(msalConfig);

    async function initializeMsal() {
        await msalInstance.initialize(); // Ensure MSAL is initialized
    }

    async function signUpMicrosoft() {
        // try {
        await initializeMsal();
        const loginRequest = {
            scopes: [
                "User.Read",            // Basic profile information
                "Calendars.ReadWrite",  // Read and write access to the user's calendar
                "Mail.ReadWrite"        // Read and write access to the user's email
            ],
            prompt: 'select_account',
        };
        const response = await msalInstance.loginPopup(loginRequest);
        if (response.idToken) {
            const accessToken = response.idToken;
            handleGoogleSignup(accessToken, 'microsoft')
        }
    }


    const OnUpdate = (attributes: any): void => {
        const data = {
            "attributes": attributes
        }
        const handleSuccess = (body: any): void => {
            toggleArrowTab(activeArrowTab + 1)
        }
        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        }
        dispatch(updateRequest(userid, data, handleSuccess, handleError));
    }

    const verifyOtp = () => {
        const data = {
            "attributes": [
                {
                    "key": "otp",
                    "value": inputs.otp
                }
            ]
        }
        const handleSuccess = (body: any): void => {
            // dispatch(userGetRequest()); 
            const userData: any = jwtDecode(body.token);
            setUserData(userData)
            setUserId(userData.user_id)
            if (userData && parseInt(userData.current_stage) > 3 && parseInt(userData.current_stage) < 8) {
                toggleArrowTab(parseInt(userData.current_stage))
            }
            else if (userData && parseInt(userData.current_stage) < 3) {
                toggleArrowTab(activeArrowTab + 1)
            }
            else toggleArrowTab(activeArrowTab + 1)
        }
        const handleError = (body: any): void => {
            const message = () => toast('You have entered invalid OTP', { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        }
        dispatch(otpVerify(data, handleSuccess, handleError));
    }

    const resendOTP = (): void => {
        const data = {
            "attributes": [
                {
                    "key": "email",
                    "value": inputs.email,
                }
            ]
        }
        const handleSuccess = (body: any): void => {
            const message = () => toast(`OTP resent successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            if (isEditEmail) {
                toggleArrowTab(activeArrowTab + 1)
                setIsEditEmail(false)
            }
            dispatch(setResponse(body));
        }
        const handleError = (body: any): void => {
            const message = () => toast(`Please enter valid email`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        }
        dispatch(resendOtp(data, handleSuccess, handleError));
    }

    const toggleArrowTab = (tab: any): void => {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 10) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }

    useEffect(() => {
        const { userProfile } = auth;
        if (userProfile && parseInt(userProfile.currentStage) > 3 && parseInt(userProfile.currentStage) < 9) {
            setUserId(userProfile.id)
            let details = {
                email: userProfile.email,
                name: userProfile.name,
                phonenumber: userProfile.phoneNumber,
                companyname: userProfile.tenant.companyName,
                companywebsite: userProfile.tenant.website,
                peoplecount: userProfile.tenant.noOfEmployees
            }
            setInputs({
                ...inputs,
                email: userProfile.email,
                full_name: userProfile.name,
                phone_number: userProfile.phoneNumber,
                company_name: userProfile.tenant.companyName,
                company_website: userProfile.tenant.website,
                people_count: userProfile.tenant.noOfEmployees
            })
            navigate(`/signup/`);
            toggleArrowTab(parseInt(userProfile.currentStage))
        }
        else if (userProfile && parseInt(userProfile.currentStage) == 9) {
            navigate('/students')
        }
        if (userProfile && parseInt(userProfile.currentStage) == 8) {
            let subscription = userProfile?.subscription && isJson(userProfile?.subscription) ? JSON.parse(userProfile?.subscription) : userProfile?.subscription
            if (subscription?.status && subscription?.status == "paid") navigate('/students')
        }
        else toggleArrowTab(1)
    }, [])
    
    return (
        <React.Fragment>
            <ToastContainer />
            <Row className="hstack justify-content-center mt-4 h-100">
                <Col xl={12} className="h-100">
                    <Row className="hstack m-2">
                        <Col lg={12}>
                            <Row className="text-center justify-content-between">
                                <Col xl={6} lg={6} md={6} sm={11} xs={11} className="hstack justify-content-start">
                                    <Link to={(userProfile && userProfile.currentStage == undefined) ? "/signin" : "/students"} className="d-inline-block auth-logo">
                                        <img src={logo} alt="" height={36} width={128} />
                                    </Link>
                                </Col>
                                {
                                    activeArrowTab === 1 ?
                                        <Col xl={6} lg={6} md={6} sm={11} xs={11} className="fs-17 hstack justify-content-end">
                                            {t("signup.have_an_account")}?&nbsp;
                                            <Link to="/signin" className="link-primary fw-bold">{t("signup.sign_in")}</Link>
                                        </Col> : null
                                }
                            </Row>
                        </Col>
                        <Col lg={12}>
                            <Row className="mt-4 text-center justify-content-between">
                                {disableBack === true || activeArrowTab < 5 || activeArrowTab > 7 ? null :
                                    <Col lg={2} md={2} className="hstack justify-content-start">
                                        <button type="button" className="w-50 btn btn-success btn-icon waves-effect waves-light" onClick={() => toggleArrowTab(activeArrowTab - 1)} disabled={activeArrowTab === 1}>
                                            <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>&nbsp;{t("signup.back")}</button>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Form className="">
                        <div className="step-arrow-nav m-4">
                        </div>
                        <TabContent activeTab={activeArrowTab} className="mt-4">
                            <TabPane id="steparrow-gen-info" tabId={1}>
                                {activeArrowTab == 1 && <Step1
                                    toggleArrowTab={() => toggleArrowTab(activeArrowTab + 1)}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    onCreate={() => onCreate()}
                                    resendOTP={() => resendOTP()}
                                    loading={loading}
                                    isEditEmail={isEditEmail}
                                    signUpGoogle={signUpGoogle}
                                    signUpMicrosoft={signUpMicrosoft}
                                    t={t}
                                />}
                            </TabPane>
                            <TabPane id="steparrow-description-info" tabId={2}>
                                {activeArrowTab == 2 && <Step2
                                    next={() => toggleArrowTab(activeArrowTab + 1)}
                                    back={() => toggleArrowTab(activeArrowTab - 1)}
                                    setIsEditEmail={setIsEditEmail}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    t={t}
                                    onCreate={onCreate}
                                    verifyOtp={verifyOtp}
                                    loading={loading}
                                />}
                            </TabPane>
                            <TabPane id="steparrow-description-info" tabId={3}>
                                {activeArrowTab == 3 && <Step3
                                    toggleArrowTab={() => {
                                        toggleArrowTab(activeArrowTab + 1);
                                    }}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    t={t}
                                    onCreate={onCreate}
                                    OnUpdate={OnUpdate}
                                    loading={loading}
                                />}
                            </TabPane>
                            <TabPane id="steparrow-description-info" tabId={4}>
                                {activeArrowTab == 4 && <Step4
                                    toggleArrowTab={() => {
                                        toggleArrowTab(activeArrowTab + 1);
                                    }}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    t={t}
                                    onCreate={onCreate}
                                    OnUpdate={OnUpdate}
                                    loading={loading}
                                    isInitialValue={isInitialValue}
                                    setIsInitialValue={setIsInitialValue}
                                />}
                            </TabPane>
                            <TabPane id="steparrow-description-info" tabId={5}>
                                {activeArrowTab == 5 && <Step5
                                    toggleArrowTab={() => {
                                        toggleArrowTab(activeArrowTab + 1);
                                    }}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    t={t}
                                    onCreate={onCreate}
                                    OnUpdate={OnUpdate}
                                    loading={loading}
                                    isInitialValue={isInitialValue}
                                />}
                            </TabPane>
                            <TabPane id="steparrow-description-info" tabId={6}>
                                {activeArrowTab == 6 && <Step6
                                    toggleArrowTab={() => {
                                        toggleArrowTab(activeArrowTab + 1);
                                    }}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    t={t}
                                    onCreate={onCreate}
                                    OnUpdate={OnUpdate}
                                    loading={loading}
                                />}
                            </TabPane>
                            <TabPane id="steparrow-description-info" tabId={7}>
                                {activeArrowTab == 7 && <Step7
                                    toggleArrowTab={() => {
                                        toggleArrowTab(activeArrowTab + 1);
                                    }}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    t={t}
                                    onCreate={onCreate}
                                    OnUpdate={OnUpdate}
                                    loading={loading}
                                    isInitialValue={isInitialValue}
                                />}
                            </TabPane>
                            <TabPane id="pills-pricing" tabId={8}>
                                {activeArrowTab == 8 && <Step8 userid={userid} />}
                            </TabPane>
                        </TabContent>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default withTranslation()(FormWizard);
