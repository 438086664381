import { useEffect, useRef, useState } from "react";
import {
    Label,
    Input,
    Form,
    Button,
    FormFeedback,
    ButtonGroup,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";

import config from "../../../config";
import { documentDownload } from "store/documents/action";
import { useDispatch } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";

const { api } = config;

const AWS_API = api.AWS_API

interface DtsTextInputProps {
    label?: string;
    type?: string;
    value: string;
    placeholder?: string;
    onChange: (value: string, str: string) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    onSaveClick?: (value?: string) => void;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    isTextract?: any;
}

const DtsFileInput: React.FC<DtsTextInputProps> = ({ isTextract, formerror, setError, setdisabled, onSaveClick, rest, defaultValue, label, type, value, placeholder, onChange, name ,icon, iconPosition, actionButtons, readOnly, disabled}: any) => {
    
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [hover, setHover] = useState<any>(false)
    const [checkdisabled, setCheckdisabled] = useState<any>(false)
    const [errormsg, setErrormsg] = useState();
	const [data, setData] = useState({});
	const [file, setFile] = useState(null);
    const inputRef = useRef<HTMLInputElement>(null);
    // const onhandleChange = (e: any) => {
    //     if (!e.target.files || e.target.files.length === 0) {
	// 		return;
	// 	}
	// 	const file = e.target.files[0];
    //     onChange(file)
    //     validateInput(file);
	// 	setFile(file)
    //     if(isTextract) onRunOCR()
    // }

    const onhandleChange = (e: any) => {
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}
		const file = e.target.files[0];
		setFile(file)
        validateInput(file);
        if(isTextract){
            onRunOCR(file)
        }
        else {            
            onChange(file)
        }
	};

    

    const validateInput = (value: any) => { 
        if(rest && rest.type == 'email') {
            if (value === '' || !/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                let error: any =  `Please enter valid ${label}`;
                setErrormsg(error)                
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.required) {
            if(value === "") {
               let error: any =  `Please enter ${label}`;
                setErrormsg(error)                                
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.minLength) {
            if(value.length < rest.validate.minLength) {
                let error: any =  `Length of ${label} must be atleast ${rest.validate.minLength}`;
                setErrormsg(error)                                
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        if(rest && rest.validate && rest.validate.maxLength) {   
           if(value.length > rest.validate.maxLength) {
                let error: any =  `Length of ${label} must be not more than ${rest.validate.maxLength}`;
                setErrormsg(error)                                
                if (typeof setError === 'function') {
                    setError({...formerror, [name] : error})
                }
                setCheckdisabled(true)
                return false
            }
        }
        setErrormsg(undefined)                             
        if (typeof setError === 'function') {
            const {[name]: _, ...newObject } = formerror;   
            setError(newObject)
        }
        setCheckdisabled(false)
        return true
    }

    useEffect(() => {
        if(rest && rest.validate) {           
            if(errormsg)
            { 
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    const onCheckClick = (e: any) => {
        e.preventDefault();
        setHover(false)
        onSaveClick("file")
    }

    useEffect(() => {
        if(rest && rest.validate && rest.validate.required) { 
            const feedback: any = document.getElementById(`starred${name}`);
            if(feedback) feedback.classList.remove('d-none');
        }
    },[rest])

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (inputRef && inputRef.current && !inputRef.current.contains(event.target)) {
                setHover(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRef]);

    const onRunOCR = async (newfile: any) => {
		if (!newfile) {
		  alert('Please select a file to upload.');
		  return;
		}
	
		const formData = new FormData();
		formData.append('file', newfile);
	
		try {
		  const response = await fetch(`${AWS_API}/quicksight/upload`, {
			method: 'POST',
			body: formData,
		  });
	
		  if (!response.ok) {
			throw new Error('Failed to upload file.');
		  }
	
		  const data = await response.json();
          let normalizedData = Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key.replace(/:$/, ''), value])
        );
		  setData(normalizedData);
          onChange(normalizedData, "isTextract")
		} catch (error) {
		  setData({});
		//   alert('Error uploading file. Please try again.');
		}
	  };

    const onClickFileDownload = (documentKey: any, key: any) => {           
        const handleSuccess = async (body: any) => {
            try {
                // Create a Blob from the response data
                const fileBlob = await body.blob();
    
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);
    
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", documentKey); // Set the desired filename for the downloaded file
    
                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();
    
                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () => {
    
        }
        dispatch(documentDownload(documentKey, handleSuccess, handleFailure))
    }
    
    return (        
        <div className="pb-3"> 
            {
                actionButtons ?
                <div>
                    { hover ?
                    <div>
                        {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}
                        <div className="d-flex border rounded-3 align-items-center">
                            <Input type={type} className="form-control border-0" id={`dts${name}`} placeholder={placeholder} onChange={(e: any) => onhandleChange(e)} readOnly={readOnly} disabled={disabled} />
                            <ButtonGroup>
                                <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(defaultValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button>
                                <Button onClick={(e) => {onCheckClick(e)}} disabled={checkdisabled} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                            </ButtonGroup>
                        </div>
                        {<FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>}                     
                    </div>
                    : <div>
                        {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-0">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}              
                        <div className="hstack justify-content-between topbar-user">
                            <div className="fs-14 fw-medium form-control border-0 topbar-user text-nowrap text-truncate text-nowrap-ellipsis" >
                                {value && typeof value !== 'object' ?
                                 <div className='ms-3'>
                                    <FeatherIcon icon="download" className="icon-xs text-primary cursor-pointer" onClick={() => onClickFileDownload(value, `${rest.key}`)} />
                                </div> : null
                                }
                            </div>
                            {
                                readOnly ? null
                                : <Button onClick={() => setHover(true)} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-edit-2-fill" /> </Button>
                            }
                        </div>
                    </div>
                    }
                </div>
            :            
                <div>
                    {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}
                    <Input {...rest} type={type} className="form-control" id={`dts${name}`} placeholder={placeholder}  onChange={(e: any) => onhandleChange(e)} readOnly={readOnly} disabled={disabled} />
                    {<FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>}
                </div>
            }
        </div>
    );
};

export default DtsFileInput


