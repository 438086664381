import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
    Card,
    CardBody,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Col,
    Button,
    Badge,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Flatpickr from "react-flatpickr";
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";
import UpcommingEvents from './UpcommingEvents';
import listPlugin from '@fullcalendar/list';
import { createGoogleCalendarEvent, deleteGoogleEvent, getGoogleCalendarData, getGoogleCalendarEvents } from "store/calendarSync/action";
import { ApplicationState } from "store";
import moment from "moment";
import DtsEmailsInputs from "Components/Common/FormBuilder/DtsEmailsInputs";
import { withTranslation } from "react-i18next";
import { studentGetRequest } from "store/student/action";
import DeleteModal from "../Views/innerComponent/DeleteModal";
import ConnectAccount from "./ConnectAccount";
import { toast } from "react-toastify";
import { meetingsCreateRequest, meetingsListGetRequest, meetingssMetadataGetRequest, meetingsUpdateRequest } from "store/meetings/action";
import { isJson } from "utils";
import AddEntryModal from "../Activities/Meetings/innerComponent/AddEntryModal";

const BYDAY: any = { "SU": 0, "MO": 1, "TU": 2, "WE": 3, "TH": 4, "FR": 5, "SA": 6 }
export interface SuggestionOption {
    readonly value: string;
    readonly label: string;
}

interface PostEvent {
    summary: string;
    location: string;
    description: string;
    eventDate: Date | null;
    startDateTime: Date | null;
    endDateTime: Date | null;
    attendees: string[];
}

// Function to check if the required fields are empty
const checkObjectEmpty = (obj: PostEvent) => {
    // Destructure to exclude 'description'
    const { description, ...rest } = obj;

    // Check if any of the remaining fields are empty, null, or undefined
    return Object.values(rest).some(value => value === null || value === undefined || value === '');
};

const Meetings = ({props, show, communicate, info, model, onCloseClick, dataFields, setTriggerApi, createAction, parent, parentId, to}: any) => {
    const { id } = useParams();
    // const { communicate } = props;
    // const { info } = props;
    // const { model } = props;
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const dispatch: any = useDispatch();
    const googleConnect = useSelector((state: ApplicationState) => state.calendarSync.data);
    const eventData = useSelector((state: ApplicationState) => state.calendarSync?.events);
    const eventDataModel: any = useSelector((state: ApplicationState) => state.meetings.list);
    const meetingMetaData: any = useSelector((state: ApplicationState) => state.meetings.metaDataFields);
    const [event, setEvent] = useState<any>({});
    const [modal, setModal] = useState<boolean>(false);
    const [selectedNewDay, setSelectedNewDay] = useState(moment().format())
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isEditButton, setIsEditButton] = useState<boolean>(true);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [newStartTime, setNewStartTime] = useState(moment().add(30, 'minutes').format());
    const [newEndTime, setNewEndTime] = useState(moment(newStartTime).add(60, 'minutes').format());
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [metaData, setMetaData] = useState<any>();
    const [statusOptions, setStatusOptions] = useState<{ label: string; value: string }[]>([]);
    const [detailsModal, setDetailsModal] = useState<any>(false);
    const [emailData, setEmailData] = useState<any>({
        to: info?.email || info?.student?.email
    })
    const [postEvent, setPostEvent] = useState<PostEvent>({
        summary: "",
        location: "",
        description: "",
        eventDate: selectedNewDay,
        startDateTime: moment().add(30, 'minutes').format(),
        endDateTime: moment(moment().add(30, 'minutes').format()).add(60, 'minutes').format(),
        attendees: [info.email || info?.student?.email]
    })

        useEffect(() => {
            let filterObject = {pageSize: 500}
            dispatch(meetingsListGetRequest())
             dispatch(meetingssMetadataGetRequest(filterObject))
        }, [modal])
        
        useEffect(() => {
            dispatch(getGoogleCalendarData())
            dispatch(meetingsListGetRequest())
        }, [])

    useEffect(() => {
        if (model == "students") {
            if (info.email) {
                setEmailData((prevState: any) => ({
                    ...prevState,
                    to: info.email
                }));
                setPostEvent(prevState => ({
                    ...prevState,
                    attendees: [info.email]
                }));
            }
        }
        else {
            if (info?.student?.email) {
                setEmailData((prevState: any) => ({
                    ...prevState,
                    to: info.student.email
                }));
                setPostEvent(prevState => ({
                    ...prevState,
                    attendees: [info.student.email]
                }));
            }
        }
    }, [info, model]);

    useEffect(() => {
        dispatch(studentGetRequest(id))
    }, [id])

    const events: any = [];
    eventDataModel?.forEach((meeting: { id: any; valuesJson: any; }) => {
        const { id, valuesJson } = meeting;
        const {
            status,
            start_time,
            end_time,
            location,
            event_name,
            description,
            meeting_type,
            invitee_email,
            location_address,
            link
        } = valuesJson || {};

        // Skip meetings without status or start_time
        if (!status || !start_time) {
            console.warn(`Meeting with ID ${id} is missing status or start time. Skipping.`);
            return;
        }

        events.push({
            id,
            title: event_name,
            description,
            location: link ? link : location_address,
            start: new Date(start_time).toISOString(),
            end: end_time ? new Date(end_time).toISOString() : null,
            className: "bg-info-subtle",
            meetingType: meeting_type,
            invitee: invitee_email?.email || "N/A",
            status: status || ""
        });
    });
    eventData?.forEach((event, index) => {
        const { id, summary, start, end, hangoutLink, recurrence, recurringEventId, location, description, status, location_address, link }: any = event;

        // Skip cancelled events
        if (status === "cancelled") return;

        // Safely extract start and end values
        const startTime = start?.dateTime?.value;
        const startTimeZoneShift = start?.dateTime?.timeZoneShift;
        const endTime = end?.dateTime?.value;
        const endTimeZoneShift = end?.dateTime?.timeZoneShift;

        if (!startTime) {
            console.warn(`Event with ID ${id} is missing a start time. Skipping.`);
            return;
        }

        if ((recurrence && recurrence.length > 0) || recurringEventId) {
            if (recurrence && recurrence.length > 0) {
                const rule = recurrence[0]?.split(';').reduce((acc: any, item: string) => {
                    const [key, value] = item.split('=');
                    acc[key] = value;
                    return acc;
                }, {});
                if (rule && rule['RRULE:FREQ'] === 'WEEKLY') {
                    const byday = rule?.BYDAY.split(',').map((item: string) => {
                        return BYDAY[item]
                    })
                    events.push({
                        id: id,
                        location: hangoutLink ? hangoutLink : location_address,
                        title: summary,
                        description: description,
                        daysOfWeek: byday,
                        startTime: startTimeZoneShift ? new Date(startTime + startTimeZoneShift * 60 * 1000).toISOString().split("T")[1] : new Date(startTime).toISOString().split("T")[1],
                        endTime: endTimeZoneShift ? new Date(endTime + endTimeZoneShift * 60 * 1000).toISOString().split("T")[1] : new Date(endTime).toISOString().split("T")[1],
                        endRecur: rule.UNTIL,
                        className: "bg-info-subtle",
                        meetingLink: hangoutLink,
                        status: status || ""
                    });
                } else {
                    events.push({
                        id: id,
                        title: summary,
                        description: description,
                        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                        location: hangoutLink ? hangoutLink : location_address,
                        startTime: startTimeZoneShift ? new Date(startTime + startTimeZoneShift * 60 * 1000).toISOString().split("T")[1] : new Date(startTime).toISOString().split("T")[1],
                        endTime: endTime ? endTimeZoneShift ? new Date(endTime + endTimeZoneShift * 60 * 1000).toISOString().split("T")[1] : new Date(endTime).toISOString().split("T")[1] : null,
                        endRecur: rule.UNTIL,
                        className: "bg-info-subtle",
                        meetingLink: hangoutLink,
                        status: status || ""
                    });
                }
            }
        } else {
            events.push({
                id: id,
                title: summary,
                description: description,
                daysOfWeek: null,
                location: hangoutLink ? hangoutLink : location_address,
                start: new Date(startTime).toISOString(),
                end: endTime ? new Date(endTime).toISOString() : null,
                className: "bg-info-subtle",
                meetingLink: hangoutLink,
                status: status || ""
            });
        }
    });

    useEffect(() => {
        if (!meetingMetaData?.length) return;
    
        let extractedStatus: { label: string; value: string }[] = [];
    
        meetingMetaData.forEach((field: any) => {
            if (field?.valuesJson && isJson(field.valuesJson)) {
                const parsedJson = JSON.parse(field.valuesJson);
    
                // Check if valuesJson contains an array of status objects
                if (Array.isArray(parsedJson.values)) {
                    extractedStatus = parsedJson.values.map((item: any) => ({
                        label: item.label,
                        value: item.value
                    }));
                }
            }
        });
    
        console.log("Extracted Status Options:", extractedStatus);
        setStatusOptions(extractedStatus); // Store only label-value pairs of statuses
    }, [meetingMetaData, modal]);
    
    useEffect(() => {
        if (googleConnect?.length) {
            const email = googleConnect[0]?.email;
            dispatch(getGoogleCalendarEvents(email))
        }

    }, [googleConnect])

    const toggle = () => {
        if (modal) {
            clearNewMeeting();
            setModal(false);
            handleClearEvent()
            setIsEdit(false);
            setIsEditButton(true);
        } else {
            setModal(true);
        }
    };

    /**
     * Handling date click on calendar
     */
    const handleCreateEvent = () => {
        const email = googleConnect[0]?.email;
        const handleSuccess = () => {
            toast(`Event created successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            clearNewMeeting();
            dispatch(getGoogleCalendarEvents(email))
            toggle();
        }
        dispatch(createGoogleCalendarEvent(email, postEvent, handleSuccess))
    }

    const clearNewMeeting = () => {
        setPostEvent({
            summary: "",
            location: "",
            description: "",
            eventDate: selectedNewDay,
            startDateTime: moment().add(30, 'minutes').format(),
            endDateTime: moment(moment().add(30, 'minutes').format()).add(60, 'minutes').format(),
            attendees: [info?.email || info?.student?.email],
        })
        setEmailData({
            to: info?.email || info?.student?.email
        })
        setNewStartTime('');
        setNewEndTime('')
    }
    const handleDateClick = (arg: any) => {
        if (arg) {
            // Get the selected date
            const selectedDate = moment(arg.date);

            // Round up to the next hour
            let roundedStartTime = moment(selectedDate).startOf('hour');
            if (moment().isAfter(roundedStartTime)) {
                roundedStartTime.add(1, 'hour');
            }

            // Set end time to 30 minutes after the start time
            const endTime = moment(roundedStartTime).add(30, 'minutes');

            // Format the dates for the state
            const formattedStartDateTime = roundedStartTime.format();
            const formattedEndDateTime = endTime.format();

            setPostEvent((prevObject) => ({
                ...prevObject,
                eventDate: formattedStartDateTime,
                startDateTime: formattedStartDateTime,
                endDateTime: formattedEndDateTime,
            }));

            // Update state with new dates
            setSelectedNewDay(new Date(formattedStartDateTime));
            setNewStartTime(new Date(formattedStartDateTime));
            setNewEndTime(new Date(formattedEndDateTime));
        }

        toggle();
    };

    const handleDateChange = (dateType: string, date: any) => {
        // Create a moment object for the selected date
        let selectedDate = moment(date);

        let combinedDateTime: any;

        switch (dateType) {
            case 'date':
                // Round to the next whole hour
                combinedDateTime = moment(selectedDate).startOf('hour');
                if (moment().isAfter(combinedDateTime)) {
                    combinedDateTime.add(1, 'hour');
                }

                // Format the rounded date-time
                const formattedStartDateTime = combinedDateTime.format();
                const formattedEndDateTime = moment(formattedStartDateTime).add(30, 'minutes').format();

                // Update state
                setPostEvent((prevObject) => ({
                    ...prevObject,
                    eventDate: formattedStartDateTime,
                    startDateTime: formattedStartDateTime,
                    endDateTime: formattedEndDateTime,
                }));
                setNewStartTime(new Date(formattedStartDateTime));
                setNewEndTime(new Date(formattedEndDateTime));
                break;

            case 'startTime':
                // Set the start time based on the existing event date
                combinedDateTime = moment(postEvent.eventDate).set({
                    hour: selectedDate.hour(),
                    minute: selectedDate.minute(),
                    second: selectedDate.second(),
                });

                // Round to the next whole hour if the time is in the past
                // if (moment().isAfter(combinedDateTime)) {
                //     combinedDateTime.add(1, 'hour');
                // }

                // Format the updated start and end times
                const newStartDateTime = combinedDateTime.format();
                const newEndDateTime = moment(newStartDateTime).add(30, 'minutes').format();

                // Update state
                setNewStartTime(new Date(newStartDateTime));
                setNewEndTime(new Date(newEndDateTime));

                setPostEvent((prevObject) => ({
                    ...prevObject,
                    startDateTime: newStartDateTime,
                    endDateTime: newEndDateTime,
                }));
                break;

            case 'endTime':
                // Set the end time based on the existing start time
                combinedDateTime = moment(postEvent.startDateTime).set({
                    hour: selectedDate.hour(),
                    minute: selectedDate.minute(),
                    second: selectedDate.second(),
                });

                // Ensure end time is always after start time
                if (moment(combinedDateTime).isBefore(postEvent.startDateTime)) {
                    combinedDateTime = moment(postEvent.startDateTime).add(30, 'minutes');
                }

                // Update state
                setPostEvent((prevObject) => ({
                    ...prevObject,
                    endDateTime: combinedDateTime.format(),
                }));
                break;

            default:
                break;
        }
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleStatusUpdate = (id: any, newStatus: { label: string; value: string }) => {
        setDisabled(true);
        setEvent((prevEvent: any) => ({
            ...prevEvent,
            status: newStatus.value,
        }));

        // Keep existing data intact while updating status
        const updatedData = {
            // ...event,
            status: newStatus.value, // Update only the value
        };

        const handleSuccess = (response: any) => {
            setDisabled(false);
            setDropdownOpen(false);
            setModal(false); // Close modal if needed
        };

        const handleFailure = (error: any) => {
            setDisabled(false);
        };

        dispatch(meetingsUpdateRequest(id, updatedData, handleSuccess, handleFailure));
    };

    const handleCreateEventClick = () => {
        // Get the current time
        // const currentTime = moment();

        // // Round up to the next hour
        // let roundedStartTime = moment(currentTime).startOf('hour');
        // if (currentTime.isAfter(roundedStartTime)) {
        //     roundedStartTime.add(1, 'hour');
        // }

        // // Set end time to 30 minutes after the rounded start time
        // const endTime = moment(roundedStartTime).add(30, 'minutes');

        // // Format the dates for the state
        // const formattedStartDateTime = roundedStartTime.format();
        // const formattedEndDateTime = endTime.format();

        // // Update state with the new dates
        // setPostEvent((prevObject) => ({
        //     ...prevObject,
        //     eventDate: formattedStartDateTime,
        //     startDateTime: formattedStartDateTime,
        //     endDateTime: formattedEndDateTime,
        // }));

        // setSelectedNewDay(new Date(formattedStartDateTime));
        // setNewStartTime(new Date(formattedStartDateTime));
        // setNewEndTime(new Date(formattedEndDateTime));

        // Trigger additional UI changes
        toggle();
    };


    const str_dt = function formatDate(date: any) {
        var monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        var d = new Date(date),
            month = "" + monthNames[d.getMonth()],
            day = "" + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [day + " " + month, year].join(",");
    };

    /**
     * Handling click on event on calendar
     */
    const handleEventClick = (arg: any) => {
        const eventsArg = arg.event;
        const st_date = events.start || eventsArg.start; // Start date/time
        const ed_date = events.end || eventsArg.end; // End date/time

        // If end is null, assume a 1-hour duration from start
        const calculatedEnd = ed_date || new Date(new Date(st_date).getTime() + 60 * 60 * 23999);

        // Format dates for display
        const r_date =
            ed_date == null
                ? str_dt(st_date)
                : `${str_dt(st_date)} to ${str_dt(calculatedEnd)}`;

        // Ensure defaultDate contains both start and calculated end
        const er_date = ed_date === null ? [st_date, calculatedEnd] : [st_date, ed_date];

        setEvent({
            id: eventsArg.id,
            title: eventsArg.title,
            start: st_date,
            end: calculatedEnd,
            className: "bg-info-subtle",
            meetingLink: eventsArg.extendedProps?.meetingLink || "",
            location: eventsArg._def.extendedProps?.location || "No Location",
            description: eventsArg._def.extendedProps?.description || "",
            defaultDate: er_date,
            datetag: r_date,
            status: eventsArg._def.extendedProps?.status,
        });
        setIsEdit(true);
        setIsEditButton(false);
        setDetailsModal(true);
    };


    const infomessage = "To create and view meetings you have to connect your calendar with social account in your profile."
    /**
     */
    const handleDeleteEvent = (eventId: any) => {
        const email = googleConnect[0]?.email;
        const handleSuccess = () => {
            setDeleteModal(false)
            dispatch(getGoogleCalendarEvents(email))
            // toggle();
        }
        dispatch(deleteGoogleEvent(email, eventId, handleSuccess))

    };

    // events validation
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: (events && events.id) || "",
            title: (events && events.title) || "",
            category: (event && event.category) || "",
            location: (events && events.location) || "",
            description: (events && events.description) || "",
            defaultDate: (event && event.defaultDate) || [],
            eventDate: (events && events.eventDate) || [],
            start: (events && events.start) || [],
            end: (events && events.end) || [],
            status: (events && events.status) || ""
        },

        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Your Event Name"),
            category: Yup.string().required("Please Select Your Category"),
            location: Yup.string().required("Please Enter Your Location"),
            description: Yup.string().required("Please Enter Your Description"),
            eventDate: Yup.date().required('Event date is required'),
            start: Yup.date().required('Start Time is required'),
            end: Yup.date().required('End Time is required'),
            defaultDate: Yup.array().of(Yup.date()).required('Date range is required').min(2, 'Select at least two dates'),
        }),
        onSubmit: () => {
            var updatedDay: any = "";
            if (selectedNewDay) {
                updatedDay = new Date(selectedNewDay[1]);
                updatedDay.setDate(updatedDay.getDate() + 1);
            }
            validation.resetForm();
            setSelectedNewDay(null);
            toggle();
        },
    });

    const handleDateSelect = (selectInfo: any) => {
        const calendarApi = selectInfo.view.calendar;
        const start = selectInfo.startStr;
        const end = selectInfo.endStr;

        // Open a prompt to get the event title from the user
        let title;

        if (title) {
            calendarApi.addEvent({
                title: title,
                start: start,
                end: end,
                allDay: false
            });
        }
    };

    const handleClearEvent = () => {
        setSelectedNewDay('')
        setNewStartTime('')
        setNewEndTime('')
    }

    const formattedStatus = statusOptions.find((s) => s.value === event?.status) || { label: "Unknown", value: "" };

    document.title = "Calendar | Zilter";
    return (
        <React.Fragment>
                         {modal && <AddEntryModal
                show={modal}
                onCloseClick={() => setModal(false)}
                dataFields={meetingMetaData}
                setTriggerApi={setTriggerApi}
                props={props}
                createAction={meetingsCreateRequest}
                parent={parent}
                parentId={parentId}
                to={emailData?.to}
            />}
            <DeleteModal
                props={props} // Pass props if needed
                show={deleteModal}
                onCloseClick={() => { setDeleteModal(false) }}
                record={event}
                onDelete={() => handleDeleteEvent(event.id)}
            />
            {googleConnect && googleConnect.length > 0 ? (
                <div className="">
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={3}>
                                    <Card className="bg-light card-h-100">
                                        <CardBody>{(userProfile?.subAgent?.enable_communication !== "false" ||
                                            (userProfile?.subAgent?.enable_communication !== "false" && communicate)) && (
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="text-nowrap btn btn-label"
                                                    id="btn-new-event"
                                                    onClick={() => handleCreateEventClick()}
                                                >
                                                    <i className="ri-calendar-event-fill label-icon align-middle fs-16 me-2"></i> Create New Event
                                                </Button>)}
                                        </CardBody>
                                    </Card>
                                    <div>
                                        <h5 className="mb-1">Upcoming Events</h5>
                                        <p className="text-muted">Don't miss scheduled events</p>
                                        <SimpleBar
                                            className="pe-2 me-n1 mb-3"
                                            style={{ height: "400px" }}
                                        >
                                            <div id="upcoming-event-list">
                                                {events &&
                                                    (events || []).map((event: any, key: any) => (
                                                        <div key={key}>
                                                            <UpcommingEvents event={event} />
                                                        </div>
                                                    ))}
                                            </div>
                                        </SimpleBar>
                                    </div>
                                </Col>

                                <Col lg={9}>
                                    <Card className="bg-light card-h-100">
                                        <CardBody>
                                            <FullCalendar
                                                plugins={[
                                                    BootstrapTheme,
                                                    dayGridPlugin,
                                                    interactionPlugin,
                                                    listPlugin
                                                ]}
                                                select={handleDateSelect}
                                                initialView="dayGridMonth"
                                                slotDuration={"00:15:00"}
                                                themeSystem="bootstrap"
                                                headerToolbar={{
                                                    left: "prev,next today",
                                                    center: "title",
                                                    right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                                                }}
                                                events={events}
                                                editable={true}
                                                droppable={true}
                                                selectable={true}
                                                dateClick={handleDateClick}
                                                eventClick={handleEventClick}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {/* <div style={{ clear: "both" }}></div> */}
                            <Modal isOpen={detailsModal} id="event-modal" centered>
                                <ModalHeader toggle={()=>{
                                    setDetailsModal(false)
                                    setEvent(null)}} tag="h5" className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary">

                                    <span className='text-light'>{event?.title}</span>
                                </ModalHeader>
                                <ModalBody>
                                <Form
                                        className={!!isEdit ? "needs-validation view-event" : "needs-validation"}
                                        name="event-form"
                                        id="form-event"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                    <div className="event-details">

                                            <div className="d-flex mb-2">

                                                <div className="flex-grow-1 d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-calendar-event-line text-muted fs-16"></i>
                                                    </div>
                                                    <div className="d-flex flex-grow-1 align-items-center gap-3">
                                                        <h6 className="d-block fw-semibold mb-0" id="event-start-date-tag">
                                                            {event ? moment(event?.start).format('DD MMM YYYY') : ""}
                                                        </h6>
                                                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} >
                                                            <DropdownToggle tag="div" className="d-inline-block">
                                                                <Badge className="fs-12 cursor-pointer" color="info" pill>
                                                                    {event
                                                                        ? event.status?.replace(/_/g, " ")?.toLowerCase()?.replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                                        : "Unknown"}
                                                                </Badge>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {statusOptions.map((status) => (
                                                                    <DropdownItem key={status.value} onClick={() => handleStatusUpdate(event.id, status)}>
                                                                        {status.label}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        </Dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <i className="ri-time-line text-muted fs-16"></i>
                                                </div>

                                                <div className="flex-grow-1">

                                                    <h6 className="d-block fw-semibold mb-0">

                                                        <span id="event-timepicker1-tag">
                                                            {event ? moment(event?.start).format('hh:mm A') : ""}
                                                        </span> -
                                                        <span id="event-timepicker2-tag">
                                                            {event ? moment(event?.end).format('hh:mm A') : ""}
                                                        </span>
                                                    </h6>

                                                </div>

                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <i className="ri-map-pin-line text-muted fs-16"></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold mb-0">
                                                        <span id="event-location-tag">
                                                            {event?.location}
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <div className="flex-shrink-0 me-3">
                                                    <i className="ri-discuss-line text-muted fs-16"></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="d-block text-muted mb-0" id="event-description-tag">
                                                        {events && event?.description !== undefined ? event?.description : "No Description"}
                                                    </p>
                                                </div>

                                            </div>
                                            <div><Link to={event?.meetingLink} target="_blank" rel="noopener noreferrer" className="text-info fw-medium">
                                                {" "}
                                                Join meeting
                                            </Link></div>
                                        </div>
                                        </Form>
                                        </ModalBody>
                                    </Modal>

                            {/* <Modal isOpen={modal} id="event-modal" centered>
                                <ModalHeader toggle={toggle} tag="h5" className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary">

                                    <span className='text-light'>{!!isEdit ? event.title : "Add Event"}</span>
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        className={!!isEdit ? "needs-validation view-event" : "needs-validation"}
                                        name="event-form"
                                        id="form-event"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >

                                        <div className="event-details">

                                            <div className="d-flex mb-2">

                                                <div className="flex-grow-1 d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-calendar-event-line text-muted fs-16"></i>
                                                    </div>
                                                    <div className="d-flex flex-grow-1 align-items-center gap-3">
                                                        <h6 className="d-block fw-semibold mb-0" id="event-start-date-tag">
                                                            {event ? moment(event?.start).format('DD MMM YYYY') : ""}
                                                        </h6>
                                                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} >
                                                            <DropdownToggle tag="div" className="d-inline-block">
                                                                <Badge className="fs-12 cursor-pointer" color="info" pill>
                                                                    {event
                                                                        ? event.status?.replace(/_/g, " ")?.toLowerCase()?.replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                                        : "Unknown"}
                                                                </Badge>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {statusOptions.map((status) => (
                                                                    <DropdownItem key={status.value} onClick={() => handleStatusUpdate(event.id, status)}>
                                                                        {status.label}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        </Dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <i className="ri-time-line text-muted fs-16"></i>
                                                </div>

                                                <div className="flex-grow-1">

                                                    <h6 className="d-block fw-semibold mb-0">

                                                        <span id="event-timepicker1-tag">
                                                            {event ? moment(event?.start).format('hh:mm A') : ""}
                                                        </span> -
                                                        <span id="event-timepicker2-tag">
                                                            {event ? moment(event?.end).format('hh:mm A') : ""}
                                                        </span>
                                                    </h6>

                                                </div>

                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <i className="ri-map-pin-line text-muted fs-16"></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold mb-0">
                                                        <span id="event-location-tag">
                                                            {event?.location}
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <div className="flex-shrink-0 me-3">
                                                    <i className="ri-discuss-line text-muted fs-16"></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="d-block text-muted mb-0" id="event-description-tag">
                                                        {events && event?.description !== undefined ? event?.description : "No Description"}
                                                    </p>
                                                </div>

                                            </div>
                                            <div><Link to={event?.meetingLink} target="_blank" rel="noopener noreferrer" className="text-info fw-medium">
                                                {" "}
                                                Join meeting
                                            </Link></div>
                                        </div>
                                        <Row className="event-form">
                                            <Col xs={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Event Name</Label>
                                                    <Input
                                                        className={!!isEdit ? "d-none" : "d-block"}
                                                        placeholder="Enter event name"
                                                        type="text"
                                                        name="title"
                                                        id="event-title"
                                                        onChange={(e) => {
                                                            setPostEvent((prevObject) => ({
                                                                ...prevObject,
                                                                summary: e.target.value
                                                            }))
                                                        }
                                                        }
                                                        onBlur={validation.handleBlur}
                                                        value={postEvent.summary || ""} />
                                                    {validation.touched.title && validation.errors.title ? (
                                                        <FormFeedback type="invalid" className="d-block">{!postEvent.summary ? validation.errors.title : null}</FormFeedback>)
                                                        : null}
                                                </div>
                                            </Col>

                                            <Col xs={12}>
                                                <div className="mb-3">
                                                    <Label>Event Date</Label>
                                                    <div className={!!isEdit ? "input-group d-none" : "input-group"}>
                                                        <Flatpickr
                                                            className="form-control"
                                                            id="event-start-date"
                                                            name="defaultDate"
                                                            placeholder="Select Date"
                                                            value={selectedNewDay}
                                                            options={{
                                                                dateFormat: "d M Y",
                                                            }}
                                                            onChange={(date) => {
                                                                handleDateChange("date", moment(date[0]))
                                                            }}
                                                        />
                                                        <span className="input-group-text">
                                                            <i className="ri-calendar-event-line"></i>
                                                        </span>
                                                    </div>
                                                    {validation.touched.eventDate && validation.errors.eventDate ? (
                                                        <FormFeedback type="invalid" className="d-block">{!selectedNewDay ? validation.errors.eventDate : null} </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <div className="mb-3">
                                                    <Label>Start Time</Label>
                                                    <div className="input-group">
                                                        <Flatpickr className="form-control"
                                                            name="start"
                                                            value={newStartTime}
                                                            onChange={(date) => {
                                                                handleDateChange("startTime", moment(date[0]))
                                                                validation.setFieldValue("start", date[0]);
                                                            }}
                                                            options={{
                                                                enableTime: true,
                                                                noCalendar: true,
                                                                dateFormat: "h:i K",
                                                            }} />
                                                        <span className="input-group-text"> <i className="ri-calendar-event-line"></i> </span>
                                                    </div>
                                                    {validation.touched.start && validation.errors.start ? (
                                                        <FormFeedback type="invalid" className="d-block">{!newStartTime ? validation.errors.start : null} </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <div className="mb-3">
                                                    <Label>End Time</Label>
                                                    <div className="input-group">
                                                        <Flatpickr className="form-control input-group"
                                                            name="end"
                                                            value={newEndTime}
                                                            onChange={(date) => {
                                                                handleDateChange("endTime", moment(date[0]))
                                                                validation.setFieldValue("end", date[0])
                                                            }}
                                                            options={{
                                                                enableTime: true,
                                                                noCalendar: true,
                                                                dateFormat: "h:i K",
                                                            }} />
                                                        <span className="input-group-text"> <i className="ri-calendar-event-line"></i> </span>
                                                    </div>
                                                    {validation.touched.end && validation.errors.end ? (
                                                        <FormFeedback type="invalid" className="d-block">{!newEndTime ? validation.errors.end : null} </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <div className="mb-2">
                                                    <Label htmlFor="exampleInputrounded" className="form-label">
                                                        Invitee Email
                                                    </Label>
                                                    <DtsEmailsInputs placeholder={"Invitee email"} defaultValue={`${emailData.to}`} onChange={(values: any) => {
                                                        setPostEvent((prevObject) => ({
                                                            ...prevObject,
                                                            attendees: [...values]
                                                        }))
                                                        setEmailData({ ...emailData, to: values })
                                                    }} />
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="event-location">Location</Label>
                                                    <div>
                                                        <Input
                                                            type="text"
                                                            className={!!isEdit ? "d-none" : "d-block"}
                                                            name="location"
                                                            id="event-location"
                                                            placeholder="Event location"
                                                            onChange={(e) => {
                                                                setPostEvent((prevObject) => ({
                                                                    ...prevObject,
                                                                    location: e.target.value
                                                                }))
                                                            }
                                                            }
                                                            onBlur={validation.handleBlur}
                                                            value={postEvent.location || ""} />
                                                        {validation.touched.location && validation.errors.location ? (
                                                            <FormFeedback type="invalid" className="d-block">{!postEvent.location ? validation.errors.location : null}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Description</Label>
                                                    <textarea
                                                        className={!!isEdit ? "form-control d-none" : "form-control d-block"}
                                                        id="event-description"
                                                        name="description"
                                                        placeholder="Enter a description"
                                                        rows={3}
                                                        onChange={(e) => {
                                                            setPostEvent((prevObject) => ({
                                                                ...prevObject,
                                                                description: e.target.value
                                                            }))
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={postEvent.description || ""}></textarea>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="hstack gap-2 justify-content-end">
                                            {!!isEdit && (
                                                <button
                                                    type="button"
                                                    className="btn btn-soft-danger"
                                                    id="btn-delete-event"
                                                    onClick={() => {
                                                        toggle();
                                                        setDeleteModal(true);
                                                    }}
                                                >
                                                    <i className="ri-close-line align-bottom"></i> Delete
                                                </button>
                                            )}
                                            {isEditButton &&
                                                <button type="button" disabled={checkObjectEmpty(postEvent)} onClick={handleCreateEvent} className="btn btn-success" id="btn-save-event">
                                                    {!!isEdit ? "Edit Event" : "Add Event"}

                                                </button>}
                                        </div>
                                    </Form>
                                </ModalBody>
                            </Modal> */}
                        </Col>
                    </Row>
                </div>
            ) : (
                <>
                    <ConnectAccount infomessage={infomessage} />
                </>
            )}
        </React.Fragment>
    );
};

export default withTranslation()(Meetings);