export interface CommissionsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Commissions {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export enum CommissionsActionTypes {
  GET_COMMISSIONMETADATA_REQUEST = "@@note/GET_COMMISSIONMETADATA_REQUEST",
  GET_COMMISSIONMETADATA_SUCCESS = "@@note/GET_COMMISSIONMETADATA_SUCCESS",
  GET_COMMISSIONMETADATA_ERROR = "@@note/GET_COMMISSIONMETADATA_ERROR",
  GET_COMMISSION_REQUEST = "@@note/GET_COMMISSION_REQUEST",
  GET_COMMISSION_SUCCESS = "@@note/GET_COMMISSION_SUCCESS",
  GET_COMMISSION_ERROR = "@@note/GET_COMMISSION_ERROR",
  GET_COMMISSIONS_REQUEST = "@@note/GET_COMMISSIONS_REQUEST",
  GET_COMMISSIONS_SUCCESS = "@@note/GET_COMMISSIONS_SUCCESS",
  GET_COMMISSIONS_ERROR = "@@note/GET_COMMISSIONS_ERROR",
  PUT_COMMISSION_REQUEST = "@@note/PUT_COMMISSION_REQUEST",
  PUT_COMMISSION_SUCCESS = "@@note/PUT_COMMISSION_SUCCESS",
  PUT_COMMISSION_ERROR = "@@note/PUT_COMMISSION_ERROR",
  DELETE_COMMISSION_REQUEST = "@@note/DELETE_COMMISSION_REQUEST",
  DELETE_COMMISSION_SUCCESS = "@@note/DELETE_COMMISSION_SUCCESS",
  DELETE_COMMISSION_ERROR = "@@note/DELETE_COMMISSION_ERROR",
  POST_COMMISSION_REQUEST = "@@note/POST_COMMISSION_REQUEST",
  POST_COMMISSION_SUCCESS = "@@note/POST_COMMISSION_SUCCESS",
  POST_COMMISSION_ERROR = "@@note/POST_COMMISSION_ERROR",
}

export interface CommissionsState {
  readonly list: Commissions[] | null;
  readonly details: Commissions | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: CommissionsMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;
}
