import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Listing from './Listing';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { isJson } from 'utils';
import { taskCreateRequest, taskDelete, taskGetRequest, taskListGetRequest, tasksMetadataGetRequest } from 'store/tasks/action';

const Tasks = (props: any) => {
    const { id } = useParams()
    const { details, mainModel, applicationId, studentId,  viewId, isCreateModal, setCreateOpen} = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const metaData = useSelector((state: ApplicationState) => state.tasks.metaDataFields);
    const [create, setCreate] = useState<boolean>(false);
    const [parentField, setParentField] = useState({})
    const [selectedTask, setSelectedTask] = useState<any>(null);
    const [modal_positionTop, setmodal_positionTop] = useState<boolean>(false);
    const [tick, setTick] = useState<boolean>()

    useEffect(() => {
        metaData && metaData.length && metaData.map((item: any) => {
            let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
            if (field.key === "student") setParentField({ ...field, ...item })
        })
    }, [metaData])
  

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.tasks;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    useEffect(() => {
        let filterObject = { PageSize: 500 }
        dispatch(tasksMetadataGetRequest(filterObject))
    }, [])

    const handleConfirmComplete = (item: any, checked: boolean) => {
        setSelectedTask(item);
        // If checked, open the top modal for confirmation
        if (checked) {
            setmodal_positionTop(true);
        } else {
            setmodal_positionTop(true);
        }
    };

    const checkbox = (row: any) => {
        if (create) return <span className="align-content-center cursor-pointer" >
            <Input
                className="form-check-input me-1 mt-1 ms-3 cursor-pointer"
                type="checkbox"
                value={row.id}
                id={"todo" + row.id}
                checked={row.status === "completed" || row.status === "COMPLETED"} // Dynamically set checked attribute
                onChange={(e) => {
                    handleConfirmComplete(row, e.target.checked);
                    setTick(e.target.checked)
                }}
            />
        </span>
        else return false
    }

    return (
        <div>
            <Listing
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                getListAction={taskListGetRequest}
                deleteAction={taskDelete}
                detailsGetAction={taskGetRequest}
                metaDatagetRequest={tasksMetadataGetRequest}
                createAction={taskCreateRequest}
                model={"tasks"}
                reducerState={"tasks"}
                parentId={id}
                parent={"student"}
                parentField={parentField}
                checkbox={checkbox}
                details={details}
                selectedTask={selectedTask}
                modal_positionTop={modal_positionTop}
                setmodal_positionTop={setmodal_positionTop}
                mainModel={mainModel} 
                applicationId={applicationId}
                studentId={studentId}
                tick={tick}
            />
        </div>
    );
};
export default withTranslation()(Tasks);


